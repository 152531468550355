var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[_c('van-nav-bar',{attrs:{"title":"修改密码","placeholder":""}}),_c('van-form',{staticClass:"mt-2x ph-2x mb-4x",on:{"submit":_vm.login}},[_c('van-field',{attrs:{"type":"password","name":"原密码","placeholder":"输入原始密码","rules":[{ required: true, message: '请输入确认密码' }],"clearable":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('van-field',{attrs:{"clearable":"","type":"password","name":"新密码","placeholder":"输入新密码","rules":[
        { required: true, message: '请输入确认密码' },
        { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' } ]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('van-field',{attrs:{"clearable":"","type":"password","name":"确认密码","placeholder":"再次输入新密码","rules":[
        { required: true, message: '请输入确认密码' },
        { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' },
        {
          validator: _vm.validatePasswordISOK,
          message: '新密码与确认密码不一致',
        } ]},model:{value:(_vm.nextNewPassword),callback:function ($$v) {_vm.nextNewPassword=$$v},expression:"nextNewPassword"}})],1),_c('button',{style:({ backgroundColor: _vm.color }),on:{"click":_vm.login}},[_vm._v("确认修改")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }