<template>
  <div>
    <!-- 凭证 -2022-5-24去掉 -->
    <!-- <div>
      <div class="flex-start" :style="{ backgroundColor: '#fff' }">
        <van-field
          readonly
          clickable
          class="prefix-picker"
          name="picker"
          :value="
            item.voucherList &&
            item.voucherList.find(
              (vouch) => vouch.voucherType === item.voucherType
            ).voucherName
          "
          placeholder="选择凭证"
          @click="showVoucher = true"
        />
        <van-field
          v-if="item.voucherType != 102"
          class="prefix-content"
          v-model="item.voucherList[voucherIndex].fieldValue"
          :required="item.voucherType == 101 ? false : true"
          :name="item.voucherName"
          placeholder="凭证信息"
          input-align="right"
          :rules="[
            {
              required: item.voucherType == 101 ? false : true,
              message: '请填写凭证信息',
            },
          ]"
          @blur="
            changeVoucher(item, item.voucherList[voucherIndex].voucherName)
          "
        />
        <div class="personFaceUrl" v-else-if="item.voucherType == 102">
          <FaceInput
            v-model="item.personId"
            :echoimgurl="item.personFaceUrl"
            @imgUrlChange="(url) => faceChange(item, url)"
          />
        </div>
      </div>
      <van-popup v-model="showVoucher" position="bottom">
        <div class="pt-6x">
          <div
            v-for="(ite, findex) in item.voucherList"
            :key="findex"
            @click="checkVoucherField(item, findex)"
            class="flex-center p-3x"
          >
            {{ item.voucherList[findex].voucherName }}
          </div>
        </div>
      </van-popup>
    </div> -->
    <!-- 预约日期&时段 -->
    <div
      v-if="
        appointmentSelection == 1 &&
        (item.timeShareAppointmentRuleId || wrapItem.timeShareAppointmentRuleId)
      "
    >
      <van-field
        readonly
        clickable
        name="picker"
        :value="visibleItem.playDateName"
        label="预约日期"
        required
        input-align="right"
        placeholder="请选择"
        @click="toShowCalendar"
      />
      <van-calendar
        v-model="showCalendar"
        @confirm="dateChange"
        :min-date="minDate"
        :max-date="maxDate"
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="visibleItem.timeIntervalName"
        label="预约时段"
        required
        input-align="right"
        placeholder="请选择"
        @click="timeFocus(visibleItem)"
      />
      <van-popup
        v-model="visibleItem.timeOpen"
        @close="visibleItem.timeOpen = false"
        @click-overlay="visibleItem.timeOpen = false"
        position="bottom"
      >
        <div class="pt-6x">
          <div
            v-for="(ite, findex) in visibleItem.times"
            :key="findex"
            @click="checkTime(ite)"
            class="flex-center p-3x"
          >
            {{ ite.beginTime | date("hh:mm") }} ~
            {{ ite.endTime | date("hh:mm") }}
          </div>
        </div>
      </van-popup>
    </div>
    <!-- 其他信息 -->
    <!-- 快速通行服务 -->
    <van-cell v-if="item.fast === 1">
      <template #title>
        <van-radio class="custom-title">快速通行服务</van-radio>
      </template>
      <template #extra> {{ item.fastChannelFee | money }}</template>
    </van-cell>
    <!-- 卡腕带押金 -->
    <van-cell v-show="item.requiredVoucherNo">
      <template #title>
        <span class="custom-title">卡腕带押金</span>
      </template>
      <template #extra>{{ item.wristbandPrice | money }}</template>
    </van-cell>
    <!-- 加购 -->
    <van-cell v-if="item.whetherRecommend">
      <template #title>
        <div>
          <div
            class="flex-start"
            @click="addMore(item.productId, params.playDate)"
          >
            <p>选择加购产品</p>
            <van-button
              type="primary"
              plain
              round
              size="mini"
              class="ph-2x ml-2x"
              :color="color"
              >选择</van-button
            >
          </div>
          <div
            class="mt-x"
            v-for="temp in item.subProductList"
            :key="temp.productId"
          >
            <!-- 产品一行 -->
            <div class="flex-between">
              <div class="flex-start">
                <p class="bold">{{ temp.productName }}</p>
                <p class="price ml-1x">
                  {{ temp.sellingAmount | money }}
                </p>
              </div>
              <div class="flex-start">
                <!-- 减 -->
                <img
                  class="minus"
                  v-if="temp.productFlagPO.whetheChangeNum"
                  v-show="temp.productFlagPO.buyNum > 0"
                  src="../../../assets/images/jian@2x.png"
                  @click="minusAddBuy(wrapItem, temp, item)"
                />
                <p class="ph-1x">{{ temp.productFlagPO.buyNum }}</p>
                <!-- <input
                      :value="item.buyNum"
                      class="input-number"
                      onkeyup="this.value=this.value.replace(/\D/g,'')"
                      @blur="(e) => inputBlur(e, item)"
                    /> -->
                <!-- 加 -->
                <img
                  class="minus"
                  v-if="temp.productFlagPO.whetheChangeNum"
                  src="../../../assets/images/jia@2x.png"
                  @click="plusAddBuy(wrapItem, temp, item)"
                />
                <!-- 删除 -->
                <img
                  v-if="temp.productFlagPO.whetheChangeNum"
                  class="minus ml-3x"
                  src="../../../assets/images/shanchu@2x.png"
                  @click="delAddBuy(wrapItem, temp, item)"
                />
              </div>
            </div>
            <div v-if="temp.appointmentTimePO">
              <div class="flex-start pl-6x">
                <p>预约日期：</p>
                <p @click="openAddPlayDate(temp)">
                  {{ temp.playDate | date }}
                  <img
                    src="../../../assets/images/fanhui@2x.png"
                    class="lower-img"
                    alt=""
                  />
                </p>
                <van-calendar
                  v-model="showAddCalendar"
                  @confirm="addDateChange"
                  :min-date="minDate"
                  :max-date="maxDate"
                />
              </div>
              <div class="flex-start pl-6x" @click="addTimeFocus(temp)">
                <p>预约时段：</p>
                <p>
                  {{
                    temp.appointmentTimePO.appointmentBeginTime | date("hh:mm")
                  }}~{{
                    temp.appointmentTimePO.appointmentEndTime | date("hh:mm")
                  }}
                  <img
                    src="../../../assets/images/fanhui@2x.png"
                    class="lower-img"
                    alt=""
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #extra> {{ item.fastChannelFee | money }}</template>
    </van-cell>
    <!-- 卡腕带 -->
    <van-field
      v-if="item.requiredVoucherNo"
      v-model="item.voucherNo"
      name="卡腕带"
      label="卡腕带"
      placeholder="卡腕带"
      :required="true"
      input-align="right"
      :rules="[
        {
          required: true,
          message: `请检查卡腕带`,
        },
      ]"
      @blur="changePlayer(item, '')"
    />
    <!-- 游客信息 -->
    <div v-for="(temp, indx) in item.visitorFieldList" :key="'v' + indx">
      <!-- 4, 姓名 10司机姓名 11司机车牌号 12地址 14微信号 -->
      <van-field
        v-if="[4, 10, 11, 12, 14].includes(temp.fieldType)"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        :rules="[
          {
            required: temp.required,
            message: `请填写${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 手机号 -->
      <van-field
        v-if="temp.fieldType === 5"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        :rules="[
          {
            validator: temp.required && checkPhone,
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 身份证 -->
      <van-field
        v-else-if="temp.fieldType === 6"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        :rules="[
          {
            validator: temp.required && checkIdCard,
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @focus="getIdCard(item, temp)"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 生日 -->
      <div v-else-if="temp.fieldType === 7">
        <van-field
          readonly
          clickable
          name="picker"
          :value="temp.fieldValue"
          :label="temp.fieldName"
          :required="temp.required"
          :disabled="temp.memberField ? true : false"
          input-align="right"
          placeholder="请选择"
          @click="openDate(temp)"
        />
        <van-calendar
          v-model="showDate"
          @confirm="(val) => dateValueChange(temp, val)"
          :min-date="new Date('1930')"
        />
      </div>

      <!-- 性别 -->
      <van-field
        v-else-if="temp.fieldType === 8"
        readonly
        clickable
        name="picker"
        :value="temp.fieldValue"
        :label="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        placeholder="请选择"
        @click="openPopup(temp)"
      />
      <!-- 证件照 -->
      <van-cell v-else-if="temp.fieldType === 9">
        <template #title>
          <span class="custom-title">{{ temp.fieldName }}</span>
        </template>
        <template #extra>
          <picture-input class="m-x" v-model="temp.fieldValue"></picture-input>
        </template>
      </van-cell>
      <!-- 证件类型 -->
      <div class="flex-start" v-else-if="temp.fieldType === 15">
        <van-field
          readonly
          clickable
          class="prefix-picker"
          name="picker"
          :value="temp.fieldName"
          placeholder="证件类型"
          @click="toShowPicker(temp)"
        />
        <van-field
          class="prefix-content"
          v-model="temp.fieldValue"
          :required="temp.required"
          :name="item.voucherName"
          placeholder="证件信息"
          input-align="right"
          :rules="[
            {
              required: temp.required,
              message: '请填写证件信息',
            },
          ]"
          @blur="changeVoucher(item, temp)"
        />
      </div>
      <!-- 国家 -->
      <van-field
        v-else-if="temp.fieldType === 16"
        readonly
        clickable
        name="picker"
        :value="temp.fieldValue"
        :label="temp.fieldName"
        :required="temp.required"
        input-align="right"
        placeholder="请选择"
        @click="openPopup(temp)"
      />
      <!-- 台胞证 -->
      <van-field
        v-else-if="temp.fieldType === 17"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        :rules="[
          {
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 港澳通行证 -->
      <van-field
        v-else-if="temp.fieldType === 18"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        :rules="[
          {
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 护照 -->
      <van-field
        v-else-if="temp.fieldType === 19"
        v-model="temp.fieldValue"
        input-align="right"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        :rules="[
          {
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 社保卡 -->
      <van-field
        v-else-if="temp.fieldType === 21"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        :disabled="temp.memberField ? true : false"
        input-align="right"
        :rules="[
          {
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
      <!-- 卡腕带 -->
      <van-field
        v-else-if="temp.fieldType === 100"
        v-model="temp.fieldValue"
        :name="temp.fieldName"
        :label="temp.fieldName"
        :placeholder="temp.fieldName"
        :required="temp.required"
        input-align="right"
        :disabled="temp.memberField ? true : false"
        :rules="[
          {
            required: temp.required,
            message: `请检查${temp.fieldName}`,
          },
        ]"
        @blur="changePlayer(item, temp.fieldName)"
      />
    </div>
    <div v-if="item.requiredPersonId" class="personFaceUrl bd-b">
      <div>
        <span class="red" v-show="item.requiredPersonTrue">*</span> 人脸
      </div>
      <FaceInput
        v-model="item.personId"
        :echoimgurl="item.personFaceUrl"
        @imgUrlChange="(res) => faceChange(item, res)"
      />
    </div>
    <!-- value弹窗 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="fieldOptions"
        @confirm="(val) => checkValueField(item.visitorFieldList, val)"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- label弹窗 -->
    <van-popup v-model="showLabelPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="fieldOptions"
        @confirm="(val) => checkField(item.visitorFieldList, val)"
        @cancel="showLabelPicker = false"
      />
    </van-popup>
    <!-- 加购时段弹窗 -->
    <van-popup
      v-model="addProduct.timeOpen"
      @close="addProduct.timeOpen = false"
      @click-overlay="addProduct.timeOpen = false"
      position="bottom"
    >
      <div class="pt-6x">
        <div
          v-for="(ite, findex) in addProduct.times"
          :key="findex"
          @click="checkAddTime(ite)"
          class="flex-center p-3x"
        >
          {{ ite.beginTime | date("hh:mm") }} ~
          {{ ite.endTime | date("hh:mm") }}
        </div>
      </div>
    </van-popup>

    <!--加购弹窗-->
    <pay-more ref="payMore" @change="updateMoreList"></pay-more>
  </div>
</template>
<script>
import { selectDiscountSku } from "@/api/cart";
import { appointmentTimeRuleStock } from "@/api/product";
import PayMore from "../ticket/components/PayMore";
// import chooseTourist from "./chooseTourist.vue";
// import store from "../../store/index";
import { nameReg, phoneReg, textReg, isIdcard } from "@/utils/global";
import PictureInput from "./PictureInput.vue";
import moment from "moment";
import FaceInput from "./FaceInput.vue";
import { clearReadIdCard, readIdCard } from "../../../utils/hardware";
export default {
  name: "basePlayer",
  inject: ["changeScan"],
  components: {
    PictureInput,
    FaceInput,
    PayMore,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    // 子产品时的主产品
    wrapItem: {
      type: Object,
      default() {
        return {};
      },
    },
    cartId: {
      type: Number,
      default: 0,
    },
    // 是否子产品
    isSubProduct: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "", // 分类id
        };
      },
    },
  },
  data() {
    return {
      appointmentSelection: "", //分时预约选择 1.产品  2.子景区 3.景区

      fieldFisible: false,
      openPopupIndex: 0, //当前点击游客信息
      fieldOptions: [], //点击游客信息选项
      fieldType: "", //当前选择类型

      voucherVisible: false,
      voucherIndex: 0,

      showPicker: false,
      showVoucher: false, //产品凭证

      showLabelPicker: false,
      showDate: false,
      showCalendar: false,
      showAddCalendar: false, //加购弹窗
      defaultData: "",
      visibleItem: {
        timeOpen: false,
        playDate: "",
        playDateName: "",
        timeIntervalId: "",
        beginTime: "",
        endTime: "",
        times: [],
        timeShareAppointmentRuleId: "",
      }, //临时修改预约日期和时段
      minDate: "",
      maxDate: "",
      orderRules: "",
      addProduct: {},
    };
  },
  created() {
    // console.log("this.item", this.item, localStorage.getItem("orderRules"));
    this.appointmentSelection = localStorage.getItem("appointmentSelection");
    this.visibleItem.playDate = this.item.playDate;
    this.visibleItem.playDateName = this.item.playDate
      ? moment(this.item.playDate).format("YYYY-MM-DD")
      : "";
    this.visibleItem.timeShareAppointmentRuleId =
      this.item.timeShareAppointmentRuleId ||
      this.wrapItem.timeShareAppointmentRuleId;
    this.minDate = new Date();
    this.maxDate = new Date();
  },

  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },

  methods: {
    // 主产品日期选择
    toShowCalendar() {
      this.handleDate(this.item.fixValidStartTime, this.item.fixValidEndTime);
      this.showCalendar = true;
    },
    /**
     * 预约日期限制处理
     * orderRules 下单规则&产品有效期-交集
     * fixValidStartTime fixValidEndTime 产品有效期
     */
    handleDate(fixValidStartTime, fixValidEndTime) {
      let orderRules = localStorage.getItem("orderRules");
      this.orderRules = orderRules ? JSON.parse(orderRules) : ""; //下单规则
      let obj = this.orderRules;
      // 没有下单规则
      if (!obj) {
        // 有产品有效期
        if (this.item && fixValidStartTime) {
          this.minDate = moment(fixValidStartTime);
          this.date = fixValidStartTime ? moment(fixValidStartTime) : "";
          this.maxDate = moment(fixValidEndTime);
        } else {
          this.minDate = moment(); //今天往后不限
        }
      } else {
        //有下单规则 1不限 2t+后n 3 T+后不限
        if (obj.orderDateRange == 1) {
          //还有产品有效期
          if (this.item && fixValidStartTime) {
            this.minDate = moment(fixValidStartTime);
            if (fixValidStartTime < moment().valueOf()) {
              this.minDate = moment();
            }
            this.date = this.minDate;
            this.maxDate = moment(fixValidEndTime);
          } else {
            this.minDate = moment(); //今天往后不限
            this.date = this.minDate;
          }
        } else if (obj.orderDateRange == 2) {
          // 2 t+后n
          //还有产品有效期
          if (this.item && fixValidStartTime) {
            // minDate 哪个大取哪个
            let minDate = this.dateCompare(
              moment().add(+Number(obj.tafterDay), "day"),
              moment(fixValidStartTime)
            )
              ? moment(fixValidStartTime)
              : moment().add(+Number(obj.tafterDay), "day");
            this.minDate = minDate;
            this.date = minDate;
            // maxDate 哪个小取哪个
            let maxDate = this.dateCompare(
              moment().add(+Number(obj.ttoDay + obj.tafterDay), "day"),
              moment(fixValidEndTime)
            )
              ? moment().add(+Number(obj.ttoDay + obj.tafterDay), "day")
              : moment(fixValidEndTime);
            this.maxDate = maxDate;
          } else {
            this.minDate = moment().add(+Number(obj.tafterDay), "day");
            this.maxDate = moment().add(
              +Number(obj.ttoDay + obj.tafterDay),
              "day"
            );
            this.date = this.minDate;
          }
        } else if (obj.orderDateRange == 3) {
          // t+n后不限
          //还有产品有效期
          if (this.item && fixValidStartTime) {
            // minDate 哪个大取哪个
            let minDate = this.dateCompare(
              moment().add(+Number(obj.tafterDay), "day"),
              moment(fixValidStartTime)
            )
              ? moment(fixValidStartTime)
              : moment().add(+Number(obj.tafterDay), "day");
            this.minDate = minDate;
            this.date = minDate;
            // maxDate 哪个小取哪个
            let maxDate = fixValidEndTime;
            this.maxDate = maxDate;
          } else {
            this.minDate = moment().add(+Number(obj.tafterDay), "day");
            this.maxDate = "";
            this.date = this.minDate;
          }
        }
      }
      // this.timeObj.playDate = this.defaultValue.valueOf();
      this.visibleItem.playDate = this.minDate.valueOf();
      this.visibleItem.playDateName = this.minDate
        ? moment(this.minDate).format("YYYY-MM-DD")
        : "";
      this.minDate = new Date(moment(this.minDate).format("YYYY-MM-DD"));
      this.defaultValue = this.minDate;
      this.maxDate = new Date(moment(this.maxDate).format("YYYY-MM-DD"));
    },
    //加购产品弹出选预约日期
    openAddPlayDate(temp) {
      this.addProduct = temp;
      this.handleDate(temp.fixValidStartTime, temp.fixValidEndTime);
      this.showAddCalendar = true;
    },
    // 获取加购产品的时段
    addTimeFocus(item, obj) {
      if (!item.timeShareAppointmentRuleId) {
        this.$toast("请先选择预约日期");
        return;
      }
      if (item.times.length === 0 && item.timeShareAppointmentRuleId) {
        // 根据规则id获取时段列表
        let date = obj
          ? obj.playDate.valueOf()
          : item.playDate
          ? item.playDate.valueOf()
          : this.params.playDate;
        appointmentTimeRuleStock({
          ruleId: item.timeShareAppointmentRuleId,
          date,
        })
          .then((res) => {
            // 过滤出可用时段
            const times = res.filter(
              (temp) =>
                temp.stock > 0 &&
                (date < temp.endTime ? temp.endTime : date) >
                  new Date().getTime()
            );
            if (obj) {
              obj.times = times;
              obj.timeOpen = !obj.timeOpen;
            } else {
              item.times = times;
              item.timeOpen = !item.timeOpen;
            }
          })
          .catch(() => {});
      } else {
        item.timeOpen = !item.timeOpen;
      }
      this.addProduct = item;
    },
    // 身份证-获取焦点后监听身份证
    getIdCard() {
      readIdCard(this.scanIdCard);
    },
    // 身份证回调
    scanIdCard(res) {
      let obj = JSON.parse(res);
      if (obj) {
        this.item.visitorFieldList.forEach((ite) => {
          // 身份证
          if (ite.fieldType == 4) {
            ite.fieldValue = obj.name;
          } else if (ite.fieldType === 6) {
            ite.fieldValue = obj.id;
          } else if (ite.fieldType === 8) {
            ite.fieldValue = obj.sex;
          }
        });
      }
      clearReadIdCard();
      this.changeScan(); //监听切回去
    },
    updateMoreList(arr) {
      const data = {
        cartId: this.cartId,
        productId: this.wrapItem.productId,
        index: this.wrapItem.index,
        addBuyList: arr,
      };
      if (this.item) {
        data.subProductId = this.item.productId;
        data.subIndex = this.item.index;
      }
      this.$emit("update-add-buy-batch", data);
    },
    checkIdCard(val) {
      return isIdcard(val) === "";
    },
    checkPhone(val) {
      return phoneReg.test(val);
    },
    // 人脸录入
    faceChange(item, res, obj) {
      if (obj) obj.personFaceUrl = res;
      else item.personFaceUrl = res;
      this.getTicketTraveller(item);
    },
    openDate(item) {
      this.fieldType = item.fieldType;
      this.showDate = true;
    },
    dateValueChange(item, val) {
      item.fieldValue = moment(val).format("YYYY-MM-DD");
      this.showDate = false;
      this.changePlayer(this.item, "");
    },
    // 打开label弹窗
    toShowPicker(item) {
      this.fieldType = item.fieldType;
      this.fieldOptions = item.fieldOptions;
      this.showLabelPicker = true;
    },
    //label选择选项
    checkField(visitorFieldList, val) {
      visitorFieldList.forEach((element) => {
        if (element.fieldType == this.fieldType) {
          element.fieldName = val;
        }
      });
      this.showLabelPicker = false;
    },
    // 打开value弹窗
    openPopup(item) {
      if (item.memberField) {
        return;
      }
      this.fieldType = item.fieldType;
      this.fieldOptions = item.fieldOptions;
      this.showPicker = true;
    },
    // 选择选项
    checkValueField(visitorFieldList, val) {
      visitorFieldList.forEach((element) => {
        if (element.fieldType == this.fieldType) {
          element.fieldValue = val;
        }
      });
      this.changePlayer(this.item, "");
      this.showPicker = false;
    },
    // 子产品选择游客加入购物车
    addShopping(arr) {
      this.addTourist = arr;
      this.visible = false;
      this.changePlayer(this.productItem);
    },
    // 子产品选择游客
    selectTourist(item) {
      this.productItem = item;
      this.isSub = true;
      this.visible = true;
    },

    // 跳转到加购选择
    addMore(productId, dailyCalendar) {
      this.$refs.payMore.getMore(productId, dailyCalendar);
    },
    // 加购加
    plusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: temp.productFlagPO.buyNum + 1,
        },
        obj
      );
    },
    // 加购减
    minusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (temp.productFlagPO.buyNum > 0)
        this.updateAddBuy(
          {
            productId: item.productId,
            index: item.index,
            addBuyProductId: temp.productId,
            addBuyNum: temp.productFlagPO.buyNum - 1,
          },
          obj
        );
    },
    // 删除加购
    delAddBuy(item, temp, obj) {
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: 0,
        },
        obj
      );
    },
    // 选中加购
    checkAddBuy(item, temp, obj) {
      const val = temp.productFlagPO.whetherCheck ? 0 : 1;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          whetherCheck: val,
        },
        obj
      );
    },
    // 更新加购
    updateAddBuy(data, obj) {
      // 如果是组合产品：一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
      }
      this.$emit("update-add-buy", data);
    },
    // 打开预约日期选择日历
    openPlayDate() {
      this.$refs.calendar.open();
    },
    dateChange(item) {
      this.visibleItem.playDateName = moment(item).format("YYYY-MM-DD");
      this.visibleItem.playDate = item.getTime();
      this.visibleItem.times = [];
      this.visibleItem.timeIntervalId = "";
      this.updateDateTime(1);
      this.showCalendar = false;
    },
    // 获取时段
    timeFocus(item, obj) {
      if (!item.timeShareAppointmentRuleId) {
        this.$toast("请先选择预约日期");
        return;
      }
      if (item.times.length === 0 && item.timeShareAppointmentRuleId) {
        // 根据规则id获取时段列表
        let date = obj
          ? obj.playDate.valueOf()
          : item.playDate
          ? item.playDate.valueOf()
          : this.params.playDate;
        appointmentTimeRuleStock({
          ruleId: item.timeShareAppointmentRuleId,
          date,
        })
          .then((res) => {
            // 过滤出可用时段
            const times = res.filter(
              (temp) =>
                temp.stock > 0 &&
                (date < temp.endTime ? temp.endTime : date) >
                  new Date().getTime()
            );
            if (obj) {
              obj.times = times;
              obj.timeOpen = !obj.timeOpen;
            } else {
              item.times = times;
              this.visibleItem.timeOpen = !this.visibleItem.timeOpen;
            }
          })
          .catch(() => {});
      } else {
        this.visibleItem.timeOpen = !this.visibleItem.timeOpen;
      }
    },
    //主产品选择时段
    checkTime(ite) {
      this.visibleItem.timeIntervalId = ite.timeIntervalId;
      this.visibleItem.beginTime = ite.beginTime;
      this.visibleItem.endTime = ite.endTime;
      this.visibleItem.timeIntervalName =
        moment(ite.beginTime).format("hh:mm") +
        "~" +
        moment(ite.endTime).format("hh:mm");
      this.visibleItem.timeOpen = false;
      this.updateDateTime(0);
    },
    // 加购产品确认预约日期
    addDateChange(item) {
      this.addProduct.playDate = new Date(item).getTime();
      this.addProduct.times = [];
      this.addProduct.timeIntervalId =
        this.addProduct.appointmentTimePO.timeIntervalId;
      this.updateAddBuy(
        {
          productId: this.item.productId,
          index: this.item.index,
          addBuyProductId: this.addProduct.productId,
          timeShareAppointmentRuleId:
            this.addProduct.timeShareAppointmentRuleId,
          timeIntervalId: this.addProduct.timeIntervalId,
          playDate: this.addProduct.playDate,
        },
        this.addProduct
      );
      this.showAddCalendar = false;
    },
    //选中加购时段
    checkAddTime(ite) {
      this.addProduct.timeIntervalId = ite.timeIntervalId;
      this.addProduct.beginTime = ite.beginTime;
      this.addProduct.endTime = ite.endTime;
      this.addProduct.timeOpen = false;
      this.updateAddBuy(
        {
          productId: this.item.productId,
          index: this.item.index,
          addBuyProductId: this.addProduct.productId,
          timeShareAppointmentRuleId:
            this.addProduct.timeShareAppointmentRuleId,
          timeIntervalId: this.addProduct.timeIntervalId,
          playDate: this.addProduct.playDate,
        },
        this.addProduct
      );
    },
    // 更新日期时段 type 1 日期
    updateDateTime(type) {
      let item = this.item;
      let obj = {};
      if (this.isSubProduct) {
        item = this.wrapItem;
        obj = this.item;
      }
      // 日期
      let data = {
        cartId: this.cartId,
        playDate: this.visibleItem.playDate,
        productId: item.productId,
        index: item.index,
      };
      // 子产品
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
        obj.timeOpen = false;
      }
      let time = { ...data }; //时段
      delete time.playDate;
      time.timeIntervalId = this.visibleItem.timeIntervalId;
      if (type) {
        this.$emit("update-date", data, "");
      } else {
        this.$emit("update-date", "", time);
      }
    },
    //凭证信息选择
    checkVoucherField(item, findex) {
      this.voucherIndex = findex;
      item.voucherType = item.voucherList[findex].voucherType;
      this.showVoucher = false;
    },
    openVoucherPopup(item, voucherList) {
      this.fieldOptions = voucherList;
      this.voucherVisible = true;
    },
    changeVoucher(item, name) {
      // 给相同的游客信息属性赋值
      if (item.voucherList[this.voucherIndex].fieldValue) {
        item.visitorFieldList.forEach((voc) => {
          if (
            voc.fieldType == item.voucherList[this.voucherIndex].voucherType
          ) {
            voc.fieldValue = item.voucherList[this.voucherIndex].fieldValue;
          }
        });
      }
      this.changePlayer(item, name);
    },
    // 删除购物车数量
    delItem(item, val) {
      this.$emit("del-item", item, val);
    },
    // 修改游客信息
    /**
     * @params item Object  主产品行
     * @params key String  校验的key
     * @params val Object  动态表单行
     * @params obj Object  子产品行
     * */
    changePlayer(item, key, val = {}, temp) {
      if (this.isSubProduct) {
        temp = item;
        item = this.wrapItem;
        if (!this.chaeckTraveller(temp) && !this.isSub) {
          return;
        }
      } else {
        if (!this.chaeckTraveller(item) && !this.isSub) {
          return;
        }
      }
      let valid = true;
      if (valid) {
        this.discountSku = {};
        this.changePlayerItem = item;
        // 如果是身份证情况下，进行惠民弹框
        if (
          (val.voucherType === 6 || val.fieldType === 6) &&
          val.fieldValue.trim()
        ) {
          let obj = {
            playDate: this.params.playDate, // 游玩日期
            idCard: val.fieldValue, // 身份证号
            timeIntervalId: this.params.timeId, // 时段id
            timeShareAppointmentRuleId: this.params.appointmentRules, // 分时预约id
            //   // 多规格 item.specCombination === 1, 否则单规格
            productId: item.productId, // 产品id
            spuId: item.spuId, // 产品spuld
            subScenicId: this.params.subScenicId, //子景区id 特惠产品要用到
          };
          selectDiscountSku(obj).then((res) => {
            if (res.selectProduct && res.selectProduct.length > 1) {
              // 惠民列表产品大于1，提供选择弹框
              //选择特惠产品
              this.discountSkus = res.selectProduct;
              this.discountSku = res.selectProduct[0];
              this.showDiscountSku = true;
            } else if (res.selectProduct && res.selectProduct.length === 1) {
              // 惠民列表为1不弹框选中
              this.discountSku = res.selectProduct[0];
              this.getTicketTraveller(item, temp);
            } else {
              // 是身份证情况下，没惠民政策
              this.getTicketTraveller(item, temp);
            }
          });
        } else {
          // 非填写身份证情况下
          this.getTicketTraveller(item, temp);
        }
      }
    },
    //出游人校验
    chaeckTraveller(item) {
      let ok = true;
      let checks = { 5: phoneReg, 10: nameReg, 12: textReg };
      for (let index = 0; index < item.visitorFieldList.length; index++) {
        const temp = item.visitorFieldList[index];
        if (temp.required && !temp.fieldValue) {
          ok = false;
          this.newErr(`${temp.fieldName}必填`);
          break;
        }
        if (
          temp.fieldValue &&
          checks[temp.fieldType] &&
          !checks[temp.fieldType].test(temp.fieldValue)
        ) {
          ok = false;
          this.newErr(`"${temp.fieldName}"校验不通过`);
          break;
        }
        //身份证
        if (temp.fieldType == 6) {
          // 如果身份证验证不通过
          const msg = isIdcard(temp.fieldValue);
          if (temp.fieldValue && msg) {
            ok = false;
            this.newErr(msg);
            break;
          }
        }
      }
      return ok;
    },
    // 抛出错误
    newErr(msg) {
      this.$toast(msg);
    },
    // 更新游客信息
    getTicketTraveller(item, temp) {
      // 把凭证信息复制到更多游客信息
      let obj = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index,
        traveler: this.getTraveler(temp || item), // temp 为组合子产品行，item为主产品行
      };
      // 惠民政策
      if (this.discountSku.selectProductSkuId) {
        obj.discountProductId = this.discountSku.selectProductSkuId;
      }

      // 如果是组合产品 一子产品一游客时
      if (temp) {
        obj.subProductId = temp.productId;
        obj.subIndex = temp.index;
        if (this.isSub) {
          obj.traveler.sightseerId = this.addTourist[0].sightseerId;
        }
      }
      this.$emit("update-player", obj);
      this.isSub = false;
    },
    //确认选择特惠产品
    sureDiscountSku() {
      this.getTicketTraveller(this.changePlayerItem);
      this.showDiscountSku = false;
    },
    // 获取游客信息
    getTraveler(item) {
      const obj = {
        voucherType: item.voucherType, // 选中的凭证选项
        personId: item.personId,
        personFaceUrl: item.personFaceUrl,
        visitorFieldList: item.visitorFieldList.map((temp) => {
          return {
            id: temp.id,
            fieldType: temp.fieldType,
            fieldValue: temp.fieldValue,
          };
        }),
      };
      // 游客类型
      if (item.visitorTypeId) obj.visitorTypeId = item.visitorTypeId;
      // 添加卡腕带号
      if (item.requiredVoucherNo) {
        // 为0不开启卡腕带录入
        obj.voucherNo = item.voucherNo;
      }
      return obj;
    },
  },
};
</script>
<style scoped lang="less">
// 游客信息
.player {
  background-color: #f8f8f8;
  .van-cell {
    // background-color: #f8f8f8;
    border-bottom: 1px solid #e9e9e9;
  }
  .prefix-picker {
    width: 30%;
    &:before {
      content: "";
      width: 1px;
      height: 50%;
      position: absolute;
      top: 25%;
      right: 0;
      background: #e2e2e2;
    }
  }
  .prefix-content {
    width: 70%;
  }
}
.minus {
  width: 20px;
}
.input-number {
  margin: 0 4px;
  border: none;
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  outline-color: #dcdfe6;
  color: #25a2f2;
}
.price {
  color: #ff2d2d;
  font-size: 16px;
}
.lower-img {
  width: 7px;
  height: 11px;
  transform: rotate(90deg);
}
.player-index {
  color: #2a2a2a;
  background-color: white;
}
.sub-product {
  background: white;
  background-color: white;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  width: 94%;
  // margin: 0 auto;
}
.hui {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  background: #f32a2a;
  color: #fff;
}
.ximg {
  width: 24px;
  height: 18px;
}
.personFaceUrl {
  width: 100%;
  padding: 0.2rem 0.38rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
