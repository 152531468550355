<template>
  <div class="root pt-1x">
    <!-- 头部tab栏 -->
    <div class="tabs font-s" v-show="actIndex == 0">
      <p class="native">{{ tabList[actIndex].tabName }}</p>
    </div>
    <div class="tabs font-s" v-show="actIndex == 1">
      <p class="native">{{ titles[natIndex].tabName }}</p>
    </div>
    <!-- 销售记录 中间统计 -->
    <div class="statistics font-s" v-show="actIndex == 0">
      <van-row class="mt-1x">
        <van-col class="text-c" span="6">
          总销售额
          <div class="font-l mt-x" :style="{ color: color }">
            {{ actualAmountSum | money("") }}
          </div>
        </van-col>
        <van-col class="text-c" span="6">
          退款金额
          <div class="font-l mt-x" :style="{ color: color }">
            {{ totalRefundAmountSum | money("") }}
          </div>
        </van-col>
        <van-col class="text-c" span="6">
          实收金额
          <div class="font-l mt-x" :style="{ color: color }">
            {{ (actualAmountSum - totalRefundAmountSum) | money("") }}
          </div>
        </van-col>
        <van-col class="text-c" span="6">
          销售产品（件）
          <div class="font-l mt-x" :style="{ color: color }">
            {{ buyNumSum }}
          </div>
        </van-col>
      </van-row>
    </div>
    <!-- 销售记录 订单列表 -->
    <div v-show="actIndex == 0" class="order-list font-s pv-2x">
      <p class="text-c native">今日销售明细</p>
      <template v-if="recordsList.length">
        <div
          v-for="(item, index) in recordsList"
          :key="index"
          @click="goOrderDetails(item)"
          class="order-number ph-2x mt-1x gray"
        >
          <div class="mt-1x">订单编号：{{ item.id }}</div>
          <div>
            <p>下单时间：{{ item.createTime | date("YYYY-MM-DD hh:mm") }}</p>
            <p>支付金额：{{ item.actualAmount | money }}</p>
            <p>支付方式：{{ item.payWayName || "-" }}</p>
            <p>订单状态：{{ item.orderStatusText }}</p>
          </div>
        </div>
      </template>
      <div class="etmy" v-else>
        <img src="../../../assets/images/emty.png" alt="" />
        <p class="gray">暂无数据</p>
      </div>
    </div>
    <!-- 核销记录 中间统计 -->
    <div class="write-off" v-show="actIndex == 1">
      <van-row class="mt-1x">
        <van-col class="text-c" :span="!natIndex ? 12 : 24">
          核销单品
          <div class="font-l mt-x" :style="{ color: color }">
            {{ natIndex === 0 ? writeOffPagetotal : writeOffPageOld.total }}
          </div>
        </van-col>
        <van-col class="text-c" span="12" v-show="!natIndex">
          入园人数
          <div class="font-l mt-x" :style="{ color: color }">
            {{ perNum }}
          </div>
        </van-col>
      </van-row>
    </div>
    <!-- 核销记录 订单列表 -->
    <div v-show="actIndex == 1" class="write-off-order-list">
      <div class="tabs-item flex-between">
        <div
          class="item"
          @click="natFocuk(0)"
          :style="
            natIndex === 0
              ? { color, 'font-size': '16px', 'font-weight': 'bold' }
              : {}
          "
        >
          今日核销明细
          <div
            v-show="natIndex === 0"
            class="tab-line"
            :style="{ background: color }"
          ></div>
        </div>
        <div
          class="item"
          @click="natFocuk(1)"
          :style="
            natIndex === 1
              ? { color, 'font-size': '16px', 'font-weight': 'bold' }
              : {}
          "
        >
          历史核销明细
          <div
            v-show="natIndex === 1"
            class="tab-line"
            :style="{ background: color }"
          ></div>
        </div>
      </div>

      <div v-show="natIndex === 0">
        <div v-if="writeOffList.length">
          <div
            class="font-s gray p-2x"
            v-for="item in writeOffList"
            :key="item.id"
          >
            <div>票号：{{ item.mainAuxiliaryId }}</div>
            <div class="mv-1x">{{ item.productName }}</div>
            <div>
              核销时间：{{ item.writeOffTime | date("YYYY-MM-DD hh:mm") }}
            </div>
          </div>
        </div>
        <div class="etmy" v-else>
          <img src="../../../assets/images/emty.png" alt="" />
          <p class="gray">暂无数据</p>
        </div>
      </div>

      <div class="old" v-show="natIndex === 1">
        <!-- 查询 -->
        <van-row class="search">
          <van-col @click="typeFn" span="4">
            {{ typeTitle }} <van-icon name="arrow-down" />
          </van-col>
          <van-col @click="isSearch" span="16" v-if="typeTitle == '时间'">
            {{ dateName }}
          </van-col>
          <van-col span="16" v-else>
            <input
              class="ipt"
              type="text"
              v-model="keyword"
              placeholder="请输入票号/单号"
            />
          </van-col>
          <van-col span="4" @click="isSearch">
            <img :src="typeTitle == '时间' ? riliUrl : searchUrl" alt="" />
          </van-col>
        </van-row>

        <div v-if="writeOffListOld.length">
          <div
            class="font-s p-2x gray bt"
            v-for="item in writeOffListOld"
            :key="item.id"
          >
            <div>票号：{{ item.mainAuxiliaryId }}</div>
            <div class="mv-1x">{{ item.productName }}</div>
            <div>
              核销时间：{{ item.writeOffTime | date("YYYY-MM-DD hh:mm") }}
            </div>
          </div>
        </div>
        <div class="etmy" v-else>
          <img src="../../../assets/images/emty.png" alt="" />
          <p class="gray">暂无数据</p>
        </div>
      </div>
    </div>
    <ActionSheet v-model="show" :actions="actions" @select="onSelect" />
    <van-calendar
      :min-date="new Date('2022-1-1')"
      :max-date="new Date()"
      v-model="isShowTime"
      type="range"
      allow-same-day
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { orderSalesrecord, getOldVerificationrecord } from "@/api/search";
import { ActionSheet } from "vant";
import { toDaywriteOffList } from "../../../api/order";
export default {
  name: "OrderList",
  props: {
    actIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ActionSheet,
  },
  data() {
    return {
      // tab栏 激活索引
      // tab 栏
      tabList: [
        { id: 1, tabName: "今日销售汇总" },
        { id: 2, tabName: "今日核销汇总" },
      ],
      recordsList: [],
      writeOffList: [], // 今日核销数据
      // 订单列表数据
      recordsPage: {
        page: 1, // 请求页码
        size: 10, // 请求页码当前显示多少数据
        total: 0, // 订单总数
      },
      writeOffPagetotal: 0, // 今日核销单品数量
      perNum: 0, // 入园人数
      loading: false, // 是否开启分页加载
      actualAmountSum: 0,
      buyNumSum: 0,
      totalRefundAmountSum: 0,
      natIndex: 0,
      riliUrl: require("../../../assets/images/rili@2x.png"),
      searchUrl: require("../../../assets/images/ss@2x.png"),
      writeOffListOld: [], // 历史核销数据
      writeOffPageOld: {
        page: 1, // 请求页码
        size: 10, // 请求页码当前显示多少数据
        total: 0, // 订单总数
      },
      show: false,
      typeTitle: "时间",
      actions: [{ name: "时间" }, { name: "单号" }],
      verifyBeginTime: "", // 查询开始时间
      verifyEndTime: "", // 查询结束时间
      keyword: "", // 查询单号
      isShowTime: false,
      dateName: "请选择时间搜索",
      isPage: false,
      titles: [
        { id: 1, tabName: "今日核销汇总" },
        { id: 2, tabName: "历史核销汇总" },
      ],
    };
  },
  async created() {
    this.getOrderSalesrecord();
    this.getVerificationrecord();
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    //格式化日期 开始日期
    formatDate(date) {
      return date.getTime();
    },
    // 格式化日期  结束日期格式化
    formatDateend(date) {
      return date.getTime() + 24 * 60 * 60 * 1000 - 1;
    },
    // 转化 年月日字符串
    newDates(time) {
      const date = new Date(time);
      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + M + D;
    },
    onConfirm(date) {
      this.writeOffPageOld.page = 1;
      const [start, end] = date;
      this.verifyBeginTime = this.formatDate(start);
      this.verifyEndTime = this.formatDateend(end);
      this.dateName = this.newDates(start) + "~" + this.newDates(end);
      this.getOldVerificationrecord();
      this.isShowTime = false;
    },
    isSearch() {
      if (this.typeTitle == "时间") {
        this.isShowTime = true;
      } else {
        if (!this.keyword.length) return;
        this.getOldVerificationrecord();
      }
    },
    onSelect(item) {
      this.typeTitle = item.name;
      this.verifyBeginTime = "";
      this.verifyEndTime = "";
      this.dateName = "请选择时间搜索";
      this.show = false;
    },
    typeFn() {
      this.show = true;
    },
    natFocuk(index) {
      this.natIndex = index;
      if (this.natIndex === 1) {
        window.addEventListener("scroll", this.loMoreOld);
      } else if (this.natIndex === 0) {
        window.removeEventListener("scroll", this.loMoreOld);
      }
    },
    // 去订单详情页面
    goOrderDetails({ id }) {
      this.$router.push({
        path: "/record/Orderdetails",
        query: {
          id,
        },
      });
    },
    // 历史核销分页
    loMoreOld() {
      let scrollTops =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (
        document.documentElement.offsetHeight <=
        scrollTops + window.innerHeight + 1
      ) {
        if (
          this.writeOffPageOld.total <=
          this.writeOffPageOld.size * this.writeOffPageOld.page
        ) {
          this.$toast("暂无数据");
          return;
        }
        this.writeOffPageOld.page += 1;
        this.isPage = !this.isPage;
        this.getOldVerificationrecord(this.isPage);
      }
    },
    // 请求订单列表数据
    async getOrderSalesrecord() {
      const res = await orderSalesrecord({
        page: this.recordsPage.page,
        size: this.recordsPage.size,
      });
      res.records.forEach((item) => {
        // 支付状态 1: "未支付"; 2: "支付中"; 3: "已支付"; 4: "待发货（零售）"; 5: "待收货（零售）"; 6: "已完成（零售）"; 7: "已取消"; 8: "已退单"; 9: "部分退款";
        switch (item.orderStatus) {
          case 1:
            item.orderStatusText = "未支付";
            break;
          case 2:
            item.orderStatusText = "支付中";
            break;
          case 3:
            item.orderStatusText = "已支付";
            break;
          case 4:
            item.orderStatusText = "待发货（零售）";
            break;
          case 5:
            item.orderStatusText = "待收货（零售）";
            break;
          case 6:
            item.orderStatusText = "已完成（零售）";
            break;
          case 7:
            item.orderStatusText = "已取消";
            break;
          case 8:
            item.orderStatusText = "已退单";
            break;
          case 9:
            item.orderStatusText = "部分退款";
            break;
        }
      });
      this.recordsList = res.records;
      this.recordsPage.total = res.total;
      this.actualAmountSum = res.actualAmountSum || 0; //总销售额（单位：分
      this.buyNumSum = res.buyNumSum || 0; //销售产品数量
      this.totalRefundAmountSum = res.totalRefundAmountSum || 0; //总退款额（单位：分）
    },
    // 请求核销列表数据
    async getVerificationrecord() {
      const resOff = await toDaywriteOffList();
      this.perNum = resOff.enterNum; // 入园人数
      this.writeOffList = resOff.writeOffDatas;
      this.writeOffPagetotal = resOff.checkNum;
    },
    // 查询历史核销记录
    async getOldVerificationrecord(isPage) {
      const res = await getOldVerificationrecord({
        page: this.writeOffPageOld.page,
        size: this.writeOffPageOld.size,
        verifyBeginTime: this.verifyBeginTime,
        verifyEndTime: this.verifyEndTime,
        keyword: this.keyword,
      });
      this.writeOffPageOld.total = res.total;
      if (isPage) {
        this.isPage = false;
        this.writeOffListOld = this.writeOffListOld.concat(res.records);
        return;
      }
      this.writeOffListOld = res.records;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.loMoreOld);
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/custom.less";
.ipt {
  border: none;
}
.root {
  background-color: #f7f7f7;
  width: 94%;
  margin: 0 auto;
  .tabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
    background: #fff;
    border-radius: 8px 8px 0 0;
    // tab 底部白条
    .line {
      width: 36px;
      height: 4px;
      background-color: #fff;
      border-radius: 3px;
      margin: 5px auto;
    }
  }
  .statistics {
    height: 75px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    overflow: hidden;
  }
  .order-list {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 8px;
    line-height: 30px;
    .order-number {
      border-top: 1px solid #f1f1f1;
    }
  }
  .write-off {
    height: 75px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    overflow: hidden;
  }
  .write-off-order-list {
    background-color: #fff;
    margin: 10px auto;
    border-radius: 8px;
    overflow: hidden;
    .bt {
      border-top: 1px solid #f2f2f2;
    }
  }
}
.etmy {
  text-align: center;
  padding: 20px;
}
.old {
  background-color: #fff;
  .search {
    width: 95%;
    height: 34px;
    line-height: 32px;
    border-radius: 6px;
    border-radius: 6px;
    margin: 20px auto;
    border: 1px solid #e2e2e2;
    text-align: center;
    img {
      width: 20px;
      vertical-align: middle;
    }
  }
}
.tabs-item {
  border-bottom: 1px solid #f1f1f1;

  .item {
    padding: 12px 32px;
    position: relative;
    .tab-line {
      position: absolute;
      left: 32px;
      bottom: 0;
      width: 96px;
      height: 4px;
      border-radius: 2px;
    }
  }
  .native {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
