<template>
  <!-- 计时结束 -->
  <div>
    <!-- 选择产品弹窗 -->
    <van-overlay :show="visible" z-index="9" @click="close">
      <div class="overlay-main" @click.stop>
        <div class="title">
          <div class="overlay-title text-c font-l pv-2x ph-2x">选择产品</div>
        </div>
        <div class="list">
          <!-- 产品列表 -->
          <van-radio-group v-model="productIndex" ref="checkboxGroup">
            <div class="item" v-for="(item, index) in productList" :key="index">
              <van-radio shape="square" :name="index">
                <p class="mb-x">{{ item.productName }}</p>
                <p>票号：{{ item.mainAuxiliaryId }}</p>
              </van-radio>
            </div>
          </van-radio-group>
        </div>
        <div class="flex-between pop-centent-buttom">
          <button class="pop-centent-btn" @click="close">取消</button>
          <button class="pop-centent-btn confirm" @click="sureModal">
            确认
          </button>
        </div>
      </div>
    </van-overlay>
    <!-- 要计时结束的产品 -->
    <van-overlay :show="showInfos" z-index="9" @click="showInfos = false">
      <div class="overlay-main" @click.stop>
        <div class="title">
          <div class="overlay-title text-c font-l pv-2x ph-2x">计时结束</div>
        </div>
        <!-- 产品明细 -->
        <div class="ph-2x pv-2x">
          <p class="mb-1x">产品名称：{{ tempRow.productName }}</p>
          <p class="mb-1x">
            进场时间：{{ details.timestampStart | date("YYYY-MM-DD hh:mm:ss") }}
          </p>
          <p class="mb-1x">
            出场时间：{{ details.timestampEnd | date("YYYY-MM-DD hh:mm:ss") }}
          </p>
          <p class="mb-1x">游玩时间：{{ details.playTime }}</p>
          <p class="mb-1x">押金：{{ details.totalAmount | money() }}</p>
          <p class="mb-1x">
            总消费：{{ details.depositRefundTotalAmount | money() }}
          </p>
          <p class="mb-1x">状态：已核销</p>
          <p class="mb-1x">
            {{ details.status === 2 ? "应退" : "应付" }}金额：{{
              Math.abs(details.depositRefundTotalAmount - details.totalAmount)
                | money()
            }}
          </p>
        </div>
        <div class="flex-between pop-centent-buttom">
          <button class="pop-centent-btn" @click="showInfos = false">
            取消
          </button>
          <button class="pop-centent-btn confirm" @click="changeStatus">
            {{ details.status === 3 ? "去付款" : "确认" }}
          </button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  quickOrderProductWrittenOffList,
  quickOrderProductDepositAmount,
  updateQuickOrderWriteOffStatus,
  timingFinishRefund,
} from "../../../api/order";
import moment from "moment";
export default {
  name: "timeing-end",
  data() {
    return {
      moment,
      isShowProduct: false, // 是否显示产品弹窗
      productList: [], // 产品列表
      searchKey: "",
      productIndex: 0, // 选中产品
      tempRow: [], // 缓存数据
      showInfos: false, // 要结束产品的信息
      details: {}, // 详情数据
    };
  },
  created() {
    this.searchData();
  },
  props: {
    // 展示
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 确认按钮
    changeStatus() {
      if (this.details.status === 1) {
        this.changeWrittenStatus();
      } else if (this.details.status === 2) {
        this.refund();
      } else if (this.details.status === 3) {
        this.showInfos = false;
        this.close();
        let arr = {
          id: this.tempRow.id,
          writeOffStatus: 3,
          remark: this.tempRow.productName,
          money:
            (this.details.depositRefundTotalAmount - this.details.totalAmount) /
            100,
        };
        this.$router.push({
          path: "/home-new/DirectPay",
          query: arr,
        });
      }
    },
    // 退款
    refund() {
      timingFinishRefund(
        {
          checkDetailId: this.tempRow.id,
          refundAmount:
            this.details.totalAmount - this.details.depositRefundTotalAmount,
        },
        { loading: false }
      )
        .then(() => {
          this.changeWrittenStatus();
        })
        .catch();
    },
    // 修改计时状态
    changeWrittenStatus() {
      updateQuickOrderWriteOffStatus({
        id: this.tempRow.id,
        writeOffStatus: 3,
        updateTime: moment().valueOf(),
      })
        .then(() => {
          this.showInfos = false;
          this.scanEnter();
        })
        .catch();
    },
    // 搜索产品
    searchData() {
      if (this.searchKey) {
        quickOrderProductWrittenOffList({
          identifyCode: this.searchKey,
        })
          .then((res) => {
            this.productList = res;
          })
          .catch();
      } else {
        // 初始化产品列表和值
        this.productList = [];
        this.productIndex = 0;
      }
    },
    // 选择产品
    sureModal() {
      if (this.productList.length) {
        const data = this.productList[this.productIndex];
        this.tempRow = data;
        quickOrderProductDepositAmount({
          productId: data.productId,
          totalAmount: data.receivedAmount,
          timestampStart: data.writeOffTime,
          timestampEnd: moment().valueOf(),
          spuRuleSnapshotId: data.spuRuleSnapshotId,
        })
          .then((res) => {
            let playTime = res.timestampEnd - res.timestampStart;
            res.playTime = this.showTime(playTime);
            let status = 1; // 状态 1不退不补 2 退款 3 补钱
            let money = res.totalAmount - res.depositRefundTotalAmount; // 押金减去相关金额
            // res.depositRefund 1.多退少补 2.只退不补
            if (res.depositRefund === 1 && money > 0) {
              status = 2;
            } else if (res.depositRefund === 1 && money < 0) {
              status = 3;
            } else if (res.depositRefund === 2 && money > 0) {
              status = 2;
            }
            res.status = status;
            this.details = res;
            this.showInfos = true;
          })
          .catch();
      }
    },
    // 计算时分秒
    showTime(val) {
      val = val / 1000;
      if (val < 60) {
        return val + "秒";
      } else {
        let min_total = Math.floor(val / 60); // 分钟
        let sec = Math.floor(val % 60); // 余秒
        if (min_total < 60) {
          return min_total + "分钟" + sec + "秒";
        } else {
          let hour_total = Math.floor(min_total / 60); // 小时数
          let min = Math.floor(min_total % 60); // 余分钟
          return hour_total + "小时" + min + "分钟" + sec + "秒";
        }
      }
    },
    close() {
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="less" scoped>
.overlay-main {
  width: 326px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .overlay-title {
      border-bottom: 1px solid #f1f1f1;
      flex: 1;
    }
    .close {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .text-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .int {
      position: relative;
      input {
        width: 254px;
        height: 45px;
        border-radius: 4px;
        border: none;
        background-color: #f1f1f1;
      }
      button {
        width: 50px;
        height: 30px;
        border-radius: 4px;
        border: none;
        color: #fff;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
  .search {
    padding: 10px 14px;
  }
  .seach-btn {
    padding: 0 12px;
    border-radius: 6px 6px 6px 6px;
    opacity: 0.81;
    color: #ffffff;
    line-height: 0.6rem !important;
    margin-right: 3px;
  }
  // 产品列表
  .list {
    height: 355px;
    overflow-y: scroll;
    .item {
      border-bottom: 1px solid #f1f1f1;
      padding: 15px 14px;
    }
  }
  .pop-centent-buttom {
    padding: 15px 20px 20px;
    border-top: 1px solid #f1f1f1;
    .pop-centent-btn {
      width: 120px;
      height: 40px;
      line-height: 40px;
      background: #f1f1f1;
      border-radius: 20px;
      font-size: 16px;
      font-weight: 400;
      border: none;
    }
    .pop-centent-btn::after {
      border: none;
    }
    .confirm {
      background: @primary;
      color: #fff;
    }
  }
  .print-type-inp {
    border-radius: 6px;
    border: 1px solid #b8b8b8;
    width: 136px;
    height: 36px;
    margin-right: 8px;
  }
  .print-time {
    width: 90px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #b8b8b8;
    margin-right: 8px;
  }
  .print-main {
    height: 410px;
    overflow-y: scroll;
  }
}
</style>
