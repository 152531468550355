var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap ph-1x"},[_c('div',{staticClass:"flex-around format-box pt-2x pb-2x"},_vm._l((_vm.filterFormat),function(item){return _c('div',{key:item.id,staticClass:"flex-center flex-col pv-1x ph-2x",class:{ currentFormat: item.id == _vm.currentFormat },style:({
        color: item.id === _vm.currentFormat ? _vm.color : '',
      }),on:{"click":function($event){return _vm.changeFormat(item)}}},[_c('img',{staticClass:"img",attrs:{"src":item.img,"alt":""}}),_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),(_vm.isShowTime && _vm.dates.length)?_c('div',{staticClass:"flex-between date-box pb-2x"},[_c('div',{staticClass:"scroll-view"},[_c('div',{staticClass:"flex-around date ml-x"},_vm._l((_vm.dates),function(item,index){return _c('div',{key:index,staticClass:"date-item ph-1x",class:{ active: index === _vm.active },style:({
            color: index === _vm.active ? _vm.color : '',
          }),attrs:{"id":'tab' + index},on:{"click":function($event){return _vm.clickDate(item, index)}}},[_c('div',[_c('div',{staticClass:"font-l bold"},[_vm._v(" "+_vm._s(item.week)+" ")]),_c('div',{staticClass:"font-n"},[_vm._v(" "+_vm._s(_vm.nowDay == item.date ? "今天" : _vm.nowDay + 1 == item.date ? "明天" : (_vm.currentMonth + "/" + (item.date)))+" ")])])])}),0)]),_c('div',{staticClass:"calender flex-center flex-shrink",class:{ active: _vm.active === '' },on:{"click":_vm.openCalendar}},[_c('div',{style:({
          color: _vm.active === '' ? _vm.color : '',
        })},[_c('img',{staticClass:"image",style:({
            color: _vm.active === '' ? _vm.color : '',
          }),attrs:{"src":require("../../../assets/images/format/rili@2x.png"),"alt":""}}),_c('span',{staticClass:"c-time"},[_vm._v(_vm._s(_vm.currentTime))])])]),_c('van-calendar',{attrs:{"default-date":_vm.minDate,"min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.dateChange},model:{value:(_vm.showCalendar),callback:function ($$v) {_vm.showCalendar=$$v},expression:"showCalendar"}})],1):_vm._e(),(_vm.isShowTime && _vm.dates.length)?_c('div',{staticClass:"flex-start align-center time-box pb-2x"},[_c('ul',{staticClass:"flex-start flex-wrap"},_vm._l((_vm.times),function(item){return _c('li',{key:item.id,staticClass:"time-item flex-center ph-1x ml-x",class:{
          active: item.id === _vm.timeId,
          disabled: item.remainingStock <= 0,
        },style:({
          color: item.id === _vm.timeId ? _vm.color : '',
        }),on:{"click":function($event){return _vm.checkTime(item)}}},[_c('div',[_c('b',[_vm._v(" "+_vm._s(_vm._f("date")(item.beginTime,"hh:mm"))+" - "+_vm._s(_vm._f("date")(item.endTime,"hh:mm")))]),_c('p',[_vm._v("库存："+_vm._s(item.remainingStock))])])])}),0)]):_vm._e(),_c('div',{staticClass:"ticket pb-2x"},[_c('div',{staticClass:"ticket-type flex-start"},[_c('div',{staticClass:"ticket-type-item ph-2x mh-x",class:{ active: _vm.classId == '' },style:({
          color: _vm.classId == '' ? _vm.color : '',
        }),on:{"click":function($event){return _vm.checkClass('')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.productClass),function(item){return _c('div',{key:item.id,staticClass:"ticket-type-item ph-2x mh-x",class:{ active: _vm.classId === item.id },style:({
          color: _vm.classId === item.id ? _vm.color : '',
        }),on:{"click":function($event){return _vm.checkClass(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }