<!-- 票务业态 -->
<template>
  <!--门票列表-->
  <section class="section ticket-box">
    <ul class="ticket-list flex-col flex-between flex-wrap" v-if="list.length">
      <li class="res" v-for="(item, index) in list" :key="item.id">
        <div class="ticket-list-item">
          <div class="p-1x ph-2x flex-start">
            <img class="img" :src="item.skuImages" alt="" />
            <div class="right-content">
              <p class="bold text-1 font-l res mv-x">
                {{ item.name }}
              </p>
              <!-- <div>
                <a-tag color="blue">{{ item.merchantName }}</a-tag>
              </div> -->
              <div class="flex-between">
                <div class="flex-start align-center">
                  <!--whetherChangePrice： 客户端销售改价: 0不允许 1允许-->
                  <template
                    v-if="handsetChangePrice && item.whetherChangePrice"
                  >
                    <b class="price mr-x">¥</b>
                    <input
                      v-model="item.sellingPrice"
                      @focus="(e) => customPriceFocus(e, index)"
                      type="text"
                      class="custom-price price"
                    />
                    <van-button
                      v-show="showSureIndex === index"
                      type="primary"
                      size="small"
                      @click="surePrice(item)"
                      >确定</van-button
                    >
                  </template>
                  <b class="price" v-else>¥ {{ item.sellingPrice }}</b>
                  <del class="disabled ml-1x">{{
                    item.marketPrice | money
                  }}</del>
                </div>
              </div>
              <div class="flex-between">
                <div class="product-setting" @click="openProductSetting(item)">
                  产品设置
                  <img
                    class="img"
                    src="../../../assets/images/fanhui@2x.png"
                    alt=""
                  />
                </div>
                <!-- 加减数量 -->
                <div class="flex-start">
                  <img
                    class="minus"
                    v-show="item.buyNum > 0"
                    src="../../../assets/images/jian@2x.png"
                    @click="updateCart(item, item.buyNum - 1, index)"
                  />
                  <img
                    v-show="item.buyNum === 0"
                    src="../../../assets/images/jian@2x.png"
                    class="minus minus-disabled"
                  />
                  <input
                    :value="item.buyNum"
                    class="input-number"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                    @blur="(e) => inputBlur(e, item)"
                  />
                  <img
                    class="minus"
                    src="../../../assets/images/jia@2x.png"
                    @click="updateCart(item, item.buyNum + 1, index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 游客信息  -->
        <product-player
          v-if="item.buyNum > 0"
          :ref="'productPlayer' + index"
          :cart="cart"
          :appointment="appointment"
          :times="times"
          :params="params"
          :productSetting="item.productSetting"
          @change-cart="changeCart"
          @update-add-buy="changeAddBuy"
          @update-add-buy-batch="changeAddBuyBatch"
          @update-player="updatePlayer"
          @update-fast="updateFast"
          @update-date="updateDate"
          @del-item="delItem"
        ></product-player>
      </li>
    </ul>

    <!-- <a-empty v-else :image="simpleImage" /> -->

    <!--产品设置弹窗-->
    <product-setting ref="productSetting"></product-setting>
  </section>
</template>

<script>
import ProductPlayer from "../components/product-player.vue";
import ProductSetting from "../ticket/components/ProductSetting";
import {
  combinationAddBuy,
  postTicketAddBuy,
  ticketTraveller,
  combinationTraveller,
  combinationFast,
  commonChangePrice,
} from "@/api/cart";
import { fix2Reg } from "@/utils/global";
import { commonAddBuyBatch } from "../../../api/cart";
export default {
  name: "ProductList",
  components: { ProductPlayer, ProductSetting },
  props: {
    api: {
      type: Function,
      default() {
        return new Promise();
      },
    },
    listData: {
      type: Array,
      default() {
        return [];
      },
    },
    format: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      showPicker: false,
      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        name: "", // 产品名称
        categoryId: "", // 分类id
        appointmentRules: this.appointmentRules, // 分时预约规则id
        timeId: this.timeId, // 时段id
      },
      page: 1,
      size: 99,
      list: [], // 产品列表
      // 购物车数据
      cartId: 0, // 购物车id
      setProductId: "", // 设置产品有效期产品id
      showProductValiditySetting: [], // 单个产品的产品有效期设置
      isShowTicketsOperation: false, // 取票操作
      productTakeTicketDefault: "", // 默认取票操作
      tempValue: "",
      showSureIndex: "",

      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [], // 需要出游人的产品
      },
      showMember: false,
      appointment: 0, //分时预约选择 1.产品  2.子景区 3.景区
      times: [], //按子景区的时段列表
      showPayOnly: false,

      currentIndex: 0,
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
    //已设置的产品设置
    productSettings() {
      return this.$store.state.common.productSettings;
    },
    // 改价按钮权限
    handsetChangePrice() {
      // 放开权限时打开
      return this.$store.getters["common/getAuthorizeMenuVOList"].some(
        (item) => item === "handsetChangePrice"
      );
      // return true;
    },
  },
  methods: {
    // 产品设置改变
    // settingChange() {
    //   this.list.forEach((item) => {
    //     // 把已设置的产品设置
    //     item.productSetting = this.productSettings.find(
    //       (temp) => temp.productId === item.id
    //     );
    //   });
    // },
    inputBlur(e, item) {
      let ruleData = this.checkProductRule(item);
      const obj = {
        productId: item.id,
        playDate: this.params.playDate,
        timeShareAppointmentRuleId: this.params.appointmentRules,
        timeIntervalId: this.params.timeId,
        subScenicId: this.params.subScenicId,
        buyNum: Number(e.target.value),
        ...ruleData,
      };
      this.$emit("update-cart", obj);
    },
    updateDate(date, time) {
      this.$emit("update-date", date, time);
    },
    // 获取产品有效期规则
    checkProductRule(item) {
      const productSettings = this.$store.state.common.productSettings;
      // 查找是否有本地配置有效期
      const temp = productSettings.find((val) => val.productId === item.id);
      // 产品有效期
      let defaultType = 0; // 不传就是拿产品默认的，1固定有效期，2预约当天生效，3指定有效期规则
      let defaultRuleIndex = ""; // 当默认类型是指定有效期规则时，有效期规则的下标
      // 如果使用本地有效期设置
      if (temp) {
        if (temp.validity.key >= 2) {
          defaultType = 3;
          if (temp.validity.key < item.validRulesList.length) {
            defaultRuleIndex = temp.validity.key - 2;
          }
          // 如果产品指定规则找不到，则使用第一条默认有效期、
          else {
            defaultType = 1;
            defaultRuleIndex = "";
          }
        } else {
          defaultType = temp.validity.key + 1;
        }
      }
      // 否则使用产品配置
      else if (item.validDefault === 3) {
        defaultType = 3;
        defaultRuleIndex = item.defaultRuleIndex;
      } else {
        defaultType = item.validDefault;
      }
      return {
        writeOffRightNow: temp ? temp.collect.key : 0, // 取票操作
        validType: 1, //是否按照前端传的有效期来计算 1按照 0不按照
        defaultType,
        defaultRuleIndex,
      };
    },
    onConfirm(value) {
      console.log(value);
    },
    customPriceFocus(e, index) {
      this.tempValue = e.target.value;
      this.showSureIndex = index;
    },

    // 确认修改价格
    surePrice(item) {
      if (!item.buyNum) {
        this.$toast.fail("请先加购该产品");
        return;
      }
      if (!fix2Reg.test(item.sellingPrice)) {
        this.$toast.fail("请输入正确的金额");
        item.sellingPrice = this.tempValue;
        return;
      }
      commonChangePrice({
        cartId: this.cartId,
        productId: item.id,
        changePrice: Math.round(item.sellingPrice * 10000) / 100,
      })
        .then((res) => {
          this.$emit("change-cart", res);
        })
        .catch(() => {
          item.sellingPrice = this.tempValue;
        })
        .finally(() => {
          this.showSureIndex = "";
        });
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$emit("change-cart", this.cart);
      this.$nextTick(() => {
        let cart = { ...this.cart };
        // 匹配只传当前产品
        cart.detailProductList = cart.detailProductList.filter(
          (item) => item.productId == this.list[this.currentIndex].id
        );
        cart.id &&
          this.$refs[`productPlayer${this.currentIndex}`][0].updateCart(cart); //传给出游人组件
      });
      // this.$refs[`productPlayer${this.currentIndex}`][0].updateCart(this.cart);
    },
    loadData(params) {
      if (this.listData.length) {
        this.list = this.listData;
      } else {
        this.page = 1;
        this.params = params;
        this.api({
          ...params,
          page: this.page,
          size: this.size,
        })
          .then(({ records }) => {
            records.forEach((item) => {
              item.sellingPrice = item.sellingPrice / 100;
              item.oldPrice = item.sellingPrice;
              item.buyNum = 0;
              // 把已设置的产品设置
              item.productSetting = this.productSettings.find(
                (temp) => temp.productId === item.id
              );
            });
            this.list = records;
          })
          .catch(() => {});
      }
    },
    // 加购更新
    changeAddBuy(obj) {
      let api = postTicketAddBuy;
      if (this.format == 13) {
        api = combinationAddBuy; //组合
      }
      api({
        cartId: this.cart.id,
        ...obj,
      })
        .then((res) => {
          this.cart = res;
          this.broadcast();
        })
        .catch(() => {});
    },
    // 批量加购更新
    changeAddBuyBatch(data) {
      commonAddBuyBatch(data)
        .then((res) => {
          this.cart = res;
          this.broadcast();
        })
        .catch(() => {});
    },
    // 更新游客信息
    updatePlayer(data) {
      let api = ticketTraveller;
      if (this.format == 13) {
        api = combinationTraveller;
      }
      let product = "";
      this.list.forEach((ite, index) => {
        if (ite.id == data.productId) {
          product = ite;
          this.currentIndex = index;
        }
      });
      let productSetting = product?.productSetting;
      if (productSetting && productSetting.shareTourist) {
        let detailProduct = this.cart.detailProductList.find(
          (ite) => ite.productId == data.productId
        );
        data.index = detailProduct ? detailProduct.index : 0;
      }
      api(data)
        .then((res) => {
          // this.changeCart(res);
          this.cart = res;
          this.$nextTick(() => {
            let cart = { ...this.cart };
            // 匹配只传当前产品
            cart.detailProductList = cart.detailProductList.filter(
              (item) => item.productId == this.list[this.currentIndex].id
            );
            cart.id &&
              this.$refs[`productPlayer${this.currentIndex}`][0].updateCart(
                cart
              ); //传给出游人组件
          });
        })
        .catch(() => {});
    },
    // 更新快速通行
    updateFast(data) {
      combinationFast(data)
        .then((res) => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 已存在购物车，更新到购物车 - 不调接口
    changeCart(cart) {
      this.cart = { ...cart };
      this.broadcast();
    },
    // 添加到购物车
    updateCart(item, val, index) {
      this.currentIndex = index;
      // 获取产品有效期规则
      let ruleData = this.checkProductRule(item);
      const obj = {
        productId: item.id,
        playDate: this.params.playDate,
        timeShareAppointmentRuleId: this.params.appointmentRules,
        timeIntervalId: this.params.timeId,
        subScenicId: this.params.subScenicId,
        buyNum: val,
        ...ruleData,
      };
      // 共用游客信息
      if (item.productSetting && item.productSetting.shareTourist && val > 1) {
        delete obj.buyNum;
        obj.detailBuyNum = val;
        let detailProduct = this.cart.detailProductList.find(
          (ite) => ite.productId == item.id
        );
        obj.index = detailProduct ? detailProduct.index : 0;
      }
      this.$emit("update-cart", obj);
    },
    // 更新添加的数量
    updateNum(cart) {
      this.cart = { ...cart };
      const { totalProductList, id } = cart;
      this.cartId = id;
      this.list.forEach((item, index) => {
        item.buyNum = 0;
        item.sellingPrice = item.oldPrice;
        for (let temp of totalProductList) {
          if (item.id === temp.productId) {
            item.buyNum = temp.productFlagPO.buyNum;
            item.sellingPrice = temp.sellingAmount / 100;
            break;
          }
        }
        // 快速下单匹配 -最后一条 this.cart.detailProductList.length - 1
        if (this.cart.detailProductList.length) {
          if (this.cart.detailProductList[0].productId == item.id) {
            this.currentIndex = index;
          }
        }
      });
      this.$nextTick(() => {
        // 匹配只传当前产品
        cart.detailProductList = cart.detailProductList.filter(
          (item) => item.productId == this.list[this.currentIndex].id
        );
        cart.id &&
          this.$refs[`productPlayer${this.currentIndex}`][0].updateCart(cart); //传给出游人组件
      });
    },
    // 删除购物车数量
    delItem(obj) {
      this.$emit("update-cart", obj);
    },
    // 修改数量
    changeNum(e, item) {
      let val = e.target.value;
      if (val) {
        const obj = {
          productId: item.productId,
          index: item.index,
          playDate: this.params.playDate,
          timeShareAppointmentRuleId: this.params.appointmentRules,
          timeIntervalId: this.params.timeId,
          subScenicId: this.params.subScenicId,
          detailBuyNum: val,
        };
        this.$emit("update-cart", obj);
      }
    },

    // 打开产品设置弹窗
    openProductSetting(item) {
      if (this.cartId) {
        this.$toast.fail("请先清空购物车再进行产品设置");
        return;
      }
      this.$refs.productSetting.initData(item);
    },
  },
};
</script>

<style scoped lang="less">
//.门票列表
.ticket-box {
  overflow-x: auto;
  background: #fff;
  .ticket-list {
    // height: 372px;
    // padding: 24px 16px 16px 16px;
    width: auto;
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      border-right: 1px solid transparent;
    }
    .ticket-list-item {
      position: relative;
      border-bottom: 2px solid #e9e9e9;
      // &:hover {
      //   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
      // }
      .img {
        width: 70px;
        height: 70px;
        margin-right: 16px;
        border-radius: 10px;
      }
      .right-content {
        flex: 1;
        height: 100%;
        .custom-price {
          padding: 0 4px;
          border: none;
          width: 50px;
          height: 24px;
          line-height: 24px;
          &:focus {
            background: #eeeeee;
          }
          outline: none;
        }
        .product-setting {
          color: #999999;
          .img {
            width: 7px;
            height: 11px;
          }
        }
        .input-number {
          margin: 0 4px;
          border: none;
          width: 30px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 18px;
          outline-color: #dcdfe6;
          color: #25a2f2;
        }
      }
      .price {
        color: #ff2d2d;
        font-size: 18px;
      }
      .minus {
        width: 25px;
      }
      .fast-key {
        position: absolute;
        top: -16px;
        right: 0;
        .ext {
          min-width: 93px;
          padding: 0 4px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 3px 10px 3px 10px;
          color: #fff;
          cursor: pointer;
        }
        .del {
          .ext;
          background: #ff6767;
        }
        .buy {
          .ext;
          background: #25a2f2;
        }
      }
    }
  }
}

/deep/.van-action-sheet__content {
  background: #f5f5f5;
}
</style>
