<template>
  <div class="root">
    <van-nav-bar title="修改密码" placeholder />
    <van-form @submit="login" class="mt-2x ph-2x mb-4x">
      <van-field
        v-model="oldPassword"
        type="password"
        name="原密码"
        placeholder="输入原始密码"
        :rules="[{ required: true, message: '请输入确认密码' }]"
        clearable
      />
      <van-field
        clearable
        v-model="newPassword"
        type="password"
        name="新密码"
        placeholder="输入新密码"
        :rules="[
          { required: true, message: '请输入确认密码' },
          { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' },
        ]"
      />
      <van-field
        clearable
        v-model="nextNewPassword"
        type="password"
        name="确认密码"
        placeholder="再次输入新密码"
        :rules="[
          { required: true, message: '请输入确认密码' },
          { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' },
          {
            validator: validatePasswordISOK,
            message: '新密码与确认密码不一致',
          },
        ]"
      />
    </van-form>
    <button @click="login" :style="{ backgroundColor: color }">确认修改</button>
  </div>
</template>

<script>
import { getUserInfo } from "../../api/order";
import { setPassword } from "../../api/user";
import { encrypt } from "../../utils/crypt-aes";
export default {
  name: "ChangePassword",
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  created() {
    getUserInfo().then((res) => {
      this.phone = res.phone;
    });
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      nextNewPassword: "",
      phone: "",
    };
  },
  methods: {
    // 校验新旧密码
    validatePasswordISOK(val) {
      return this.newPassword == val;
    },
    login() {
      // 加密
      const obj1 = encrypt(this.oldPassword);
      const obj2 = encrypt(this.newPassword);
      setPassword({
        phone: this.phone,
        oldPwdKey: obj1.key,
        oldPwd: obj1.encryptedWord,
        newPwdKey: obj2.key,
        newPwd: obj2.encryptedWord,
      })
        .then(() => {
          this.$dialog
            .confirm({
              title: "提示",
              message: "修改成功，是否重新登录？",
              confirmButtonText: "重新登录",
              showCancelButton: false,
            })
            .then(() => {
              this.toLogin();
            })
            .catch(() => {});
        })
        .catch(() => {})
        .finally(() => {});
    },
    // 去登录
    toLogin() {
      localStorage.removeItem("token");
      location.href = "#/login";
    },
  },
};
</script>
<style lang="less" scoped>
.root {
  height: 100vh;
  background-color: #fff;
  text-align: center;
  /deep/.van-field__control {
    width: 100%;
    border: none;
    border-bottom: 1px solid #cbcfd2;
    padding-bottom: 16px;
  }
  button {
    width: 320px;
    height: 50px;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}
// 输入框 提示文字颜色
/* WebKit browsers */
input::-webkit-input-placeholder {
  color: #b8b8b8;
}
/* Mozilla Firefox 4 to 18 */
input:-moz-placeholder {
  color: #b8b8b8;
}
/* Mozilla Firefox 19+ */
input::-moz-placeholder {
  color: #b8b8b8;
}
/* Internet Explorer 10+ */
input:-ms-input-placeholder {
  color: #b8b8b8;
}
</style>
