<template>
  <!--产品设置弹窗-->
  <van-action-sheet
    title="产品设置"
    v-model="visible"
    @click-overlay="hiddenProductSetting"
    @cancel="hiddenProductSetting"
  >
    <!-- 共用游客信息 -->
    <van-cell-group>
      <van-cell class="mt-2x" title="共用游客信息">
        <template #extra>
          <van-switch
            v-model="productSetting.shareTourist"
            :activeColor="color"
            @change="updateSetting"
            size="20"
          />
        </template>
      </van-cell>
    </van-cell-group>
    <!--取票操作-->
    <p class="mt-2x mb-x ph-2x gray">取票操作</p>
    <van-cell-group class="">
      <van-cell v-for="item in takeList" :key="item.key" :title="item.name">
        <template #right-icon>
          <van-button v-if="item.default" disabled size="small"
            >已设默认</van-button
          >
          <van-button
            v-else
            plain
            type="primary"
            size="small"
            :color="color"
            @click="setProductSetting(item, 'collect')"
            >设为默认</van-button
          >
        </template>
      </van-cell>
    </van-cell-group>
    <!--产品有效期-->
    <p class="mt-2x mb-x ph-2x gray">产品有效期</p>
    <van-cell-group class="mt-1x">
      <van-cell v-for="item in validityList" :key="item.key" :title="item.name">
        <template #right-icon>
          <van-button v-if="item.default" disabled size="small"
            >已设默认</van-button
          >
          <van-button
            v-else
            :color="color"
            plain
            type="primary"
            size="small"
            @click="setProductSetting(item, 'validity')"
            >设为默认</van-button
          >
        </template>
      </van-cell>
    </van-cell-group>

    <div class="p-2x">
      <van-button
        type="primary"
        :color="color"
        round
        block
        @click="hiddenProductSetting"
        >确定</van-button
      >
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  name: "ProductSetting",

  data() {
    return {
      visible: false,
      productId: "", //     // 产品id
      validRulesList: [], // 规则列表
      validDefault: 1, // 规则索引
      showPicker: false,
      takeList: [], // 取票列表
      validityList: [], // 有效期列表
      productSetting: {
        productId: "",
        shareTourist: false, //是否共用游客信息
        collect: { key: "", name: "" }, //取票操作
        validity: { key: "", name: "", default: "" }, //产品有效期
      }, //当前产品的设置
      currentClick: "collect", //点击类型 collect取票操作 validity产品有效期
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
    //已设置的产品设置
    productSettings() {
      return this.$store.state.common.productSettings;
    },
  },
  created() {
    // console.log("this.productSetting", this.productSetting, info);
  },
  methods: {
    // 初始化数据
    initData(item) {
      this.productId = item.id;
      this.validRulesList = item.validRulesList || [];
      this.validDefault = item.validDefault || 1;
      this.defaultRuleIndex = item.defaultRuleIndex || 0;
      this.setData();
    },
    setData() {
      this.productSetting = this.productSettings.find(
        (item) => item.productId === this.productId
      ) || {
        productId: this.productId,
        shareTourist: false, //是否共用游客信息
        collect: { key: 0, name: "取票后无操作", default: true }, //取票操作
        validity: { key: "", name: "", default: false }, //产品有效期
      };
      // 取票列表
      this.takeList = [
        {
          key: 0,
          name: "取票后无操作",
          default:
            this.productSetting.collect &&
            this.productSetting.collect.key === 0,
        },
        {
          key: 1,
          name: "取票后自动核销",
          default:
            this.productSetting.collect &&
            this.productSetting.collect.key === 1,
        },
      ];
      // 产品有效期
      if (this.validRulesList && this.validRulesList.length) {
        let list = this.validRulesList;
        list.forEach((item, index) => {
          item.key = index;
          item.name = item.ruleName;
          item.default =
            this.productSetting.validity &&
            this.productSetting.validity.key === item.key;
        });
        // 如果缓存没有设置过默认
        if (
          !this.productSetting.validity ||
          !this.productSetting.validity.default
        ) {
          let i = 0;
          if (this.validDefault < 3) {
            i = this.validDefault - 1;
          } else {
            i = this.validDefault - 1 + this.defaultRuleIndex;
          }
          list[i].default = true;
          this.productSetting.validity.key = list[i].key;
          this.productSetting.validity.name = list[i].name;
        }
        this.validityList = list;
      }
      this.visible = true;
    },
    // 设置取票操作、产品有效期
    setProductSetting(item, type) {
      this.productSettings.forEach((temp) => {
        temp[type].default = false;
      });
      this.productSetting[type].default = true;
      this.productSetting[type].key = item.key;
      this.productSetting[type].name = item.name;
      this.updateSetting();
    },
    // 更新设置
    updateSetting() {
      const productSettings = this.productSettings;
      const temp = productSettings.find(
        (item) => item.productId === this.productId
      );
      if (!temp) {
        productSettings.push(this.productSetting);
      }
      this.$store.commit("common/productSetting", productSettings);
      this.setData();
      // this.$emit("change", productSettings);
    },
    // 隐藏产品设置弹窗
    hiddenProductSetting() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="less">
.cancel {
  position: absolute;
  right: 10px;
  color: #b8b8b8;
}
.default {
  border: 1px solid;
  border-radius: 5px;
}
.isdefault {
  color: #b8b8b8;
}
</style>
