<template>
  <!-- 直接收款 收款金额 和 收款备注组件 -->
  <div class="text-c">
    <div class="cashier-content pl-1x">
      <p class="cashier-content-text text-l font-m">本次收款金额：</p>
      <div class="cashier-content-input">
        <span class="cashier-content-moneyIcon">￥</span>
        <input
          v-model="collection"
          class="font-s pv-x"
          type="number"
          placeholder="请输入"
        />
      </div>
    </div>
    <div class="cashier-remarks pl-2x">
      <p class="cashier-remarks-text font-m">备注收款事项：</p>
      <input
        v-model="collectionText"
        class="font-s pv-x"
        type="text"
        placeholder="请输入"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collection: "",
      collectionText: "",
    };
  },
};
</script>

<style lang="less" scoped>
.cashier-content {
  overflow: hidden;
  width: 94%;
  height: 1.54rem;
  margin: 0.019rem auto;
  background-color: #fff;
  .cashier-content-text {
    margin-top: 0.15rem;
  }
  .cashier-content-input {
    display: flex;
    align-items: center;
    .cashier-content-moneyIcon {
      font-size: 36px;
      font-weight: 700;
    }
    input {
      margin-left: 0.125rem;
      border: none;
      border-bottom: #f1f1f1 1px solid;
    }
  }
}
.cashier-remarks {
  display: flex;
  align-items: center;
  width: 94%;
  margin: 0.15rem auto;
  height: 1.15rem;
  background-color: #fff;
  input {
    border: none;
  }
}
</style>
