<!--景区列表-->
<template>
  <div class="wrap">
    <div
      class="flex-between scenic-box pv-2x"
      :style="{ 'background-color': $store.getters['common/color'].primary }"
    >
      <div class="scroll-view" :scroll-into-view="intoScenic">
        <div class="flex-start scenic">
          <div
            class="scenic-item"
            v-for="(item, index) in subScenics"
            :key="index"
            @click="tabChange(index)"
            :class="{ white: item.subScenicId === subScenicId }"
            :id="'scenic' + index"
          >
            {{ item.subScenicName }}
          </div>
        </div>
      </div>

      <div class="more-scenic" @click="showMoreScenic">
        <img
          class="image"
          src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16413/1641365288307.png"
        />
      </div>
    </div>
    <!-- 选择子景区弹窗 -->
    <van-popup v-model="visibleScenic" get-container="body" position="bottom">
      <van-picker
        show-toolbar
        :columns="subScenics"
        value-key="subScenicName"
        :default-index="0"
        @confirm="onConfirm"
        @cancel="visibleScenic = false"
      />
    </van-popup>
    <!-- 搜索组件，放在外层会和选择子景区弹窗有层级问题 -->
    <!-- <search-index
      ref="searchForm"
      :params="{ subScenicId, format }"
      @search-number="searchNumber"
      @changeCard="changeCard"
      @changeType="changeType"
      class="search-index"
    /> -->
  </div>
</template>

<script>
import { subScenics as getSubScenic } from "../../../api/merchant";

export default {
  name: "index-header",
  components: {},
  props: {
    // 展示搜索框
    showSeach: {
      type: Boolean,
      default: true,
    },
  },
  // components: { SearchForm },
  data() {
    return {
      searchType: 0, // 搜索类型
      // 子景区
      intoScenic: "", // 滚动到指定子景区
      current: 0,
      // 子景区列表
      subScenics: [],
      subScenicId: "", //已选
      visibleScenic: false,
      merchantId: "", // 景区id
      userId: "", // 用户Id
    };
  },

  computed: {
    format() {
      return this.$store.state.common.currentFormat;
    },
  },

  created() {
    this.loadScenics();
  },
  methods: {
    changeCard(info) {
      this.$emit("changeCard", info);
    },
    // 搜索查询
    searchNumber(val) {
      this.$router.push({
        path: "/home-new/TheSearch",
        query: {
          searchNumber: val,
        },
      });
    },
    // 加载子景区列表 和时段
    async loadScenics() {
      // 如果切换景区或退出登录或子景区列表长度为0 则不用刷新数据
      if (
        localStorage.merchantId !== this.merchantId ||
        localStorage.userId !== this.userId ||
        this.subScenics.length === 0
      ) {
        this.merchantId = localStorage.merchantId;
        this.userId = localStorage.userId;
        // 窗口端控制 filterSubScenic
        const { subScenics } = await getSubScenic({ filterSubScenic: true });
        if (subScenics && subScenics.length) {
          this.subScenics = subScenics;
          this.tabChange(0);
        }
      }
    },
    onConfirm(val, index) {
      this.tabChange(index);
    },
    // 点击tab
    tabChange(i) {
      this.subScenicId = this.subScenics[i].subScenicId;
      this.current = i;
      this.visibleScenic = false;
      this.$emit("changeScenic", this.subScenicId);
    },
    // 展示更多景区弹窗
    showMoreScenic() {
      this.visibleScenic = true;
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  position: sticky;
  top: 0;
  z-index: 9;
}
.scenic-box {
  // background: #386cdf;
  padding-bottom: 55px;
  .scroll-view {
    width: 88%;
    height: max-content;
    overflow-x: scroll;
    .scenic {
      width: max-content;
      min-width: 100%;
      color: #f9f9f9;
      .scenic-item {
        position: relative;
        padding: 5px 12px 12px 12px;
        font-size: 14px;
        font-weight: bold;
      }
      .white {
        font-size: 16px;
        font-weight: bold;
      }
      .white:after {
        position: absolute;
        left: 32%;
        bottom: 8px;
        display: block;
        content: "";
        width: 22px;
        height: 3px;
        border: 3px solid white;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 50% 100%;
        border-bottom-left-radius: 50% 100%;
        border-top: none;
      }
    }
  }

  /*更多景区*/
  .more-scenic {
    width: 38px;
    height: 30px;
    text-align: center;
    .image {
      width: 16px;
      height: 14px;
      margin-top: 5px;
    }
  }
}

.scenic-list {
  max-height: 80vh;
  overflow: auto;
  .scenic-item {
    padding: 7px 16px;
    font-size: 18px;
    &.active {
      color: #2c9e6c;
    }
  }
}

.content {
  min-height: 500px;
}
/* 隐藏滚动条 */
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.search-index {
  position: sticky;
  top: 63px;
  margin-top: -50px;
  z-index: 9;
}
</style>
