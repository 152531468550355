<template>
  <div class="root">
    <!-- 头部 直接收款标题 -->
    <div
      class="flex-center bold font-n text-c header-title mb-2x"
      :style="{ 'background-color': color }"
    >
      直接收款
    </div>
    <!-- tab 栏 -->
    <div class="flex-around tab">
      <!-- 无产品收银 -->
      <div
        class="cashier text-c"
        @click="nativeIndex(0)"
        :class="[natIndex === 0 ? 'native' : '']"
        :style="{ color: natIndex === 0 ? color : '' }"
      >
        无产品收银
      </div>
      <!-- 补差价 -->
      <div
        class="price-difference text-c"
        @click="nativeIndex(1)"
        :class="[natIndex === 1 ? 'native' : '']"
        :style="{ color: natIndex === 1 ? color : '' }"
      >
        补差价
      </div>
    </div>
    <!-- 无产品收银内容 -->
    <div v-show="natIndex == 0" class="text-c">
      <!-- tabs 商户列表 -->
      <div class="scroll ph-1x pt-1x">
        <div
          class="scroll-item ml-2x ph-2x pv-x"
          :class="tabIndex == index ? 'taNative' : ''"
          v-for="(item, index) in merchantList"
          :key="item.id"
          @click="getMerchantFn(index, item)"
          :style="tabIndex == index ? tabStyle : ''"
        >
          {{ item.merchantName }}
        </div>
      </div>
      <directPayCollection ref="directPayCollectionOne"></directPayCollection>
      <van-button class="btnOk" :color="color" size="large" @click="addTocarts"
        >确定</van-button
      >
    </div>
    <!-- 补差价内容 -->
    <div v-show="natIndex == 1" class="text-c">
      <div class="price-difference-content">
        <!-- 查询票号 -->
        <div class="price-difference-search mt-x ml-1x mr-1x mb-1x">
          <input
            v-model="ticketNumber"
            class="pl-4x"
            type="number"
            placeholder="请输入票号"
          />
          <button :style="{ 'background-color': color }" @click="search">
            查询
          </button>
          <div class="flex-center ml-2x">
            <van-icon
              :name="scanIcon"
              @click="scan"
              size="23"
              color="#545454"
            />
          </div>
        </div>
        <!-- 票号信息展示 -->
        <div
          class="price-difference-details mh-1x"
          v-for="(item, index) in ticketNumberText"
          :key="index"
        >
          <div class="flex-between">
            <div>票号：{{ item.auxiliaryId }}</div>
            <div>状态：{{ item.statusText }}</div>
          </div>
          <p>{{ item.productName }}</p>
          <p>销售渠道：{{ item.buyChannelsText }}</p>
          <button class="font-xs" @click="makeupthedifference">补差价</button>
        </div>
      </div>
      <!-- 收款组件 -->
      <directPayCollection
        ref="directPayCollectionTwo"
        class="mt-1x"
      ></directPayCollection>
      <van-button class="btnOk" :color="color" size="large" @click="addTocarts"
        >确定</van-button
      >
    </div>
    <!-- 选择支付方式组件 -->
    <pay-way
      ref="PayWay"
      @success="paySuccess"
      :show-pay-way="false"
      :cart-id="cartId"
    />
  </div>
</template>

<script>
import { searchTicketNumber } from "@/api/search";
import { skuDifference, skuNoProduct } from "@/api/product";
import { addTocart } from "@/api/cart.js";
import directPayCollection from "./components/directPay-collection.vue";
import PayWay from "../../components/PayWay.vue";
import { getMerchantListType } from "../../api/merchant";
import { updateQuickOrderWriteOffStatus } from "../../api/order";
import { scanQRCode } from "../../utils/hardware";
import moment from "moment";
export default {
  name: "DirectPay",

  components: {
    directPayCollection,
    PayWay,
  },

  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },

  data() {
    return {
      moment,
      natIndex: 0, // tab 选中
      ticketNumber: "", // 票号
      ticketNumberText: [], // 票号信息
      productId2: "", // 无产品收银产品id
      productId9: "", // 补差价产品id
      cartId: 0, // 购物车id
      merchantList: [], // 商户列表
      tabIndex: 0,
      tabStyle: {
        color: this.$store.getters["common/color"].primary,
      },
      payParams: {}, // 计时结束支付参数
      scanIcon: require("@/assets/images/record/sys.png"), // 扫码图标
      isDifference: true, //是否有补差价产品
    };
  },

  created() {
    //获取无收银产品
    this.getMerchantListType();
    if (this.$route.query.id) {
      this.payParams = this.$route.query;
      this.$nextTick(() => {
        this.$refs.directPayCollectionOne.collection = this.$route.query.money;
        this.$refs.directPayCollectionOne.collectionText =
          this.$route.query.remark;
      });
    }
  },

  methods: {
    scan() {
      scanQRCode(this.scanOk);
    },
    scanOk(code) {
      this.ticketNumber = code;
      this.search();
    },
    // 切换商户
    getMerchantFn(index, item) {
      this.tabIndex = index;
      this.getProduct2(item.id);
      this.getProduct9(item.id);
    },
    // 获取商户
    async getMerchantListType() {
      const res = await getMerchantListType({ merchantType: 6 });
      this.merchantList = res;
      // 页面初始化 获取第一个选中的 商户id 发送请求
      this.getProduct2(this.merchantList[0].id);
      this.getProduct9(this.merchantList[0].id); //补差价
    },
    // tab 切换
    nativeIndex(index) {
      this.natIndex = index;
      if (this.natIndex == 0) {
        this.$refs.directPayCollectionTwo.collection = "";
        this.$refs.directPayCollectionTwo.collectionText = "";
      } else {
        this.$refs.directPayCollectionOne.collection = "";
        this.$refs.directPayCollectionOne.collectionText = "";
      }
    },
    // 点击查询
    search() {
      if (!this.ticketNumber) {
        return;
      }
      searchTicketNumber({ ticketNo: this.ticketNumber }).then((res) => {
        this.ticketNumberText = res;
        if (!this.isDifference) {
          this.$toast("无补差价产品！");
        }
      });
    },
    // 点击补差价
    makeupthedifference() {
      this.$refs.directPayCollectionTwo.collectionText = "补差价";
    },
    // 获取无产品收银
    getProduct2(id) {
      if (!id) return;
      skuNoProduct({ merchantId: id })
        .then((res) => {
          this.productId2 = res.id;
        })
        .catch(() => {});
    },
    // 获取补差价产品
    getProduct9(merchantId) {
      skuDifference({ merchantId })
        .then((res) => {
          if (res) {
            this.productId9 = res.id;
            this.isDifference = true;
          } else {
            this.isDifference = false;
          }
        })
        .catch(() => {});
    },
    addTocarts() {
      const data = {
        businessCategory: "",
        changePrice: "",
        productId: "",
        remark: "",
        ticketNo: "",
      };
      // 无产品收银
      if (this.natIndex == 0) {
        data.productId = this.productId2;
        data.businessCategory = 2;
        data.changePrice = this.$refs.directPayCollectionOne.collection * 100;
        data.remark = this.$refs.directPayCollectionOne.collectionText;
      }
      // 补差价
      if (this.natIndex == 1) {
        data.productId = this.productId9;
        data.businessCategory = 9;
        data.changePrice = this.$refs.directPayCollectionTwo.collection * 100;
        data.remark = this.$refs.directPayCollectionTwo.collectionText;
        data.ticketNo = this.ticketNumber;
        // 未输入票号
        if (!this.ticketNumber) {
          this.$toast("请输入票号");
          return;
        }
      }
      if (!data.changePrice) {
        this.$toast("请输入收款价格");
        return;
      }
      if (!data.remark) {
        this.$toast("请输入收款备注价格");
        return;
      }
      addTocart(data).then((res) => {
        // 加入购物车成功
        this.cartId = res.id;
        this.$nextTick(() => {
          this.$refs.PayWay.pay();
        });
      });
    },
    // 支付成功
    paySuccess() {
      if (this.payParams.id) {
        updateQuickOrderWriteOffStatus({
          id: this.payParams.id,
          writeOffStatus: 3,
          updateTime: moment().valueOf(),
        })
          .then(() => {
            this.payParams = {};
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.root {
  background-color: #f2f2f2;
  .header-title {
    color: #fff;
    height: 66px;
  }
  .scroll {
    width: 94%;
    height: 56px;
    margin: 1px auto 10px;
    text-align: center;
    vertical-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: #fff;
    .taNative {
      background-color: #ebf1ff;
    }
    .scroll-item {
      line-height: 27px;
      border-radius: 4px;
      white-space: nowrap;
      display: inline-block;
    }
  }
  .tab {
    width: 94%;
    height: 45px;
    border-radius: 10px 10px 0 0;
    align-content: center;
    margin: 0 auto;
    .cashier {
      width: 50%;
      height: 45px;
      line-height: 45px;
      border-radius: 6px 6px 0 0;
      background: linear-gradient(#e6e6e6, #fff);
    }
    .price-difference {
      width: 50%;
      height: 45px;
      line-height: 45px;
      border-radius: 6px 6px 0 0;
      background: linear-gradient(#e6e6e6, #fff);
    }
    .native {
      background: #fff;
    }
  }
  .price-difference-content {
    overflow: hidden;
    width: 94%;
    margin: 10px auto;
    background-color: #fff;
    .price-difference-search {
      height: 34px;
      display: flex;
      justify-content: center;
      input {
        flex: 4;
        border: none;
        border-radius: 6px;
        background: url("../../assets/images/format/search.png") no-repeat;
        background-size: 16px;
        background-position: 14px 50%;
        background-color: #f3f3f3;
      }
      button {
        border-radius: 6px;
        border: none;
        color: #fff;
        flex: 1;
      }
    }
    .price-difference-details {
      font-size: 12px;
      text-align: center;
      color: #666666;
      p {
        text-align: left;
        margin-top: 5px;
      }
      button {
        color: #386cdf;
        width: 80px;
        height: 30px;
        border-radius: 15px;
        border: #386cdf 1px solid;
        background-color: #fff;
        margin: 7px auto;
      }
    }
  }
}
.btnOk {
  width: 94%;
}
// 输入框 提示文字颜色
/* WebKit browsers */
input::-webkit-input-placeholder {
  color: #e2e2e2;
}
/* Mozilla Firefox 4 to 18 */
input:-moz-placeholder {
  color: #e2e2e2;
}
/* Mozilla Firefox 19+ */
input::-moz-placeholder {
  color: #e2e2e2;
}
/* Internet Explorer 10+ */
input:-ms-input-placeholder {
  color: #e2e2e2;
}
</style>
