<!-- 新版手持机首页 -->
<template>
  <div class="">
    <!--首页景区header-->
    <index-header
      ref="indexHeader"
      @changeScenic="changeScenic"
      @changeCard="changeCard"
    />
    <!-- 搜索组件，放在外层会和选择子景区弹窗有层级问题 -->
    <search-index
      ref="searchForm"
      :params="{ subScenicId, format }"
      :position="true"
      @search-number="searchNumber"
      @changeCard="changeCard"
      @changeType="changeType"
      @newList="newList"
      @addCart="addCart"
    />
    <template v-if="searchType === 3">
      <!--    subScenicId 子景区id，format 业态id-->
      <!-- 选择业态时段组件 -->
      <index-format
        class="mt-1x"
        ref="IndexFormat"
        @changeFormat="changeFormat"
        @updateCartDate="updateCartDate"
        @change="change"
      />
      <!--各个业态组件-->
      <component
        v-if="subScenicId"
        :is="component[format]"
        :elm="component[format]"
        :id="format"
        ref="formatComponent"
        class="comm"
        @close="close"
      />
    </template>
    <record-list ref="recordList" v-else :act-index="actIndex"></record-list>
  </div>
</template>

<script>
import IndexHeader from "./components/index-header.vue";
import SearchIndex from "./components/search-index.vue";
import RecordList from "./components/record-list.vue";
import IndexFormat from "./components/index-format.vue";
import SellTicket from "./ticket/SellTicket.vue"; //票务
import CombinationSellTicket from "./combination/SellTicket"; //组合
import PlayllTicket from "./play/SellTicket"; //游乐
import {
  ticketCommonlyVisitorBatch,
  combinationCommonlyVisitorBatch,
} from "@/api/cart";
import { userinfoSettingInfoList } from "@/api/product";
export default {
  name: "HomeIndex",
  components: {
    IndexHeader,
    IndexFormat,
    SellTicket,
    CombinationSellTicket,
    RecordList,
    PlayllTicket,
    SearchIndex,
  },
  data() {
    return {
      subScenicId: "", // 默认用来测试子景区id
      format: "6",
      // 业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      component: {
        6: "SellTicket", //票务
        13: "CombinationSellTicket", //组合
        7: "PlayllTicket", //游乐
      },
      params: {},
      searchType: 2, // 搜索组件类型 1：查询 2：核销 3：下单
      actIndex: 1,
      timeKey: "", //缓存的时间戳
    };
  },
  provide() {
    return {
      changeScan: this.changeScan,
    };
  },

  //
  created() {
    this.timeKey = Date.parse(new Date());
  },

  mounted() {
    this.reload();
    this.changeScan();
    this.activeSearchType();
  },

  methods: {
    // 激活操作
    activeSearchType() {
      // 放开权限时打开 start
      const getAuthorizeMenuVOList =
        this.$store.getters["common/getAuthorizeMenuVOList"];
      // 核销激活
      if (
        getAuthorizeMenuVOList.indexOf("handsetVerification") > -1 ||
        this.$store.getters["common/isSupperManager"]
      ) {
        this.searchType = 2;
      }
      // 查询激活
      else if (getAuthorizeMenuVOList.indexOf("handsetQuery") > -1) {
        this.searchType = 1;
      }
      // 下单激活
      else if (
        this.$store.getters["common/isSupperManager"] ||
        this.$store.state.common.formatTypeList.length
      ) {
        this.searchType = 3;
      }
      this.$refs.searchForm.searchType = this.searchType;
      // 放开权限时打开 end
    },
    // 快速匹配加入购物车
    addCart(idCard) {
      // 先动态获取 游客信息的id
      userinfoSettingInfoList().then((temp) => {
        let field = temp.find((ite) => ite.fieldType === 6);
        let obj = {
          playDate: this.params.playDate,
          timeIntervalId: this.params.timeId,
          subScenicId: this.subScenicId,
          travelers: [
            {
              visitorFieldList: [
                { id: field.id, fieldType: 6, fieldValue: idCard },
              ],
            },
          ],
        };
        let api = ticketCommonlyVisitorBatch;
        if (this.format == 13) {
          api = combinationCommonlyVisitorBatch;
        }
        // 加入购物车
        api(obj).then((res) => {
          this.$refs.formatComponent.changeCart(res);
        });
      });
    },
    newList() {
      setTimeout(() => {
        this.$refs.recordList.getVerificationrecord();
      }, 1000);
    },
    // 搜索查询
    searchNumber(val) {
      this.$router.push({
        path: "/home-new/TheSearch",
        query: {
          searchNumber: val,
        },
      });
    },
    // 选择查询核销
    changeType(type) {
      this.searchType = type;
      if (type === 1) {
        this.actIndex = 0;
      } else if (type === 2) {
        this.actIndex = 1;
      } else if (type === 3) {
        this.$nextTick(() => {
          this.$refs.IndexFormat.changeScenic(this.subScenicId);
        });
      }
    },
    // 身份证匹配产品加入购物车
    close() {
      this.changeScan();
    },
    changeCard(info) {
      let obj = {
        buyNum: 1,
        playDate: this.params.playDate,
        timeShareAppointmentRuleId: this.params.appointmentRules,
        timeIntervalId: this.params.timeId,
        productId: info.sybSystemIdProductId,
        subScenicId: this.subScenicId,
      };
      this.$refs.formatComponent.updateCart(obj);
    },
    changeScan() {
      this.$nextTick(() => {
        this.$refs.searchForm.watchScan();
      });
    },
    // 重新加载
    reload() {
      this.$refs.indexHeader.loadScenics();
      this.changeScan();
    },
    // 切换景区
    changeScenic(subScenicId) {
      this.subScenicId = subScenicId;
      if (this.searchType === 3) {
        this.$refs.IndexFormat.changeScenic(subScenicId);
      }
    },
    //切换业态
    changeFormat(id) {
      this.format = id;
      this.$store.commit("common/currentFormat", id);
    },
    change(params) {
      this.params = params;
      this.$nextTick(() => {
        if (this.$refs.formatComponent) {
          this.$refs.formatComponent.loadData(params);
        }
      });
    },
    // 更新预约日期
    updateCartDate(datesId) {
      this.$refs.formatComponent.updateCartDate(datesId);
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  .comm {
    background-color: #f3f3f3;
  }
}
</style>
