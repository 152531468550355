<template>
  <van-action-sheet title="取单" v-model="visible">
    <div class="list" v-for="(item, index) in list" :key="item.id">
      <div class="ticket" v-for="temp in item.productList" :key="temp.spuId">
        <div class="flex-between">
          <span>{{ temp.productName }}</span>
          <span class="red">￥{{ temp.sellingAmount / 100 }}</span>
        </div>
        <div class="flex-between font-s mt-x">
          <span>数量：{{ temp.productFlagPO.buyNum }}</span>
          <span
            >小计：￥{{
              (temp.productFlagPO.buyNum * temp.sellingAmount) / 100
            }}</span
          >
        </div>
      </div>
      <div class="product">
        <p class="bold">
          订单总价：<span class="red">￥{{ item.totalPrice / 100 }}</span>
        </p>
        <p class="gray">
          挂单时间：{{
            item.cartFlagPO.pauseCartTime | date("yyyy-mm-dd hh:mm")
          }}
        </p>
      </div>
      <div class="footer pb-2x">
        <button class="del" @click="delcart(item.id, index)">删除</button>
        <button class="get ml-2x" @click="getcart(item.id)">取单</button>
      </div>
    </div>
    <div class="default" v-if="!list.length">
      <img src="../../../../assets/images/me/zanwu.png" alt="" />
      <div>暂无数据</div>
    </div>
  </van-action-sheet>
</template>

<script>
import {
  commonCartList,
  delPauseCart,
  commonTakeOut,
} from "../../../../api/cart";
export default {
  name: "TakeOrder",
  data() {
    return {
      list: [],
      visible: false, //弹窗展示
    };
  },
  computed: {
    currentFormats() {
      return this.$store.state.common.currentFormat;
    },
  },
  created() {},
  methods: {
    loadData() {
      commonCartList({
        pauseCartFormatType: this.currentFormats,
      })
        .then((res) => {
          this.list = res;
          this.visible = true;
        })
        .catch(() => {});
    },
    // 删除
    delcart(cartId, ind) {
      delPauseCart({ cartId }).then(() => {
        this.list.splice(ind, 1);
        this.$toast("删除成功");
      });
    },
    // 取单操作
    getcart(id) {
      commonTakeOut({ id })
        .then((res) => {
          // this.$store.commit("common/setCartList", res);
          this.visible = false;
          this.$emit("change", res);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 98%;
  border-radius: 6px;
  margin: 14px auto;
  background-color: #fff;
  .ticket {
    width: 100%;
    padding: 13px;
    border-top: 1px solid #f1f1f1;
  }
  .product {
    width: 100%;
    padding: 13px;
  }
  .footer {
    text-align: right;
    button {
      width: 90px;
      height: 30px;
      border-radius: 100px;
      background-color: #fff;
    }
    .del {
      border: 1px solid #c8dae7;
      color: #c8dae7;
    }
    .get {
      border: 1px solid #386cdf;
      color: #386cdf;
    }
  }
}
.default {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  text-align: center;
  img {
    width: 92px;
  }
  div {
    margin-top: 18px;
    color: #d7d7d7;
    font-size: 12px;
  }
}
</style>
