<template>
  <!-- 自定义打印 -->
  <van-action-sheet title="自定义打印规则" v-model="visible">
    <div class="wrap" v-for="item in productList" :key="item.productId">
      <div class="bd-b">
        <div class="flex-between ph-2x pt-2x">
          <strong>{{ item.productName }}</strong
          ><span>x{{ item.buyNum }}</span>
        </div>
        <div class="flex-between ph-2x pb-2x">
          <span>{{ item.sellingAmount | money }}</span>
          <div>
            小计：<span>{{ item.total | money }}</span>
          </div>
        </div>
      </div>
      <div class="flex-between p-2x">
        <div class="gray">
          打印规则：<span>{{ item.printRule }}</span>
        </div>
        <div :style="{ color: color }" @click="showEdit(item)">
          <van-icon name="edit" class="mr-x" />修改
        </div>
      </div>
    </div>
    <div class="p-2x">
      <van-button round block :color="color" @click="handleOk(true)"
        >确定</van-button
      >
    </div>
    <van-dialog
      v-model="show"
      title="修改打印规则"
      show-cancel-button
      @confirm="handleOk"
    >
      <div class="ph-2x pb-2x">
        <van-radio-group v-model="printForm.cartPrintType">
          <van-radio
            v-for="item in types"
            :key="item.value"
            :name="item.value"
            :checked-color="$store.getters['common/color'].primary"
            class="mt-3x"
          >
            <div class="flex-between">
              <div>{{ item.name }}</div>
            </div>
          </van-radio>
        </van-radio-group>
        <!-- 自定义 -->
        <div v-if="printForm.cartPrintType === 3" class="mt-2x">
          <div class="flex-start">
            <span class="font-n">本次打印票数</span>
            <input
              v-model="printForm.printNum"
              type="text"
              style="width: 45%"
              class="mh-1x my-input"
              placeholder="输入"
            />
            <span class="font-n">张</span>
          </div>
          <div class="flex-start mt-1x">
            <span class="font-n">每张票支持核销次数</span>
            <input
              v-for="(item, index) in printForm.times"
              :key="index"
              v-model="printForm.times[index]"
              type="text"
              style="width: 30%"
              class="mh-1x my-input"
              placeholder="输入"
              @change="calculateNum"
            />
          </div>
          <p class="font-m mt-1x">
            提示：<span class="gray"
              >当前共<span class="red bold mh-1x">{{ tempRow.buyNum }}</span
              >张{{ tempRow.productName }}，本次打印<span
                class="red bold mh-1x"
              >
                {{ printForm.printNum }}</span
              >张票，包含<span class="red bold mh-1x">{{ cludeNum }}</span
              >张{{ tempRow.productName }}，剩余<span class="red bold mh-1x">{{
                remainingNum
              }}</span
              >张{{ tempRow.productName }}。</span
            >
          </p>
        </div>
      </div>
    </van-dialog>
  </van-action-sheet>
</template>

<script>
import { commonPrintProductList, commonPrintWay } from "@/api/cart";
export default {
  name: "CustomPrint",
  data() {
    return {
      visible: false, // 弹窗
      cartId: "",
      productList: [], // 产品列表
      show: false,
      types: [
        {
          name: "一个产品一票",
          value: 1,
        },
        {
          name: "一种产品一票",
          value: 2,
        },
        {
          name: "自定义规则",
          value: 3,
        },
      ],
      printType: 1, // 打印方式
      printForm: {
        cartPrintType: 1, //打印规则：1后台打印规则，2窗口打印规则，3自定义规则
        printNum: 1, //本次打印数量
        times: [1], //每张票支持核销次数，数字数组
      },
      cludeNum: 0, // 包含票数
      remainingNum: 0, // 剩余票数
      tempRow: {
        buyNum: 0,
      }, //当前点击产品
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    showEdit(item) {
      this.tempRow = item;
      if (item.printSettingPO) {
        this.printForm.cartPrintType = item.printSettingPO.cartPrintType;
        this.printForm.printNum = item.printSettingPO.printNum || 1;
        this.printForm.writeOffNum = item.printSettingPO.writeOffNum || [1];
      }
      this.calculateNum();
      this.show = true;
    },
    //确定保存按钮
    handleOk(back) {
      // 如果是自定义规则，则需要校验
      if (!this.printForm.printNum) {
        this.$toast("请输入本次打印票数");
        return;
      } else if (this.printType === 3) {
        if (this.printForm.printNum > this.tempRow.buyNum) {
          this.$toast("打印的票数不能大于产品数量");
          return;
        } else if (isNaN(this.cludeNum)) {
          this.$toast("请正确填写核销次数");
          return;
        } else if (this.cludeNum > this.tempRow.buyNum) {
          this.$toast("每张票支持核销次数的总和不能大于产品数量");
          return;
        } else if (this.printForm.times.some((item) => !item)) {
          this.$toast("请填写每张票支持核销次数");
          return;
        }
      }
      commonPrintWay({
        cartId: this.cartId,
        productId: this.tempRow.productId,
        cartPrintType: this.printForm.cartPrintType,
        printNum: this.printForm.printNum,
        writeOffNum: this.printForm.times,
      })
        .then(() => {
          this.show = false;

          this.$store.commit("common/customPrintType", 3);
          if (back) {
            this.visible = false;
          } else {
            this.loadProductList(this.cartId);
          }
        })
        .catch(() => {});
    },
    //计算核销次数之和及剩余核销次数
    calculateNum() {
      let totalNum = 0;
      for (let i = 0; i < this.printForm.times.length; i++) {
        totalNum += this.printForm.times[i];
      }
      this.cludeNum = totalNum;
      this.remainingNum = this.tempRow.buyNum - totalNum;
    },
    // 加载产品列表
    async loadProductList(cartId) {
      this.cartId = cartId;
      return commonPrintProductList({
        id: this.cartId,
      })
        .then(({ productList }) => {
          productList.forEach((item) => {
            let typeName = "一种产品一票";
            if (item.printSettingPO) {
              const type = this.types.find(
                (temp) => temp.value === item.printSettingPO.cartPrintType
              );
              typeName = type.name;
            }
            item.buyNum = item.productFlagPO.buyNum;
            item.printRule = typeName;
            item.total = item.sellingAmount * item.buyNum;
          });
          this.productList = productList;
          this.visible = true;
          return true;
        })
        .catch(() => false);
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  background-color: white;
}
.gray {
  color: #b8b8b8;
}
.my-input {
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  color: #898989;
}
</style>
