<template>
  <div>
    <!-- 颜色设置弹出层 -->
    <van-popup
      @close="closeFn"
      @click-overlay="clickoverlay"
      v-model="colorShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <!-- 颜色设置 -->
      <div class="title">颜色设置</div>
      <div class="colorList">
        <div
          v-for="(item, index) in colorList"
          :key="index"
          @click="isTheme(index, item.title)"
          :style="{ backgroundColor: item.primary }"
        >
          <van-icon
            v-show="showIconIndex == index"
            name="checked"
            size="22px"
            color="#fff"
          />
        </div>
      </div>
      <div class="footer" @click="close">取消</div>
    </van-popup>
  </div>
</template>

<script>
import themeColor from "../../utils/theme-color";
export default {
  name: "ColorSetting",
  computed: {
    // 获取 主题色 列表
    colorList() {
      let arr = [];
      for (let i in themeColor) {
        let value = themeColor[i];
        value.title = i;
        arr.push(value);
      }
      return arr;
    },
  },
  created() {
    if (localStorage.getItem("themeIndex")) {
      this.showIconIndex = localStorage.getItem("themeIndex");
    } else {
      this.showIconIndex = 0;
    }
  },
  data() {
    return {
      colorShow: false,
      themecolorList: {
        // 畅游橙-默认主题
        theme386CDF: {
          backgroundColor: "#386CDF", // 常规
        },
        // 深蓝
        them3B49C7: {
          backgroundColor: "#3B49C7",
        },
        // 畅游红a
        themeff4242: {
          backgroundColor: "#ff4242",
        },
        // 浅红
        themeFF5F46: {
          backgroundColor: "#FF5F46",
        },
        // 绿色
        theme19B81E: {
          backgroundColor: "#19B81E",
        },
        // 深绿
        theme2C9E6C: {
          backgroundColor: "#2C9E6C",
        },
        // 深色
        theme354065: {
          backgroundColor: "#354065",
        },
        // 橙色
        themeF1A41A: {
          backgroundColor: "#F1A41A",
        },
        // 紫色
        theme715DC2: {
          backgroundColor: "#715DC2",
        },
        // 灰色
        theme949CA4: {
          backgroundColor: "#949CA4",
        },
      },
      showIconIndex: 0,
      colse: true,
    };
  },
  methods: {
    clickoverlay() {
      this.colorShow = false;
    },
    closeFn() {
      if (!this.colorTheme) return;
      if (!this.colse) {
        this.colse = true;
        return;
      }
      this.$store.commit("common/changeTheme", {
        theme: this.colorTheme,
        themeName: this.colorTheme,
      });
      localStorage.setItem("themeIndex", this.showIconIndex);
      this.colorShow = false;
    },
    isTheme(index, color) {
      if (!this.colse) {
        return;
      } else {
        this.showIconIndex = index;
        this.colorTheme = color;
      }
      this.clickoverlay();
    },
    //
    close() {
      this.colorShow = false;
      this.colse = false;
      this.showIconIndex = localStorage.getItem("themeIndex");
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  font-size: 16px;
  height: 54px;
  border-bottom: 1px solid #f1f1f1;
  text-align: center;
  line-height: 54px;
}
.colorList {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 56px;
    height: 56px;
    margin-left: 16px;
    margin-top: 16px;
    border-radius: 8px;
    text-align: center;
    line-height: 56px;
  }
}
.footer {
  margin-top: 20px;
  border-top: 1px solid #f1f1f1;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
}
</style>
