<template>
  <div class="player">
    <van-form ref="formData">
      <!--字段类型：1文本,2日期,3下拉选择,4姓名,5手机,6身份证,7生日,8性别,9证件照,10司机姓名,
        11司机车牌号,12地址,13邮箱,14微信openId,15证件类型,16国家,17台胞证,18港澳通行证 19护照
      -->
      <!-- 共用游客信息 -->
      <div v-if="productSetting && productSetting.shareTourist">
        <!--子产品 使用规则如果是一子产品一游客(注意维护时要同时维护一种产品一游客) combUseRule === 2-->
        <template v-if="item.combUseRule === 2">
          <!-- 子产品 -->
          <div
            v-for="(obj, inde) in item.subProductList"
            :key="obj.id"
            class="sub-product mt-1x"
          >
            <div class="p-1x">
              <div>
                <!-- v-if="obj.whetherSpecialDiscount" -->
                <span v-if="obj.whetherSpecialDiscount" class="hui">惠</span>
                <strong class="text-v">{{ obj.productName }}</strong>
              </div>
              <div class="flex-between ml-x">
                <div class="price">
                  <strong class="font-s">¥</strong>
                  <strong class="pt-1x">{{
                    obj.sellingAmount | money("")
                  }}</strong>
                </div>
                <strong>x{{ obj.buyNum }}</strong>
              </div>
            </div>
            <div class="p-1x player-index bold">游客</div>
            <base-player
              :ref="'basePlayer' + inde"
              :item="obj"
              :wrapItem="item"
              :cartId="cartId"
              :isSubProduct="true"
              :params="params"
              @update-add-buy="changeAddBuy"
              @update-add-buy-batch="changeAddBuyBatch"
              @update-player="updatePlayer"
              @update-fast="updateFast"
              @update-date="updateDate"
              @del-item="delItem"
            />
          </div>
        </template>
        <!--和票务一样 使用规则一种产品一游客(注意维护时要同时维护一子产品一游客)-->
        <template v-else>
          <base-player
            :ref="'basePlayer'"
            :item="item"
            :wrapItem="item"
            :cartId="cartId"
            :params="params"
            @update-add-buy="changeAddBuy"
            @update-add-buy-batch="changeAddBuyBatch"
            @update-player="updatePlayer"
            @update-fast="updateFast"
            @update-date="updateDate"
            @del-item="delItem"
          />
        </template>
      </div>
      <!-- 不共用游客 -->
      <div v-else>
        <div
          v-for="(item, index) in formData.cartList"
          :key="item.id"
          :class="{ 'flex-center': item.combUseRule === 2 }"
        >
          <!--子产品 使用规则如果是一子产品一游客(注意维护时要同时维护一种产品一游客) combUseRule === 2-->
          <template v-if="item.combUseRule === 2">
            <!-- 子产品 -->
            <div
              v-for="(obj, inde) in item.subProductList"
              :key="obj.id"
              class="sub-product mt-1x"
            >
              <div class="p-1x">
                <div>
                  <!-- v-if="obj.whetherSpecialDiscount" -->
                  <span v-if="obj.whetherSpecialDiscount" class="hui">惠</span>
                  <strong class="text-v">{{ obj.productName }}</strong>
                </div>
                <div class="flex-between ml-x">
                  <div class="price">
                    <strong class="font-s">¥</strong>
                    <strong class="pt-1x">{{
                      obj.sellingAmount | money("")
                    }}</strong>
                  </div>
                  <strong>x{{ obj.buyNum }}</strong>
                </div>
              </div>
              <div class="p-1x player-index bold">游客</div>
              <basePlayer
                :ref="'basePlayer' + inde"
                :item="obj"
                :wrapItem="item"
                :cartId="cartId"
                :isSubProduct="true"
                :params="params"
                @update-add-buy="changeAddBuy"
                @update-add-buy-batch="changeAddBuyBatch"
                @update-player="updatePlayer"
                @update-fast="updateFast"
                @update-date="updateDate"
                @del-item="delItem"
              />
            </div>
          </template>
          <!--和票务一样 使用规则一种产品一游客(注意维护时要同时维护一子产品一游客)-->
          <template v-else>
            <div
              class="p-1x pl-2x player-index flex-between"
              v-if="
                (appointmentSelection != 2 &&
                  item.timeShareAppointmentRuleId) ||
                item.fast === 1 ||
                item.whetherRecommend ||
                (item.visitorFieldList && item.visitorFieldList.length)
              "
            >
              <span class="bold">游客{{ item.index + 1 }}</span>
              <img
                class="ximg mr-x"
                src="../../../assets/images/x@2x.png"
                @click="delItem(item, 0)"
              />
            </div>
            <basePlayer
              :ref="'basePlayer' + index"
              :item="item"
              :wrapItem="item"
              :cartId="cartId"
              :params="params"
              @update-add-buy="changeAddBuy"
              @update-add-buy-batch="changeAddBuyBatch"
              @update-player="updatePlayer"
              @update-fast="updateFast"
              @update-date="updateDate"
              @del-item="delItem"
            />
          </template>
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Popup, Picker, Uploader } from "vant";
import {
  getTicketAddBuy,
  selectDiscountSku,
  ticketAppointmentDate,
} from "@/api/cart";
import { phoneReg, isIdcard, integerReg } from "@/utils/global";
import { appointmentTimeRuleStock } from "@/api/product";
import { faceAnalyzeUpload, face } from "@/api/iot";
import { fileUploadBase64 } from "@/api/upload";
// import PictureInput from "./PictureInput.vue";
import BasePlayer from "./base-player.vue";
export default {
  name: "ProductPlayer",
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    // PictureInput,
    BasePlayer,
  },

  props: {
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "", // 分类id
        };
      },
    },
    // 分时预约选择 1.产品  2.子景区 3.景区
    appointment: {
      type: Number,
      default: 0,
    },
    // 时段列表
    times: {
      type: Array,
      default() {
        return [];
      },
    },
    cart: {
      type: Object,
      default() {
        return {};
      },
    },
    // 产品设置
    productSetting: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // checkEmail,
      aurl: [],
      showPicker: false,
      humanFace: [],
      cartId: 0,
      formData: {
        cartList: [],
      },
      // 加购
      visible: false,
      tempRow: {}, // 临时行数据
      addBuyList: [], // 加购产品列表
      showDiscountSku: false, // 惠民列表弹框
      discountSku: {}, // 惠民列表选中值
      discountSkus: [], //特惠产品列表
      changePlayerItem: {}, // 修改游客信息item纪录
      item: {}, //共用游客信息

      voucherIndex: 0, //凭证index
      appointmentSelection: "", //分时预约选择 1.产品  2.子景区 3.景区
    };
  },
  created() {
    this.appointmentSelection = localStorage.getItem("appointmentSelection");
  },
  methods: {
    updateDate(date, time) {
      this.$emit("update-date", date, time);
    },
    updateMoreList(data) {
      this.$children[0].updateMoreList(data);
    },
    updatePlayer(obj) {
      this.$emit("update-player", obj);
    },
    updateFast() {},
    addMore(productId, dailyCalendar) {
      this.$router.push({
        path: "/paymore",
        query: {
          productId,
          dailyCalendar,
        },
      });
    },
    // 选择凭证
    checkVoucherField(item, voucherList, val) {
      this.voucherIndex = voucherList.findIndex(
        (ite) => ite.voucherType == val.voucherType
      );
      item.voucherType = voucherList[this.voucherIndex].voucherType;
      this.showPicker = false;
    },
    checkIdCard(val) {
      return isIdcard(val) === "";
    },
    checkPhone(val) {
      return phoneReg.test(val);
    },
    // 拍照 -待硬件
    afterRead(file, item) {
      console.log(item, file);
      // item.fieldValue = [
      //   "https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16507/1650787262872.png",
      // ];
      //证件照
      // fileUploadBase64({ data: file.content, ext: "png" })
      //   .then((value) => {
      //     console.log(value);
      //     // item.tempImg = [value.src];
      //     // item.fieldValue = value.src;
      //     // val.voucherList.forEach((temp) => {
      //     //   if (temp.voucherType === 102 && val.voucherType === 102) {
      //     //     // 人脸本地显示图片
      //     //     val.personFaceUrl = value.src;
      //     //     // 人脸上传获取id
      //     //     this.getPicture(res.photo, val);
      //     //   }
      //     // });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.$toast.fail("图片上传接口错误");
      //   });
    },
    //凭证信息输入
    changeVoucher(item, name) {
      // 给相同的游客信息属性赋值
      if (item.fieldValue) {
        item.visitorFieldList.forEach((voc) => {
          if (voc.fieldType == item.voucherType) {
            voc.fieldValue = item.fieldValue;
          }
        });
      }
      this.changePlayer(item, name);
    },
    // 凭证信息输入身份证反向适配
    changeVoucherOrder(voucherList, temp) {
      for (let item of voucherList) {
        if (item.voucherType === temp.fieldType) {
          item.fieldValue = temp.fieldValue;
          break;
        }
      }
    },
    // 删除购物车数量
    delItem(item, val) {
      const obj = {
        productId: item.productId,
        index: item.index,
        detailBuyNum: val,
      };
      this.$emit("del-item", obj);
    },
    // 修改游客信息
    /**
     * @params item Object  主产品行
     * @params key String  校验的key
     * @params val Object  动态表单行
     * @params obj Object  子产品行
     * */
    changePlayer(item, key, val = {}, temp) {
      this.$refs.formData.validate(key).then((valid) => {
        if (!valid) {
          this.discountSku = {};
          this.changePlayerItem = item;
          // 如果是身份证情况下，进行惠民弹框
          if (
            (val.voucherType === 6 || val.fieldType === 6) &&
            val.fieldValue.trim()
          ) {
            let obj = {
              playDate: this.params.playDate, // 游玩日期
              idCard: val.fieldValue, // 身份证号
              timeIntervalId: this.params.timeId, // 时段id
              timeShareAppointmentRuleId: this.params.appointmentRules, // 分时预约id
              //   // 多规格 item.specCombination === 1, 否则单规格
              productId: item.productId, // 产品id
              spuId: item.spuId, // 产品spuld
              subScenicId: this.params.subScenicId, //子景区id 特惠产品要用到
            };
            selectDiscountSku(obj).then((res) => {
              if (res.selectProduct && res.selectProduct.length > 1) {
                // 惠民列表产品大于1，提供选择弹框
                //选择特惠产品
                this.discountSkus = res.selectProduct;
                this.discountSku = res.selectProduct[0];
                this.showDiscountSku = true;
              } else if (res.selectProduct && res.selectProduct.length === 1) {
                // 惠民列表为1不弹框选中
                this.discountSku = res.selectProduct[0];
                this.getTicketTraveller(item, temp);
              } else {
                // 是身份证情况下，没惠民政策
                this.getTicketTraveller(item, temp);
              }
            });
          } else {
            // 非填写身份证情况下
            this.getTicketTraveller(item, temp);
          }
        }
      });
    },
    // 人脸录入
    faceChange(item, res, obj) {
      obj.personFaceUrl = res;
      this.getTicketTraveller(item, obj);
    },
    // 更新游客信息
    getTicketTraveller(item, temp) {
      // 把凭证信息复制到更多游客信息
      let obj = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index,
        traveler: this.getTraveler(temp || item), // temp 为组合子产品行，item为主产品行
      };
      // 惠民政策
      if (this.discountSku.selectProductSkuId) {
        obj.discountProductId = this.discountSku.selectProductSkuId;
      }

      // 如果是组合产品 一子产品一游客时
      if (temp) {
        obj.subProductId = temp.productId;
        obj.subIndex = temp.index;
      }
      this.$emit("update-player", obj);
    },
    //确认选择特惠产品
    sureDiscountSku() {
      this.getTicketTraveller(this.changePlayerItem);
      this.showDiscountSku = false;
    },
    // 获取游客信息
    getTraveler(item) {
      const obj = {
        voucherType: item.voucherType, // 选中的凭证选项
        personId: item.personId,
        personFaceUrl: item.personFaceUrl,
        visitorFieldList: item.visitorFieldList.map((temp) => {
          return {
            id: temp.id,
            fieldType: temp.fieldType,
            fieldValue: temp.fieldValue,
          };
        }),
      };
      // 游客类型
      if (item.visitorTypeId) obj.visitorTypeId = item.visitorTypeId;
      // 添加卡腕带号
      if (item.voucherType === 100) {
        obj.voucherNo = item.voucherList.find(
          (temp) => temp.voucherType === 100
        ).fieldValue;
      }
      return obj;
    },
    // 快速通行
    fastChange(item, obj) {
      const data = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index,
      };
      // 一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
        data.whetherFastTicket = obj.whetherFastTicket ? 1 : 0;
      }
      // 一种产品一游客、、票务
      else {
        data.whetherFastTicket = item.whetherFastTicket ? 1 : 0;
      }
      this.$emit("update-fast", data);
    },
    // 选择加购
    selectAdd(row) {
      getTicketAddBuy({
        productId: row.productId,
        dailyCalendar: this.params.playDate,
      })
        .then((res) => {
          this.$refs.productAddBuy.loadData(res, row.subProductList);
          this.tempRow = row;
        })
        .catch(() => {});
    },
    // 选中加购
    checkAddBuy(temp, item) {
      const val = temp.productFlagPO.whetherCheck ? 0 : 1;
      this.updateAddBuy({
        productId: item.productId,
        index: item.index,
        addBuyProductId: temp.productId,
        whetherCheck: val,
      });
    },
    // 加购加
    plusAddBuy(temp, item) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      this.updateAddBuy({
        productId: item.productId,
        index: item.index,
        addBuyProductId: temp.productId,
        addBuyNum: temp.productFlagPO.buyNum + 1,
      });
    },
    // 加购减
    minusAddBuy(temp, item) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (temp.productFlagPO.buyNum > 0)
        this.updateAddBuy({
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: temp.productFlagPO.buyNum - 1,
        });
    },
    delAddBuy(temp, item) {
      this.updateAddBuy({
        productId: item.productId,
        index: item.index,
        addBuyProductId: temp.productId,
        addBuyNum: 0,
      });
    },
    // 更新加购产品
    addInputBlur(e, temp, item) {
      const val = e.target.value;
      if (integerReg.test(val)) {
        this.updateAddBuy({
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: val,
        });
      }
    },
    // 修改加购时段
    changeAddTime(temp, item) {
      temp.timeOpen = false;
      this.updateAddBuy({
        productId: item.productId,
        index: item.index,
        addBuyProductId: temp.productId,
        timeShareAppointmentRuleId: temp.timeShareAppointmentRuleId,
        timeIntervalId: temp.timeIntervalId,
      });
    },
    // 修改弹窗加购产品数量
    changeAddBuy(data) {
      this.updateAddBuy({
        productId: this.tempRow.productId,
        index: this.tempRow.index,
        ...data,
      });
    },
    // 更新加购
    updateAddBuy(data) {
      this.$emit("update-add-buy", data);
    },
    // 批量更新加购
    changeAddBuyBatch(data) {
      this.$emit("update-add-buy-batch", data);
    },
    // 获取时段
    timeFocus(item) {
      if (item.times.length === 0 && item.timeShareAppointmentRuleId) {
        // 根据规则id获取时段列表
        appointmentTimeRuleStock({
          ruleId: item.timeShareAppointmentRuleId,
          date: this.params.playDate,
        })
          .then((res) => {
            // 过滤出可用时段
            const times = res.filter(
              (temp) => temp.stock > 0 && temp.endTime > new Date().getTime()
            );
            item.times = times;
            item.timeOpen = !item.timeOpen;
          })
          .catch(() => {});
      } else {
        item.timeOpen = !item.timeOpen;
      }
    },
    // 主产品时段改变
    timeChange(item) {
      item.timeOpen = false;
      ticketAppointmentDate({
        cardId: this.cartId,
        timeIntervalId: item.timeIntervalId,
        productId: item.productId,
        index: item.index,
      })
        .then((res) => {
          this.updateCart(res);
        })
        .catch(() => {});
    },
    // 刷身份证自动匹配产品到购物车
    changeCard(info) {
      let fieldList = [
        {
          id: "1380460304389713921",
          fieldName: "身份证",
          fieldType: 6,
          fieldValue: info.idCard,
        },
        {
          id: "1380352491692818436",
          fieldName: "性别",
          fieldType: 8,
          fieldOptions: ["未知", "女", "男"],
          fieldValue: info.sex,
        },
        {
          id: "1380352491692818433",
          fieldName: "姓名",
          fieldType: 4,
          fieldValue: info.name,
        },
        {
          id: "1380352491692818435",
          fieldName: "生日",
          fieldType: 7,
          fieldValue: info.birth,
        },
        {
          id: "1380352491713789953",
          fieldName: "证件照",
          fieldType: 9,
          fieldValue: info.cardUrl,
        },
      ];

      let obj = {
        productId: info.sybSystemIdProductId, // 产品id
        playDate: this.params.playDate, // 游玩日期
        timeShareAppointmentRuleId: this.params.appointmentRules, // 分时预约id
        timeIntervalId: this.params.timeId, // 时段Id
        subScenicId: this.params.subScenicId, // 子景区id
        buyNum: 1,
        traveler: {
          personId: info.personId,
          personFaceUrl: info.personFaceUrl,
          visitorFieldList: fieldList,
        },
      };
      this.$emit("update-cart", obj);
    },
    // 购物车改动后更新
    updateCart({ detailProductList, id }) {
      this.cartId = id;
      // 处理购物车返回的数据
      const dealData = (item) => {
        // 分时预约按子景区走则使用头部筛选条件的，否则通过接口获取时段列表
        const arr = this.appointment === 2 ? this.times : [];
        // 过滤出可用时段
        const times = arr.filter((temp) => {
          return temp.remainingStock > 0 && temp.endTime > new Date().getTime();
        });
        const obj = {
          index: item.index, // 序号
          productId: item.productId,
          playDate: this.params.playDate,
          spuId: item.spuId,
          productName: item.productName, // 产品名称
          ownerMerchantName: item.ownerMerchantName, // 商户名称
          sellingAmount: item.sellingAmount / 100, // 价格
          buyNum: item.productFlagPO.buyNum, // 数量
          buyNumDiasabled: false, // 修改数量禁用
          voucherList: [], // 产品凭证
          visitorFieldList: item.traveler && item.traveler.visitorFieldList, // 游客信息
          memberTouristList: item.traveler && item.traveler.memberTouristList, // 游客类型列表
          visitorTypeId: item.traveler && item.traveler.visitorTypeId, // 选中的游客类型
          wristbandPrice: item.traveler && item.traveler.wristbandPrice, // 游客信息
          fast: item.traveler && item.traveler.fast, // 是否开启快票功能
          requiredPersonId: item.traveler && item.traveler.requiredPersonId, //是否需要人脸
          requiredPersonTrue: item.traveler && item.traveler.requiredPersonTrue, //人脸是否必填
          whetherFastTicket: item.traveler
            ? !!item.traveler.whetherFastTicket
            : false, // 是否打开了快票
          timeIntervalId: item.appointmentTimePO
            ? item.appointmentTimePO.timeIntervalId
            : "", // 选中的时段id
          playerType: "", // 游客类型
          numDisabled: false, // 修改数量禁用
          // 加购产品列表
          subProductList: item.subProductList.map((temp) => {
            // 设置时段选择
            this.$set(
              temp,
              "timeIntervalId",
              temp.appointmentTimePO
                ? temp.appointmentTimePO.timeIntervalId
                : ""
            );
            this.$set(temp, "times", times);
            this.$set(temp, "timeOpen", false);
            return temp;
          }),
          whetherRecommend: item.whetherRecommend, // 是否有推荐加购
          requiredVoucherNo: item.traveler && item.traveler.requiredVoucherNo, //是否需要卡腕带
          voucherNo: item.traveler && item.traveler.voucherNo, //卡腕带
          personFaceUrl: item.traveler && item.traveler.personFaceUrl, // 人脸头像
          personId: item.traveler && item.traveler.personId, // 人脸头像id
          times, // 分时预约按子景区走则使用头部筛选条件的，否则通过接口获取时段列表
          timeShareAppointmentRuleId: item.timeShareAppointmentRuleId, // 产品是否开启分时预约
          timeOpen: false,
          ...item,
        };
        // 产品凭证列表
        obj.voucherList = item.traveler && item.traveler.voucherList;
        if (obj.voucherList && obj.voucherList.length) {
          obj.voucherList.sort(function (a, b) {
            return a.voucherType - b.voucherType;
          });
          obj.voucherType = obj.voucherList[0].voucherType; // 凭证类型, 默认选中第一个
          obj.voucherList.forEach((temp) => {
            // 回显选中的凭证名称
            if (temp.voucherSelect === 1) {
              obj.voucherType = temp.voucherType;
            }

            this.$set(temp, "fieldValue", "");
            for (let val of obj.visitorFieldList) {
              if (val.fieldType === temp.voucherType) {
                temp.fieldValue = val.fieldValue;
              }
              // 回显卡腕带号到凭证信息
              if (temp.voucherType === 100) {
                temp.fieldValue = item.traveler.voucherNo;
              }
            }
          });
        }
        return obj;
      };
      this.formData.cartList = detailProductList.map((item) => {
        // 如果使用规则是一子产品一游客，则游客信息放在
        if (item.productFlagPO.combUseRule === 2) {
          return {
            productId: item.productId,
            index: item.index, // 序号
            productName: item.productName, // 组合产品名称
            sellingAmount: item.sellingAmount, // 组合产品价格
            combUseRule: 2, // 一子产品一游客
            timeShareAppointmentRuleId: item.timeShareAppointmentRuleId,
            // 子产品列表
            subProductList: item.subProductList.map((temp) => {
              return dealData(temp);
            }),
          };
        } else {
          return dealData(item);
        }
      });
      // let itemArr = this.formData.cartList;
      this.item = this.formData.cartList[0];
      // 主产品数量大于1个时 一子产品一游客 共用游客时子产品数量也加
      // if(itemArr.length>1){
      // itemArr.forEach(temp=>{
      //     temp.subProductList.forEach(it=>{
      //       if(it.productId ===)
      //     })
      // })
      // }
      // 更新弹窗加购产品数量
      if (this.$refs.productAddBuy && this.tempRow) {
        const row = this.formData.cartList.find(
          (item) => item.index === this.tempRow.index
        );
        if (row) this.$refs.productAddBuy.updateData(row.subProductList);
      }
    },
    // 人脸、证件识别涉及的方法区域
    readCard(res, val) {
      val.visitorFieldList.forEach((item) => {
        //填充姓名
        if (item.fieldType == 4) {
          item.fieldValue = res.name;
        } else if (item.fieldType == 7) {
          //生日,分组匹配出年月日，再转换成moment格式
          // let reg = /(\d+)-(\d+)-(\d+)/;
          // let target = res.birth.match(reg);
          // let birthday = moment()
          //   .year(target[1])
          //   .month(Number(target[2]) - 1)
          //   .date(target[3])
          //   .format("YYYY-MM-DD");
          item.fieldValue = res.birth;
        } else if (item.fieldType == 8) {
          //性别(2男，1女)
          if (res.sex === "男") {
            item.fieldValue = "男";
          } else {
            item.fieldValue = "女";
          }
        } else if (item.fieldType == 9) {
          //证件照
          fileUploadBase64({ data: res.photo, ext: "png" })
            .then((value) => {
              item.fieldValue = value.src;
              val.voucherList.forEach((temp) => {
                if (temp.voucherType === 102 && val.voucherType === 102) {
                  // 人脸本地显示图片
                  val.personFaceUrl = value.src;
                  // 人脸上传获取id
                  this.getPicture(res.photo, val);
                }
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("图片上传接口错误");
            });
        } else if (item.fieldType == 12) {
          // 地址
          item.fieldValue = res.address;
        }
      });

      // if (this.formItemData.requiredPersonId) this.getPicture(res.photo);
    },
    getPicture(data64, val) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      //this.$message.success({ content: this.imgbase64, duration: 10 });
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then((res) => {
          // this.$message.success(JSON.stringify(res));
          this.saveFace(res.src, val);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 保存人脸
    saveFace(url, val) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      face(postData)
        .then((res) => {
          val.personId = res.personId;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 校验购物车表单
    validate() {
      let ok = true;
      this.$refs.formData.validate((valid) => {
        if (!valid) {
          this.$toast.fail("请正确填写购物信息");
          ok = false;
        }
      });
      return ok;
    },
  },
};
</script>
<style scoped lang="less">
// 游客信息
.player {
  background-color: #f8f8f8;
  .van-cell {
    // background-color: #f8f8f8;
    border-bottom: 1px solid #e9e9e9;
  }
  .prefix-picker {
    width: 30%;
    &:before {
      content: "";
      width: 1px;
      height: 50%;
      position: absolute;
      top: 25%;
      right: 0;
      background: #e2e2e2;
    }
  }
  .prefix-content {
    width: 70%;
  }
}
.check-btn {
  color: #386cdf;
  border: 1px solid;
  border-radius: 13px;
}
.minus {
  width: 20px;
}
.input-number {
  margin: 0 4px;
  border: none;
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  outline-color: #dcdfe6;
  color: #25a2f2;
}
.price {
  color: #ff2d2d;
  font-size: 16px;
}
.lower-img {
  width: 7px;
  height: 11px;
  transform: rotate(90deg);
}
.player-index {
  color: #2a2a2a;
  background-color: white;
}
.sub-product {
  background: white;
  background-color: white;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  width: 94%;
  // margin: 0 auto;
}
.hui {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  background: #f32a2a;
  color: #fff;
}
.ximg {
  width: 24px;
  height: 18px;
}
</style>
