<template>
  <!-- 选择打印方式组件 -->
  <div class="flex-center mv-1x">
    <div class="wrap">
      <div class="flex-between container" @click="changePrintWay">
        <div class="bold font-l">打印方式</div>
        <div class="mr-1x">
          <template>
            <span :class="{ disabled: !cartId }">
              {{ currentTypeName }}
              <img
                class="img ml-1x"
                src="../../../assets/images/fanhui@2x.png"
                alt=""
              />
            </span>
          </template>
        </div>
      </div>
      <!-- 游客计时结束 -->
      <div class="flex-between p-1x pb-2x bt" v-if="showTimeEnd">
        <div class="bold font-l">计时结束</div>
        <div class="scan-box mr-1x ph-1x flex-between">
          <input
            type="text"
            v-model="searchKey"
            class="input"
            placeholder="请输入识别凭证"
            @keyup.enter="scanEnter"
          />
          <van-icon name="scan" @click="scan" size="19" :color="color" />
        </div>
      </div>
    </div>
    <!--打印方式弹窗-->
    <print-setting ref="printSetting"></print-setting>
    <!-- 计时结束弹窗 -->
    <timing-end
      ref="timingEnd"
      @close="close"
      :visible="showTimingEnd"
    ></timing-end>
  </div>
</template>

<script>
import { scanQRCode } from "../../../utils/hardware";
import timingEnd from "./timing-end.vue";
import PrintSetting from "../ticket/components/PrintSetting";
export default {
  name: "PrintWay",

  props: {
    cartId: {
      type: Number,
      default: 0,
    },
    productId: {
      type: String,
      default: "",
    },
    productName: {
      type: String,
      default: "",
    },
    printNum: {
      type: Number,
      default: 0,
    },
    // 显示计时结束
    showTimeEnd: {
      type: Boolean,
      default: false,
    },
  },

  components: { timingEnd, PrintSetting },

  data() {
    return {
      // types: [
      //   {
      //     name: "一个产品一票",
      //     value: 1,
      //   },
      //   {
      //     name: "一种产品一票",
      //     value: 2,
      //   },
      //   {
      //     name: "自定义规则",
      //     value: 3,
      //   },
      // ],

      types: {
        1: "一产品一票",
        2: "一种产品一票",
        3: "自定义规则",
        4: "一子产品一票",
      },
      searchKey: "", // 识别凭证
      showTimingEnd: false, // 显示计时结束弹窗
    };
  },
  computed: {
    // 默认打印方式 || 当前业态打印方式
    defaultPrintType() {
      return this.$store.getters["common/defaultPrintType"];
    },
    // 是否自定义打印规则
    customPrintType() {
      return this.$store.getters["common/customPrintType"];
    },
    // 自定义打印规则或默认打印规则， 下完一单需清空自定义打印规则
    currentTypeName() {
      return this.types[this.customPrintType || this.defaultPrintType];
    },
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  created() {},

  beforeDestroy() {
    this.$store.commit("common/customPrintType", null);
  },

  methods: {
    changePrintWay() {
      if (this.cartId) {
        this.$refs.printSetting.initData(this.cartId);
      } else {
        this.$toast("请选择产品");
      }
    },
    // 扫识别凭证
    // 扫码
    scan() {
      scanQRCode(this.scanOk);
    },
    // 扫码成功返回
    scanOk(code) {
      this.searchKey = code;
      this.scanEnter();
    },
    // 扫码确认
    scanEnter() {
      if (this.searchKey) {
        this.showTimingEnd = true;
        this.$refs.timingEnd.searchKey = this.searchKey;
        this.$refs.timingEnd.searchData();
      }
    },
    close() {
      this.showTimingEnd = false;
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  width: 96%;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 2px rgba(75, 75, 75, 0.1);
  .img {
    width: 7px;
    height: 11px;
  }
  .bt {
    border-top: 1px solid #f1f1f1;
    .scan-box {
      line-height: 34px;
      border-radius: 6px;
      width: 75%;
      border: 1px solid #e2e2e2;
      .input {
        border: none;
      }
    }
  }
}
</style>
