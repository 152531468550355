<template>
  <div>
    <!-- 热销产品分成三张业态 -->
    <!-- 全选按钮 -->
    <van-checkbox-group
      v-model="all"
      ref="checkboxGroup"
      class="all pl-2x pt-2x"
    >
      <van-checkbox shape="square" name="all" @click="checkAll"
        >全选</van-checkbox
      >
    </van-checkbox-group>
    <!-- 热销产品列表    -->
    <van-checkbox-group v-model="result" ref="checkboxGroup" @change="change">
      <van-checkbox
        v-for="item in hotProductList"
        :key="item.id"
        class="checkboxlist pl-2x pt-2x"
        shape="square"
        :name="item.id"
      >
        {{ item.name }}
        <p>
          ￥ <span>{{ item.sellingPrice / 100 }}</span>
        </p>
      </van-checkbox>
    </van-checkbox-group>
    <!-- 底部按钮 -->
    <div class="footer">
      <button
        @click="hotProductListSave"
        :style="{
          'background-color': $store.getters['common/color'].primary,
        }"
      >
        确定
      </button>
    </div>
  </div>
</template>

<script>
import { productFrontHotproduct } from "@/api/search";
import { hotProduct } from "../../../api/product";
export default {
  name: "HotProductList",
  created() {
    productFrontHotproduct().then((res) => {
      this.hotProductList = res;
    });
  },
  data() {
    return {
      result: [],
      all: [],
      hotProductList: [],
      productList: [],
    };
  },
  methods: {
    hotProductListSave() {
      if (this.productList.length == 0) return;
      hotProduct({ productIdList: this.productList }).then((res) => {
        console.log(res);
      });
    },
    change(event) {
      console.log(event);
      this.productList = event;
    },
    // 全选按钮控制
    checkAll() {
      if (this.all.length) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll(false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f1f1f1;
  /deep/ .van-checkbox__icon .van-icon {
    border-radius: 4px;
  }
  /deep/.van-checkbox__icon {
    margin-right: 10px;
  }
}
.checkboxlist {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #f1f1f1;
  /deep/ .van-checkbox__icon .van-icon {
    border-radius: 4px;
  }
  /deep/.van-checkbox__icon {
    margin-right: 10px;
  }
  p {
    font-size: 12px;
    color: #ff2d2d;
  }
  span {
    font-size: 18px;
    color: #ff2d2d;
  }
}
.footer {
  width: 100%;
  height: 68px;
  position: fixed;
  bottom: 0;
  text-align: center;
  background-color: #fff;
  button {
    width: 300px;
    height: 45px;
    border-radius: 25px;
    border: none;
    color: #fff;
  }
}
</style>
