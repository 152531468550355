<template>
  <div>
    <!-- 商户汇总详情组件 -->
    <div class="header-text pl-2x">
      <p class="mt-2x">员工：{{ user.userName }}</p>
      <div class="mv-1x">
        上班时间：{{ user.clockIn | date("yyyy-mm-dd hh:mm") }}
      </div>
      <div>交账时间：{{ user.clockOff | date("yyyy-mm-dd hh:mm") }}</div>
    </div>
    <!-- 商户汇总列表 start-->
    <div class="main-text font-s ph-2x mt-1x">
      <div class="flex-between pv-1x">
        <span>销售数量</span>
        <span>{{ money.buyNumSum }}个</span>
      </div>
      <div class="flex-between pv-1x">
        <span>收款金额</span>
        <span>￥{{ money.totalActualAmount / 100 || "0" }}</span>
      </div>
      <div class="flex-between pv-1x">
        <span>退票数量</span>
        <span>{{ money.refundNumSum }}个</span>
      </div>
      <div class="flex-between pv-1x">
        <span>退款金额</span>
        <span>￥{{ money.totalRefundAmount / 100 || "0" }}</span>
      </div>
      <div class="flex-between pv-1x">
        <span>实收票数</span>
        <span>{{ money.paidInNumSum }}个</span>
      </div>
      <div class="flex-between pv-1x">
        <span>实收小计</span>
        <span>￥{{ money.totalPaidInAmount / 100 || "0" }}</span>
      </div>
      <div class="flex-between pv-1x">
        <span>合计</span>
        <span>￥{{ money.totalPaidInAmount / 100 || "0" }}</span>
      </div>
    </div>
    <!-- 商户汇总列表 底部详情 -->
    <div class="footer-text font-s ph-2x mt-1x">
      <div
        class="flex-between pv-1x"
        v-for="(item, index) in summarylist"
        :key="index"
      >
        <span>{{ item.merchantName }}</span>
        <span :style="{ color: color }" @click="goDetails(item, index)"
          >详情</span
        >
      </div>
    </div>
    <!-- 商户汇总列表 end -->
  </div>
</template>

<script>
import { orderAccountMerchantssummary } from "../../../api/search";
import { orderHandoverMainInfo } from "@/api/order";
export default {
  name: "PaymentList",
  props: {
    id: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  data() {
    return {
      summarylist: [], // 商户汇总列表
      money: {}, // 商户汇总 数据
      productTotal: {}, // 产品汇总列表
      productData: [], // 产品汇总 数据
    };
  },
  methods: {
    getSummary(res) {
      // 从交账记录页面跳转过来的交账汇总
      if (this.id) {
        // 商户总汇
        orderHandoverMainInfo({ id: this.id }).then((res) => {
          let list = res[0];
          this.money.buyNumSum = list.totalSaleNum; // 总销售量
          this.money.refundNumSum = list.refundNum; // 退款
          this.money.paidInNumSum = list.actualSaleNum; // 实收票数
          this.money.totalActualAmount = list.actualSaleAmount; //累计实收小计（单位：分）
          this.money.totalPaidInAmount = list.totalSaleAmount; //累计收款金额（单位：分）
          this.money.totalRefundAmount = list.refundAmount; //累计退款金额（单位：分）
          res.pop();
          this.summarylist = res;
        });
        return;
      }
      // 获取总汇数据
      // 交账商户汇总
      orderAccountMerchantssummary({
        accountStartTime: res.clockIn,
        accountEndTime: res.clockOff,
      }).then((res) => {
        this.summarylist = res.records; // 汇总列表
        let buyNumSum = 0;
        let refundNumSum = 0;
        let paidInNumSum = 0;
        this.summarylist.forEach((item) => {
          buyNumSum += item.buyNumSum;
          refundNumSum += item.refundNumSum;
          paidInNumSum += item.paidInNumSum;
        });
        this.money.buyNumSum = buyNumSum; // 总销售量
        this.money.refundNumSum = refundNumSum; // 退款
        this.money.paidInNumSum = paidInNumSum; // 实收票数
        this.money.totalActualAmount = res.totalActualAmount; //累计实收小计（单位：分）
        this.money.totalPaidInAmount = res.totalPaidInAmount; //累计收款金额（单位：分）
        this.money.totalRefundAmount = res.totalRefundAmount; //累计退款金额（单位：分）
      });
    },
    goDetails(item) {
      let res;
      let type = "ZH"; // 默认是总汇页面
      if (this.id) {
        type = "JL"; // 从交账记录跳转的标识
        res = encodeURI(JSON.stringify(item));
      } else {
        type = "ZH"; // 默认是总汇页面
        res = encodeURI(JSON.stringify(item));
      }
      this.$router.push({
        path: "/me/auditAndPayAccountsDetails",
        query: {
          item: res,
          title: "详情",
          type,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header-text {
  height: 110px;
  width: 100%;
  border-top: 1px solid #f3f3f3;
  background-color: #fff;
}
.main-text {
  width: 100%;
  background-color: #fff;
}
.footer-text {
  width: 100%;
  background-color: #fff;
}
</style>
