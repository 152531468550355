<template>
  <div class="root">
    <!-- 查账交账 -->
    <van-nav-bar title="查账交账" placeholder />
    <van-tabs :color="color" :title-active-color="color">
      <van-tab title="商户汇总">
        <McPaymentList ref="mc" :user="userInfos" :id="id" />
      </van-tab>
      <van-tab title="产品汇总">
        <PdPaymentList ref="pd" :user="userInfos" :id="id" />
      </van-tab>
    </van-tabs>

    <div class="footer pr-2x">
      <span>打印销售汇总</span>
      <van-switch
        class="mr-2x ml-1x"
        v-model="checked"
        :active-color="color"
        inactive-color="#dcdee0"
      />
      <van-button @click="subimt" round type="info" :color="color"
        >确认交账</van-button
      >
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "../../api/order";
import { orderAccountConfirmsummary } from "../../api/search";
import { printDailyReport } from "../../utils/hardware";
import McPaymentList from "./components/McPaymentList.vue";
import PdPaymentList from "./components/PdPaymentList.vue";
export default {
  name: "AuditAndPayAccounts",
  components: {
    McPaymentList,
    PdPaymentList,
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      this.id = id;
    }
    this.getHandOverUserInfo();
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  data() {
    return {
      id: "",
      checked: true,
      printTemplateId: "", // 打印模块id
      //员工信息
      userInfos: {
        clockIn: "", //上班时间
        clockOff: "", //交账时间
        phone: "", //手机号
        userName: "", //员工名字
      },
    };
  },
  methods: {
    subimt() {
      // const hardware = localStorage.getItem("Hardware");
      // //拿到最新的打印模板id
      // this.printTemplateId =
      //   hardware && JSON.parse(hardware).accountforTemplate;
      // if (this.checked && this.printTemplateId === null) {
      //   this.$toast("请在我的页面->>打印方式设置-->> 设置打印模板", 2);
      //   return false;
      // }
      orderAccountConfirmsummary({
        accountStartTime: this.userInfos.clockIn,
        accountEndTime: this.userInfos.clockOff,
        print: this.checked ? 1 : "0",
      }).then((res) => {
        if (this.checked) {
          // 获取打印信息
          let str = JSON.stringify(res);
          printDailyReport(str);
        }
        this.$dialog
          .alert({
            title: "提示",
            message: "交账成功, 确定后将退出登录，祝您工作愉快！",
          })
          .then(() => {
            localStorage.removeItem("token");
            setTimeout(() => {
              this.$router.push("/login");
            }, 500);
          });
      });
    },
    // 获取交账的用户信息
    getHandOverUserInfo() {
      getUserInfo().then((res) => {
        this.userInfos = res;
        this.$refs.mc.getSummary(res);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.root {
  padding-bottom: 55px;
  .footer {
    height: 55px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
