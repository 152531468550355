<!-- 价格明细 -->
<template>
  <div class="flex-center mt-1x">
    <div class="wrap">
      <div class="bd-b bold font-l p-1x">价格明细</div>
      <div class="content p-1x">
        <div class="content-item flex-between">
          <p>产品数量</p>
          <span>{{ cart.productNum }}</span>
        </div>
        <div class="content-item flex-between">
          <p>游客数量</p>
          <span>{{ cart.travelerNum }}</span>
        </div>
        <div class="content-item flex-between">
          <p>订单金额</p>
          <span class="price">{{ cart.totalPrice | money }}</span>
        </div>
        <div
          class="content-item flex-between"
          v-if="cart.preferentialInfo"
          @click="checkCode"
        >
          <p>优惠券</p>
          <div @click="checkCode">
            <span class="price">{{
              cart.preferentialInfo.merchantDiscountAmount | money
            }}</span>
            <img
              class="right-img ml-1x"
              src="../../../assets/images/fanhui@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="content-item flex-between" v-if="cart.preferentialInfo">
          <p>积分抵扣</p>
          <span class="price">{{
            cart.preferentialInfo.integralPayPrice | money
          }}</span>
        </div>
        <div class="content-item flex-between font-l bold">
          <p>应收金额</p>
          <span class="price">{{ cart.settlementPrice | money }}</span>
        </div>
      </div>
    </div>
    <van-popup bottom v-model="showPicker" position="bottom">
      <div class="flex-center bd-b p-2x">
        <strong class="font-xl">优惠券</strong
        ><van-icon
          class="cancel font-xl"
          name="cross"
          @click="showPicker = false"
        />
      </div>
      <div>
        <div
          v-for="item in list"
          :key="item.key"
          class="flex-between align-center p-2x bd-b"
        >
          <div class="product-setting-name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div
        class="flex-center p-2x"
        :style="{ color: color }"
        @click="showPicker = false"
      >
        确定
      </div>
    </van-popup>
  </div>
</template>
<script>
import { windowCouponActivityList } from "@/api/marketing";
export default {
  name: "price-total",
  data() {
    return {
      cart: {},
      member: {},
      showPicker: false,
      list: [],
      code: "", //优惠券码
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    updateCart(cart) {
      this.cart = cart;
    },
    // 通过优惠券码/会员id，查询优惠券
    checkCode() {
      if (!this.member.id) {
        this.$toast.fail("请先输入会员或券码");
        return;
      }
      let list = this.cart.productList.map((item) => {
        return {
          productId: item.productId,
          salePrice: item.sellingAmount,
          count: item.productFlagPO.buyNum,
          merchantId: item.merchantSubScenicId,
        };
      });
      windowCouponActivityList({
        couponCode: this.code,
        memberId: this.member.id,
        orderChannelId: 6, //6是窗口
        productItems: list,
      })
        .then((res) => {
          if (res) {
            this.showPicker = true;
            this.list = res;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="less">
.wrap {
  width: 96%;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 2px rgba(75, 75, 75, 0.1);
  .content {
    padding: 0.2rem;
    .content-item {
      line-height: 2.2;
    }
  }
}
.price {
  color: #ff2d2d;
}
.right-img {
  width: 7px;
  height: 11px;
}
</style>
