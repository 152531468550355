<!--业态组件-->
<template>
  <div class="wrap ph-1x">
    <!-- 业态 -->
    <div class="flex-around format-box pt-2x pb-2x">
      <div
        v-for="item in filterFormat"
        :key="item.id"
        :class="{ currentFormat: item.id == currentFormat }"
        :style="{
          color: item.id === currentFormat ? color : '',
        }"
        class="flex-center flex-col pv-1x ph-2x"
        @click="changeFormat(item)"
      >
        <img :src="item.img" alt="" class="img" />
        <div>
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 日历 -->
    <div class="flex-between date-box pb-2x" v-if="isShowTime && dates.length">
      <div class="scroll-view">
        <div class="flex-around date ml-x">
          <div
            class="date-item ph-1x"
            :class="{ active: index === active }"
            :style="{
              color: index === active ? color : '',
            }"
            v-for="(item, index) in dates"
            :key="index"
            @click="clickDate(item, index)"
            :id="'tab' + index"
          >
            <!-- <div v-if="dateRange == 2">
              <div class="font-l bold">
                {{ item | date("MM-DD") }}
              </div>
              <div class="font-n">
                {{ item | date("MM-DD") }}
              </div>
            </div> -->
            <div>
              <div class="font-l bold">
                {{ item.week }}
              </div>
              <div class="font-n">
                {{
                  nowDay == item.date
                    ? "今天"
                    : nowDay + 1 == item.date
                    ? "明天"
                    : `${currentMonth}/${item.date}`
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="calender flex-center flex-shrink"
        :class="{ active: active === '' }"
        @click="openCalendar"
      >
        <div
          :style="{
            color: active === '' ? color : '',
          }"
        >
          <img
            class="image"
            :style="{
              color: active === '' ? color : '',
            }"
            src="../../../assets/images/format/rili@2x.png"
            alt=""
          />
          <span class="c-time">{{ currentTime }}</span>
        </div>
      </div>
      <van-calendar
        v-model="showCalendar"
        @confirm="dateChange"
        :default-date="minDate"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </div>
    <!-- 时段库存 -->
    <div
      class="flex-start align-center time-box pb-2x"
      v-if="isShowTime && dates.length"
    >
      <ul class="flex-start flex-wrap">
        <li
          class="time-item flex-center ph-1x ml-x"
          v-for="item in times"
          :class="{
            active: item.id === timeId,
            disabled: item.remainingStock <= 0,
          }"
          :style="{
            color: item.id === timeId ? color : '',
          }"
          :key="item.id"
          @click="checkTime(item)"
        >
          <div>
            <b>
              {{ item.beginTime | date("hh:mm") }}
              -
              {{ item.endTime | date("hh:mm") }}</b
            >
            <p>库存：{{ item.remainingStock }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!--门票类型-->
    <div class="ticket pb-2x">
      <div class="ticket-type flex-start">
        <div
          class="ticket-type-item ph-2x mh-x"
          :class="{ active: classId == '' }"
          :style="{
            color: classId == '' ? color : '',
          }"
          @click="checkClass('')"
        >
          全部
        </div>
        <div
          class="ticket-type-item ph-2x mh-x"
          :class="{ active: classId === item.id }"
          :style="{
            color: classId === item.id ? color : '',
          }"
          v-for="item in productClass"
          :key="item.id"
          @click="checkClass(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appointmentStockDate } from "@/api/product";
import { productFrontAggregatecategory } from "@/api/search";
import { merchantSettingOrderInfo } from "../../../api/merchant";
import moment from "moment";
import { Calendar } from "vant";
export default {
  name: "index-format",
  components: { [Calendar.name]: Calendar },
  data() {
    return {
      formats: [
        {
          id: "6",
          img: require("../../../assets/images/format/piaowu@2x.png"),
          name: "票务",
        },
        {
          id: "7",
          img: require("../../../assets/images/format/youle@2x.png"),
          name: "游乐",
        },
        {
          id: "13",
          img: require("../../../assets/images/format/zuhe@2x.png"),
          name: "组合",
        },
        {
          id: "11",
          img: require("../../../assets/images/format/lingshou@2x.png"),
          name: "零售",
        },
        {
          id: "8",
          img: require("../../../assets/images/format/juyuan@2x.png"),
          name: "剧院",
        },
      ], // 业态：1会员，2票务，3游乐，4剧院，5酒店，6餐饮，7零售，8租赁，9组合
      subScenicId: "",
      // 日期
      dateRange: "",
      fullDate: "",
      minDate: "", // 最小可选日期
      maxDate: "", // 最大可选日期
      defaultDate: "", // 默认选中日期
      active: 0, // 激活的日历
      dates: [], // 日期列表
      activeTime: "", // 激活的日期
      intoTab: "",
      currentMonth: "", // 当前月份
      currentTime: "", //当前选择日期
      nowDay: new Date().getDate(),
      showCalendar: false, // 日历
      defaultData: "",

      // 时段
      times: [], // 时段列表
      timeId: "", // 选中的日期
      isShowTime: true, // 是否显示时间段

      // 产品分类
      classId: "",
      productClass: [],
      setting: {},
      appointmentRules: "", // 分时预约规则id
      appointmentSelection: 1, // 分时预约设置 1 产品维度 2 子景区, 3景区
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
    formatTypeList() {
      return this.$store.state.common.formatTypeList;
    },
    // 是否超管
    isSupperManager() {
      return this.$store.getters["common/isSupperManager"];
    },
    // 角色拥有的业态 为配置权限暂时写死
    filterFormat() {
      return this.formats.filter((item) => item.id === "6" || item.id === "13");
    },
    // 当前选中业态
    currentFormat() {
      return this.$store.getters["common/currentFormat"];
    },
  },
  created() {
    this.loadProductClass();
    this.minDate = new Date();
    let newYear = new Date().getFullYear();
    this.maxDate = new Date(new Date().setFullYear(newYear + 3)); //先默认最大可选日期为往后3年
  },
  methods: {
    moment,
    // 筛选条件改变
    change() {
      this.$emit("change", {
        subScenicId: this.subScenicId,
        playDate: this.datesId,
        appointmentRules: this.appointmentRules,
        timeId: this.timeId,
        name: this.searchKey,
        categoryId: this.classId,
      });
    },
    // 切换子景区
    async changeScenic(subScenicId) {
      this.subScenicId = subScenicId;
      // 获取景区设置
      // 业态currentFormat：// 业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      const setting = await merchantSettingOrderInfo({
        formatType: this.currentFormat,
      });
      this.setting = setting;
      // 处理可选日期
      this.loadDate(setting);
      localStorage.setItem("orderRules", JSON.stringify(setting.orderRules[0]));
      // appointmentSelection 分时预约选择 1.产品  2.子景区 3.景区
      this.appointmentSelection = setting.appointmentSelection;
      localStorage.setItem(
        "appointmentSelection",
        setting.appointmentSelection
      );
      this.isShowTime = setting.appointmentSelection !== 1;

      this.$emit("appointment", setting.appointmentSelection);
      if (setting.appointmentSelection !== 1) {
        // 景区维度 可以从配置获取到分时预约规则id
        if (setting.appointmentSelection === 3) {
          this.appointmentRules =
            setting.scenicDpotReservation[0].appointmentRules;
        }
        // 获取时段列表
        this.getAppointmentStockDate();
      } else {
        this.datesId = "";
        this.change();
      }
    },
    // 选择时段
    checkTime(item) {
      if (item.remainingStock <= 0) return;
      this.timeId = item.id;
      this.change();
    },
    // 加载日期
    loadDate({ correspondOrderRules }) {
      this.active = 0;
      if (correspondOrderRules && correspondOrderRules.length) {
        const obj = correspondOrderRules[0];
        //下单规则 1不限 2限制 3 T后不限
        if (obj.orderDateRange == 1) {
          this.dateRange = 1;
          this.mintDate = new Date();
          this.datesId = moment().valueOf();
          this.getDate(new Date());
        } else if (obj.orderDateRange == 2) {
          this.dateRange = 2;
          // 计算可选日期范围
          let t = Number(obj.ttoDay);
          let dates = [];
          const weeks = {
            0: "周日",
            1: "周一",
            2: "周二",
            3: "周三",
            4: "周四",
            5: "周五",
            6: "周六",
          };
          for (let i = 0; i <= t; i++) {
            let day = moment()
              .add(+Number(i + obj.tafterDay), "day")
              .valueOf();
            if (i == 0) {
              this.datesId = day;
            }
            let date = new Date(day);
            dates.push({
              week: weeks[date.getDay()],
              date: this.two(date.getDate()),
              time: date.getTime(),
            });
          }
          this.minDate = new Date(dates[0].time);
          this.maxDate = new Date(dates[dates.length - 1].time);
          this.dates = dates;
        } else if (obj.orderDateRange == 3) {
          this.dateRange = 3;
          this.defaultData = moment()
            .add(+Number(obj.tafterDay), "day")
            .valueOf();
          this.date = moment()
            .add(+Number(obj.tafterDay), "day")
            .format("YYYY-MM-DD");
          this.minDate = new Date(this.date);
          this.datesId = this.defaultData;
          this.getDate(new Date(this.date));
        }
      } else {
        this.dateRange = 1;
        this.datesId = moment().valueOf();
        this.getDate(new Date());
        this.$notify({
          type: "warning",
          message:
            "该景区未配置下单规则，请到后台管理系统的“景区设置”配置或联系管理员！",
        });
      }
    },
    // 禁用日期
    disabledDate(day) {
      let current = new Date(day.date);
      let disabled =
        current &&
        current.getTime() <
          (this.defaultData == ""
            ? new Date(moment().add(-1, "day")).getTime()
            : this.defaultData);
      day.type = disabled ? "disabled" : "";
      return day;
    },
    // 获取时段
    async getAppointmentStockDate(changeDate) {
      const obj = {
        date: this.datesId,
      };
      // 子景区维度的分时预约需要根据子景区id获取时段
      if (this.appointmentSelection === 2) {
        obj.subScenicId = this.subScenicId;
      }
      // 景区维度则需要根据后台设置选中的规则id获取
      else {
        obj.ruleId = this.appointmentRules; // 规则id
      }
      const data = await appointmentStockDate(obj);
      this.times = data;
      this.$emit("appointment", this.appointmentSelection, data);
      // 子景区分时预约规则
      if (data.length) this.appointmentRules = data[0].ruleId;
      // 查找库存大于0的时段
      const temp = data.find((val) => val.remainingStock > 0);
      if (temp) this.timeId = temp.id;
      !changeDate && this.change();
      // this.change();
      this.timeIdTxt =
        moment(temp.beginTime).format("HH:mm") +
        "-" +
        moment(temp.endTime).format("HH:mm");
      return data;
    },
    // 选择分类
    checkClass(id) {
      this.classId = id;
      this.change();
    },
    // 加载产品分类
    loadProductClass() {
      productFrontAggregatecategory({
        formats: this.currentFormat,
      })
        .then((res) => {
          this.productClass = res;
          // if (res.length) this.classId = res[0].id;
        })
        .catch(() => {});
    },
    openCalendar() {
      this.showCalendar = true;
    },
    // 点击日期
    clickDate(item, index) {
      if (this.active === index) return;
      this.active = index;
      this.datesId = item.time;
      this.activeTime = item.time;
      // if (this.dateRange == 2) {
      //   this.datesId = item;
      //   this.activeTime = item;
      // }
      this.fullDate = item.fullDate;
      this.intoTab = "tab" + index;
      if (this.setting.appointmentSelection !== 1) {
        this.getAppointmentStockDate();
      }
    },
    // 日期选择确认
    dateChange(val) {
      this.datesId = val.valueOf();
      this.active = "";
      this.currentTime = moment(val).format("MM/DD");
      // this.$emit("updateCartDate", this.datesId);
      // 获取时段列表
      if (this.setting.appointmentSelection !== 1) {
        this.getAppointmentStockDate(true);
      }
      this.showCalendar = false;
    },
    //切换业态
    changeFormat(item) {
      this.$store.commit("common/currentFormat", item.id);
      this.$emit("changeFormat", item.id);
      this.loadProductClass();
      this.changeScenic(this.subScenicId);
    },
    headerChange() {},
    // 构造日期
    getDate(now) {
      const dates = [];
      const weeks = {
        0: "周日",
        1: "周一",
        2: "周二",
        3: "周三",
        4: "周四",
        5: "周五",
        6: "周六",
      };
      // 获取当前激活时间
      now.setHours(23, 59, 59, 999);
      const year = now.getFullYear();
      const month = this.two(now.getMonth() + 1);
      const day = this.two(now.getDate());
      const week = now.getDay();
      const date = this.two(now.getDate());
      const time = now.getTime();
      const fullDate = `${year}-${month}-${day}`;
      const current = {
        week: weeks[week],
        date,
        fullDate,
        time,
      };
      dates.unshift(current);
      this.currentMonth = month;
      this.fullDate = fullDate;
      // 循环获取前5天
      // for (let i = 1; i < 6; i++) {
      //   now.setDate(now.getDate() - 1);
      //   const year = now.getFullYear();
      //   const month = this.two(now.getMonth() + 1);
      //   const day = this.two(now.getDate());
      //   const week = now.getDay();
      //   const date = this.two(now.getDate());
      //   dates.unshift({
      //     week: weeks[week],
      //     date,
      //     time: now.getTime(),
      //     fullDate: `${year}-${month}-${day}`,
      //   });
      // }

      // now.setDate(now.getDate() + 5);
      // 循环获取后5天
      for (let i = 1; i < 5; i++) {
        now.setDate(now.getDate() + 1);
        const week = now.getDay();
        const date = this.two(now.getDate());
        const time = now.getTime();
        // 当前选中日期后5天要小于最大可选日期
        dates.push({
          week: weeks[week],
          date,
          time,
        });
      }

      this.active = dates.findIndex((item) => item.time === time);
      this.activeTime = dates[this.active].time;
      this.dates = dates;
    },
    two(val) {
      if (val < 10) {
        val = "0" + val;
      }
      return val;
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
}
/*业态*/
.format-box {
  .currentFormat {
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  .img {
    width: 30px;
    height: 30px;
  }
}
/*日历*/
.calender {
  position: relative;
  width: 48px;
  height: 48px;
  background: #fff;
  .txt {
    width: max-content;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 11px;
    font-size: 10px;
    color: #2c9e6c;
  }
  .image {
    width: 25px;
    height: 23px;
  }
  .c-time {
    position: absolute;
    bottom: -5px;
    left: 7px;
  }
}
/*选择日期*/
.date-box {
  background: #fff;
  .scroll-view {
    width: 85%;
    height: max-content;
    overflow-x: scroll;
  }
  /*日期*/
  .date {
    width: max-content;
    min-width: 100%;
    .date-item {
      // width: 50px;
      // height: 50px;
      color: #545454;
      text-align: center;
    }
    .active {
      .gray {
        color: #2c9e6c;
      }
      border-radius: 5px;
      background: #f5f5f5;
    }
  }
}
.time-box {
  overflow-x: scroll;
  .time-item {
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
}
/*门票类型*/
.ticket {
  overflow-x: scroll;
  .ticket-type {
    width: max-content;
    .ticket-type-item {
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      // color: #a59999;
    }
    .active {
      background: #f5f5f5;
    }
  }
}
.active {
  border-radius: 5px;
  background: #f5f5f5;
}
/* 隐藏滚动条 */
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
