<template>
  <div>
    <!-- 头部 navbar -->
    <van-nav-bar :title="title" placeholder />
    <!-- 交账查账汇总的详细信息 -->
    <div v-if="type == 'ZH'">
      <!-- 商户 详情信息 -->
      <div class="main-text font-s ph-2x mt-1x" v-if="title == '详情'">
        <div class="flex-between pv-1x">
          <span>业态</span>
          <span>{{ format(list.productForms) || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>子景区名称</span>
          <span>{{ list.subMerchantScenicName || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>商户名称</span>
          <span>{{ list.merchantName }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售数量</span>
          <span>{{ list.buyNumSum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>收款金额</span>
          <span>￥{{ list.actualAmountSum / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退票数量</span>
          <span>{{ list.refundNumSum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款金额</span>
          <span>￥{{ list.refundAmountSum / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>实收票数</span>
          <span>{{ list.paidInNumSum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>实收小计</span>
          <span>￥{{ list.paidInAmountSum / 100 }}</span>
        </div>
      </div>
      <!-- 产品详细信息 -->
      <div class="main-text font-s ph-2x mt-1x" v-else>
        <div class="flex-between pv-1x">
          <span>业态</span>
          <span>{{ format(list.productForms) || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>子景区名称</span>
          <span>{{ list.subMerchantScenicName || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品名称</span>
          <span>{{ list.productName || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品编号</span>
          <span>{{ list.productId }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品所属商户</span>
          <span>{{ list.productMerchants || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>业务类型</span>
          <span>{{ list.businessType }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品分类</span>
          <span>{{ list.productType }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售数量</span>
          <span>{{ list.salesNum }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款数量</span>
          <span>{{ list.refundNum }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>数量小计</span>
          <span>{{ list.actualNum }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售金额</span>
          <span>￥{{ list.actualAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款金额</span>
          <span>￥{{ list.refundAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>金额小计</span>
          <span>￥{{ list.paidInAmount / 100 }}</span>
        </div>
      </div>
      <!-- 支付方式 -->
      <div
        class="pay font-s mt-x ph-2x"
        v-for="temp in list.paymentWayVOList"
        :key="temp.payWayCode"
        v-show="title == '详情'"
      >
        <!-- 小标题 -->
        <div class="paytype bold pv-1x">{{ temp.payWayName }}</div>
        <div class="flex-between pv-1x">
          <span>收款金额</span>
          <span>￥{{ temp.actualAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售数量</span>
          <span>{{ temp.buyNum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款金额</span>
          <span>￥{{ temp.refundAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>金额小计</span>
          <span>￥{{ temp.paidInAmount / 100 }}</span>
        </div>
      </div>
    </div>
    <div v-else-if="type == 'JL'">
      <!-- 商户 从交账记录跳转过来 详情信息 -->
      <div class="main-text font-s ph-2x mt-1x" v-if="title == '详情'">
        <div class="flex-between pv-1x">
          <span>业态</span>
          <span>{{ list.orderFormatTypeText || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>子景区名称</span>
          <span>{{ list.subMerchantScenicName || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>商户名称</span>
          <span>{{ list.merchantName }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售数量</span>
          <span>{{ list.totalSaleNum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>收款金额</span>
          <span>￥{{ list.totalSaleAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退票数量</span>
          <span>{{ list.refundNum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款金额</span>
          <span>￥{{ list.refundAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>实收票数</span>
          <span>{{ list.actualSaleNum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>实收小计</span>
          <span>￥{{ list.actualSaleAmount / 100 }}</span>
        </div>
      </div>
      <!-- 产品详细信息 -->
      <div class="main-text font-s ph-2x mt-1x" v-else>
        <div class="flex-between pv-1x">
          <span>业态</span>
          <span>{{ list.orderFormatTypeText || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>子景区名称</span>
          <span>{{ list.subMerchantScenicName || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品名称</span>
          <span>{{ list.productName || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品编号</span>
          <span>{{ list.productId }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品所属商户</span>
          <span>{{ list.merchantNames || "-" }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>业务类型</span>
          <span>{{ list.orderBusinessName }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>产品分类</span>
          <span>{{ list.productTypeName }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售数量</span>
          <span>{{ list.saleNum }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款数量</span>
          <span>{{ list.refundNum }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>数量小计</span>
          <span>{{ list.actualSaleNum }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售金额</span>
          <span>￥{{ list.saleAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款金额</span>
          <span>￥{{ list.refundAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>金额小计</span>
          <span>￥{{ list.actualSaleAmount / 100 }}</span>
        </div>
      </div>
      <!-- 支付方式 -->
      <div
        class="pay font-s mt-x ph-2x"
        v-for="temp in list.paymentWayVOList"
        :key="temp.payWayCode"
        v-show="title == '详情'"
      >
        <!-- 小标题 -->
        <div class="paytype bold pv-1x">{{ temp.payWayName }}</div>
        <div class="flex-between pv-1x">
          <span>收款金额</span>
          <span>￥{{ temp.actualAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>销售数量</span>
          <span>{{ temp.buyNum }}个</span>
        </div>
        <div class="flex-between pv-1x">
          <span>退款金额</span>
          <span>￥{{ temp.refundAmount / 100 }}</span>
        </div>
        <div class="flex-between pv-1x">
          <span>金额小计</span>
          <span>￥{{ temp.paidInAmount / 100 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "../../utils/global";
export default {
  name: "AuditAndPayAccountsDetails",
  data() {
    return {
      title: this.$route.query.title,
      type: this.$route.query.type,
      list: JSON.parse(decodeURI(this.$route.query.item)),
      format,
    };
  },
};
</script>
<style lang="less" scoped>
.main-text {
  width: 100%;
  background-color: #fff;
}
.pay {
  width: 100%;
  background-color: #fff;
  .paytype {
    font-size: 16px;
    color: #2a2a2a;
  }
}
</style>
