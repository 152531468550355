<template>
  <div>
    <!-- 打印方式 -->
    <van-action-sheet v-model="visible" title="打印方式">
      <van-radio-group v-model="printType" @change="change">
        <van-cell-group>
          <van-cell
            v-for="item in types"
            :key="item.value"
            :title="item.name"
            clickable
            @click="printType = item.value"
          >
            <template #right-icon>
              <van-radio :name="item.value" :checked-color="color"
                ><span class="isdefault">设为默认</span></van-radio
              >
            </template>
          </van-cell>
          <van-cell is-link @click="openCustom">
            <template #title>
              <div :style="{ color: color }">自定义打印</div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div class="p-2x">
        <van-button round block :color="color" @click="visible = false"
          >保存</van-button
        >
      </div>
    </van-action-sheet>

    <!--自定义打印规则弹窗-->
    <custom-print ref="customPrint"></custom-print>
  </div>
</template>

<script>
import { commonPrintWay } from "@/api/cart";
import CustomPrint from "./CustomPrint";
export default {
  name: "PrintSetting",

  components: {
    CustomPrint,
  },

  data() {
    return {
      visible: false,
      cartId: "",
      printType: 1,
      types: [
        {
          name: "一个产品一票",
          value: 1,
        },
        {
          name: "一种产品一票",
          value: 2,
        },
        // {
        //   name: "自定义规则",
        //   value: 3,
        // },
      ],
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  created() {},
  methods: {
    initData(cartId) {
      this.cartId = cartId;
      // 如果是组合产品，则存在一子产品一票规则
      if (this.$store.state.currentFormat === "9") {
        this.types.splice(2, 0, {
          name: "一子产品一票",
          value: 4,
        });
      }

      this.printType =
        this.$store.getters["common/customPrintType"] ||
        this.$store.getters["common/defaultPrintType"];

      this.visible = true;
    },
    // 打印方式切换
    change() {
      this.updatePrint();
    },

    // 更新打印规则
    updatePrint() {
      commonPrintWay({
        cartId: this.cartId,
        cartPrintType: this.printType,
      })
        .then(() => {
          this.$store.commit("common/defaultPrintType", this.printType);
          this.$store.commit("common/customPrintType", null);
        })
        .catch(() => {});
    },

    openCustom() {
      this.$refs.customPrint.loadProductList(this.cartId).then(() => {
        this.visible = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.isdefault {
  color: #b8b8b8;
}
</style>
