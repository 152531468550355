<template>
  <div class="root">
    <van-nav-bar :title="'热销产品设置'" placeholder />
    <van-tabs :color="color" :title-active-color="color">
      <van-tab v-for="temp in formats" :key="temp.id" :title="temp.name">
        <HotProductList></HotProductList>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import HotProductList from "./components/HotProductList.vue";
export default {
  name: "HotSaleProduct",
  components: {
    HotProductList,
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  data() {
    return {
      formats: [
        {
          id: "2",
          name: "门票",
        },
        {
          id: "3",
          name: "游乐",
        },
        {
          id: "9",
          name: "组合产品",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.root {
  padding-bottom: 68px;
}
</style>
