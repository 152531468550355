<template>
  <div class="root">
    <van-nav-bar title="交账记录" placeholder />
    <van-calendar
      v-model="show"
      type="range"
      @confirm="onConfirm"
      :color="color"
      :min-date="minDate"
      allow-same-day
    />
    <div class="rili ph-2x">
      <div class="start" @click="show = true">
        <i></i>
        开始时间
        <span>{{ startdate.time }}</span>
      </div>
      <div class="end">
        <i>至</i>
        结束时间
        <span>{{ enddate.time }}</span>
      </div>
      <div class="btn">
        <button
          @click="query"
          :style="{ backgroundColor: startdate.time ? color : '' }"
        >
          查询
        </button>
      </div>
    </div>
    <!-- 交账记录列表 -->
    <div
      class="list"
      @click="goAuditAndPayAccounts(item.id)"
      v-for="item in list"
      :key="item.id"
    >
      <p>
        {{ item.accountBeginTime | date("yyyy-mm-dd hh:mm") }} ~
        {{ item.accountEndTime | date("yyyy-mm-dd hh:mm") }}
      </p>
      <p>员工：{{ item.salespersonName }}</p>
      <p>售票金额合计：{{ item.totalSaleAmount / 100 }}元</p>
    </div>
    <!-- 缺省页面 -->
    <div class="default" v-if="!list.length">
      <img src="../../assets/images/me/zanwu.png" alt="" />
      <div>暂无记录，请输入时间搜索</div>
    </div>
  </div>
</template>

<script>
import { orderHandoverMainInfoPage } from "../../api/order";
export default {
  name: "PaymentRecord",
  data() {
    return {
      startdate: {},
      enddate: {},
      show: false,
      list: [],
      minDate: new Date(2022, 0, 1),
    };
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.startdate.time = `${this.formatDate(start)}`;
      this.enddate.time = `${this.formatDate(end)}`;
      this.startdate.value = Date.parse(start) / 1000;
      this.enddate.value = Date.parse(end) / 1000;
    },
    query() {
      if (!this.startdate.value || !this.enddate.value) return;
      orderHandoverMainInfoPage({
        page: 1,
        size: 10,
        startTime: this.startdate.value,
        endTime: this.enddate.value,
      }).then((res) => {
        this.list = res.records;
      });
    },
    goAuditAndPayAccounts(id) {
      this.$router.push({
        path: "/me/auditAndPayAccounts",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.root {
  position: relative;
  height: 100vh;
  .rili {
    display: flex;
    height: 50px;
    background-color: #fff;
    align-items: center;
    .start {
      display: flex;
      align-items: center;
      width: 140px;
      i {
        width: 18px;
        height: 18px;
        background: url("../../assets/images/me/riqi.png") no-repeat;
        background-size: contain;
        margin-right: 9px;
      }
      span {
        margin-left: 10px;
      }
    }
    .end {
      width: 140px;
      i {
        color: #b8b8b8;
        text-decoration: none;
        margin-right: 10px;
      }
      span {
        margin-left: 10px;
      }
    }
    .btn {
      button {
        width: 70px;
        height: 30px;
        border-radius: 15px;
        border: none;
        background-color: #e2e2e2;
        color: #fff;
      }
    }
  }
  .list {
    width: 100%;
    height: 102px;
    padding: 16px 0 16px 18px;
    background-color: #fff;
    border-top: 1px solid #f1f1f1;
  }
  .default {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
    text-align: center;
    img {
      width: 92px;
    }
    div {
      margin-top: 18px;
      color: #d7d7d7;
      font-size: 12px;
    }
  }
}
</style>
