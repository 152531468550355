<!-- 票务业态 -->
<template>
  <div>
    <!--门票列表-->
    <product-list
      :api="productFrontSilkyTicket"
      ref="productList"
      @update-cart="updateCart"
      @change-cart="changeCart"
      @update-date="updateDate"
    ></product-list>
    <!-- 价格明细 -->
    <price-total ref="PriceTotal" />
    <!-- 打印方式 -->
    <print-way ref="PrintWay" :cartId="cart.id" />
    <!-- 支付方式 -->
    <div class="pay-box mt-1x">
      <pay-way
        business-type="1365211690130337793"
        ref="PayWay"
        :memberMobile="memberMobile"
        :cartId="cart.id"
        showSettlementBtn
        :preferential="
          cart.preferentialInfo &&
          cart.preferentialInfo.merchantDiscountAmount +
            cart.preferentialInfo.integralPayPrice >
            0
            ? true
            : false
        "
        @success="success"
        @close="close"
      />
    </div>
    <!-- 会员/挂单/取单 -->
    <footer-button
      ref="footerButton"
      @clear="clearCart"
      @getVip="getVip"
      @take="takeOrder"
      :cartId="cart.id"
    />

    <!--取单弹窗-->
    <take-order ref="takeOrder" @change="changeCart"></take-order>
  </div>
</template>
<script>
import { productFrontSilkyTicket } from "@/api/search";
import ProductList from "../components/product-list.vue";
import PriceTotal from "../components/price-total.vue";
import PayWay from "../../../components/PayWay.vue";
import PrintWay from "../components/print-way";
import footerButton from "../components/footer-button";
import TakeOrder from "./components/TakeOrder";
import { commonDeleteCart } from "../../../api/cart";
import {
  cartTicket,
  ticketMore,
  ticketDate,
  ticketAppointmentDate,
  commonMember,
} from "@/api/cart";
export default {
  name: "SellTicket",
  components: {
    ProductList,
    PriceTotal,
    PayWay,
    PrintWay,
    footerButton,
    TakeOrder,
  },
  data() {
    return {
      productFrontSilkyTicket,
      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        appointmentRules: "",
        timeId: "",
        name: "", // 产品名称
        categoryId: "", // 分类id
      },
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [], // 需要出游人的产品
      },
      showMember: false,
      appointment: 0, //分时预约选择 1.产品  2.子景区 3.景区
      times: [], //按子景区的时段列表
      showPayOnly: false,
      memberMobile: 0, // 会员手机号

      member: {},
    };
  },

  computed: {
    printType() {
      return this.$store.getters["common/defaultPrintType"];
    },
    // 是否下单成功清除购物车
    deleteCart() {
      return this.$store.getters["common/deleteCart"];
    },
  },

  mounted() {
    this.$refs.PayWay.payValue = "payOnline"; //设置个默认支付方式
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    // 立即结算
    goPay() {
      this.$refs.PayWay.pay();
    },
    // 支付成功
    success() {
      // 是否下单成功清除购物车
      if (this.deleteCart) {
        commonDeleteCart({
          cartId: this.cart.id,
        })
          .then(() => {
            this.clearCart();
          })
          .catch(() => {});
      }
      // 下单成功清除会员
      this.$refs.footerButton.vipMessage = {};
      this.$refs.footerButton.mobileShow = "";
      this.clearMember();
      this.$store.commit("common/customPrintType", null);
    },
    // 清除会员
    clearMember() {
      this.member = {};
      this.memberMobile = 0;
    },
    // 更改预约日期
    updateDate(data, timeArr) {
      if (data) {
        ticketDate(data)
          .then((res) => {
            this.changeCart(res);
            if (timeArr) {
              this.updateTime(timeArr);
            }
          })
          .catch(() => {});
      } else if (timeArr) {
        this.updateTime(timeArr);
      }
    },
    updateTime(data) {
      ticketAppointmentDate(data)
        .then((res) => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    loadData(params) {
      this.params = params;
      this.$refs.productList.loadData(params);
    },
    // 添加/更新到购物车 - 调接口
    async updateCart(obj) {
      // 添加购物车
      let api = cartTicket;
      // 更新购物车
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        api = ticketMore;
      }
      // 添加会员信息
      else {
        // 如果已经有会员信息
        if (this.memberInfo) {
          obj.purchaserMemberId = this.memberInfo.id;
        }
        // 如果本地存有默认打印方式
        if (this.printType) {
          obj.cartPrintType = this.printType;
        }
      }

      let ok = true;
      await api(obj)
        .then((res) => {
          if (res) {
            this.cart = res;
          }
          // 清空购物车
          else {
            this.clearCart();
          }
        })
        .catch(() => {
          ok = false;
        })
        .finally(() => {
          // 更新产品列表数量
          this.broadcast();
        });
      return ok;
    },
    // 已存在购物车，更新到购物车 - 不调接口
    changeCart(cart) {
      this.cart = cart;
      this.broadcast();
    },
    // 更新预约日期
    updateCartDate(datesId) {
      if (this.cart.id) {
        // 修改全局游玩日期
        ticketDate({
          cartId: this.cart.id,
          playDate: datesId,
        });
      }
    },
    getVip(member) {
      this.member = member;
      let val = member.id;
      const obj = {
        cartId: this.cart.id,
      };
      this.memberMobile = Number(member.mobile) || 0;
      if (val) {
        obj.purchaserMemberId = val;
      }
      if (this.cart.id === 0) return;
      commonMember({
        cartId: this.cart.id,
        purchaserMemberId: val,
      }).then((res) => {
        this.changeCart(res);
      });
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [], // 需要出游人的产品
      };
      this.broadcast();
      this.$store.commit("common/customPrintType", null);
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$refs.productList.updateNum(this.cart);
      this.$refs.PriceTotal.updateCart(this.cart);
      this.$refs.PriceTotal.member = this.member;
      // this.$refs.PayType.updateCart(this.cart);
      // this.$refs.productCalcFooter.updateCart(this.cart);
    },
    // 取单弹窗
    takeOrder() {
      this.$refs.takeOrder.loadData();
    },
  },
};
</script>

<style scoped lang="less">
.pay-box {
  width: 96%;
  margin: 8px auto 0;
  border-radius: 6px;
}
</style>
