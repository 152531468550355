<!-- 票务业态 -->
<template>
  <div>
    <!--门票列表-->
    <product-list
      :api="productFrontCombinationProduct"
      ref="productList"
      :format="13"
      @update-cart="updateCart"
      @change-cart="changeCart"
      @update-date="updateDate"
    ></product-list>
    <!-- 价格明细 -->
    <price-total ref="PriceTotal" />
    <!-- 打印方式 -->
    <print-way ref="PrintWay" :cartId="cart.id" />
    <!-- 支付方式 -->
    <div class="pay-box mt-1x">
      <pay-way
        ref="PayWay"
        business-type="1459375207863967746"
        :cartId="cart.id"
        showSettlementBtn
        @success="success"
      />
    </div>

    <!-- 会员/挂单/取单 -->
    <footer-button @clear="clearCart" @getVip="getVip" :cartId="cart.id" />
  </div>
</template>
<script>
import { productFrontCombinationProduct } from "@/api/search";
import {
  commonDeleteCart,
  combinationDate,
  combinationAppointmentDate,
} from "../../../api/cart";
import ProductList from "../components/product-list.vue";
import PriceTotal from "../components/price-total.vue";
import PayWay from "../../../components/PayWay.vue";
import PrintWay from "../components/print-way";
import footerButton from "../components/footer-button";
import { combination, combinationMore, commonMember } from "@/api/cart";
export default {
  name: "CombinationSellTicket",
  components: { ProductList, PriceTotal, PayWay, PrintWay, footerButton },
  data() {
    return {
      productFrontCombinationProduct,
      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        appointmentRules: "",
        timeId: "",
        name: "", // 产品名称
        categoryId: "", // 分类id
      },
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [], // 需要出游人的产品
      },
      showMember: false,
      appointment: 0, //分时预约选择 1.产品  2.子景区 3.景区
      times: [], //按子景区的时段列表
      showPayOnly: false,
    };
  },
  computed: {
    // 是否下单成功清除购物车
    deleteCart() {
      return this.$store.getters["common/deleteCart"];
    },
  },
  mounted() {
    this.$refs.PayWay.payValue = "payOnline"; //设置个默认支付方式
  },
  methods: {
    // 更改预约日期
    updateDate(data, timeArr) {
      if (data) {
        combinationDate(data)
          .then((res) => {
            this.changeCart(res);
            if (timeArr) {
              this.updateTime(timeArr);
            }
          })
          .catch(() => {});
      } else if (timeArr) {
        this.updateTime(timeArr);
      }
    },
    updateTime(data) {
      combinationAppointmentDate(data)
        .then((res) => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 支付成功
    success() {
      // 是否下单成功清除购物车
      if (this.deleteCart) {
        commonDeleteCart({
          cartId: this.cart.id,
        })
          .then(() => {
            this.clearCart();
          })
          .catch(() => {});
      }
    },
    loadData(params) {
      this.params = params;
      this.$refs.productList.loadData(params);
    },
    // 组合 添加/更新到购物车 - 调接口
    async updateCart(obj) {
      // 添加购物车
      let api = combination;
      // 更新购物车
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        api = combinationMore;
      }
      // 添加会员信息
      else {
        // 如果已经有会员信息
        if (this.memberInfo) {
          obj.purchaserMemberId = this.memberInfo.id;
        }
        // 如果本地存有默认打印方式
        if (localStorage.ticketPrintType) {
          obj.cartPrintType = localStorage.ticketPrintType;
        }
      }

      let ok = true;
      await api(obj)
        .then((res) => {
          if (res) {
            this.cart = res;
          }
          // 清空购物车
          else {
            this.clearCart();
          }
        })
        .catch(() => {
          ok = false;
        })
        .finally(() => {
          // 更新产品列表数量
          this.broadcast();
        });
      return ok;
    },
    // 已存在购物车，更新到购物车 - 不调接口
    changeCart(cart) {
      this.cart = cart;
      this.broadcast();
    },
    getVip(member) {
      let val = member.id;
      const obj = {
        cartId: this.cart.id,
      };
      if (val) {
        obj.purchaserMemberId = val;
      }
      commonMember({
        cartId: this.cart.id,
        purchaserMemberId: val,
      }).then((res) => {
        this.changeCart(res);
      });
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [], // 需要出游人的产品
      };
      this.broadcast();
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$refs.productList.updateNum(this.cart);
      this.$refs.PriceTotal.updateCart(this.cart);
      // this.$refs.PayType.updateCart(this.cart);
      // this.$refs.productCalcFooter.updateCart(this.cart);
    },
  },
};
</script>

<style scoped lang="less">
.pay-box {
  width: 96%;
  margin: 0 auto;
  margin-top: 8px;
  border-radius: 6px;
}
</style>
