<template>
  <div>
    <!-- 头部 头像和 id -->
    <div
      class="hearder-one"
      :style="{ 'background-color': $store.getters['common/color'].primary }"
    >
      <img :src="user.headImgUrl || avatar" alt="" />
      <div class="hearder-id">
        <div>{{ user.userName || user.nickName }}</div>
        <p>{{ user.phone ? phoneFormat(user.phone) : user.accountNo }}</p>
      </div>
    </div>
    <!--    <van-cell title="打印方式设置" is-link class="vant-cell">
      <template #icon>
        <img class="imgs" src="../../assets/images/me/dayin.png" alt="" />
      </template>
    </van-cell>-->
    <van-cell
      title="修改密码"
      is-link
      class="vant-cell"
      to="/me/ChangePassword"
    >
      <template #icon>
        <img class="imgs" src="../../assets/images/me/mima.png" alt="" />
      </template>
    </van-cell>
    <van-cell
      title="热销产品"
      is-link
      class="vant-cell"
      to="/me/HotSaleProduct"
    >
      <template #icon>
        <img class="imgs" src="../../assets/images/me/hot.png" alt="" />
      </template>
    </van-cell>
    <van-cell
      title="查账交账"
      is-link
      class="vant-cell"
      to="/me/auditAndPayAccounts"
    >
      <template #icon>
        <img class="imgs" src="../../assets/images/me/chazhang.png" alt="" />
      </template>
    </van-cell>
    <van-cell title="交账记录" is-link class="vant-cell" to="/me/paymentRecord">
      <template #icon>
        <img class="imgs" src="../../assets/images/me/zb.png" alt="" />
      </template>
    </van-cell>
    <van-cell title="颜色设置" is-link class="vant-cell" @click="colorStting">
      <template #icon>
        <img class="imgs" src="../../assets/images/me/yanse.png" alt="" />
      </template>
    </van-cell>
    <ColorSetting ref="ColorSetting" />
    <van-cell title="回到旧版" is-link @click="toOld" class="vant-cell">
      <template #icon>
        <img class="imgs" src="../../assets/images/me/back.png" alt="" />
      </template>
    </van-cell>
    <van-cell
      title="切换景区"
      is-link
      url="/#/login/MerchantList"
      class="vant-cell"
    >
      <template #icon>
        <img class="imgs" src="../../assets/images/me/qiehuan.png" alt="" />
      </template>
    </van-cell>
    <div class="text-c">
      <button @click="out" class="btn">退出登录</button>
    </div>
  </div>
</template>

<script>
import { getUser, logout } from "../../api/user";
import { phoneFormat } from "../../utils/global";
import ColorSetting from "./ColorSetting.vue";
import avatar from "../../assets/images/me/avatar.png";

export default {
  name: "MeIndex",
  components: {
    ColorSetting,
  },
  data() {
    return {
      user: {},
      phoneFormat,
      avatar,
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    toOld() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要切换回旧版吗？系统将会刷新！",
        })
        .then(() => {
          localStorage.setItem("newVersion", "false");
          location.reload();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 切换主题色
    colorStting() {
      this.$refs.ColorSetting.colorShow = true;
    },
    out() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要退出登录吗?",
        })
        .then(() => {
          localStorage.removeItem("token");
          setTimeout(() => {
            logout().then(() => {});
            this.$router.push("/login");
          }, 500);
        });
    },
    // 获取用户个人信息
    async getUserInfo() {
      this.user = await getUser();
    },
  },
};
</script>

<style lang="less" scoped>
.hearder-one {
  // background-image: url("../../assets/images/me/bg.png");
  background-image: url("../../assets/images/me/bg1.png");
  background-size: 100%;
  height: 168px;
  display: flex;
  align-items: center;
  img {
    margin: 0 22px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  .hearder-id {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
    p {
      font-size: 16px;
      font-weight: normal;
    }
  }
}
.vant-cell {
  display: flex;
  align-items: center;
  .imgs {
    width: 20px;
    height: 20px;
    margin-right: 17px;
  }
}
.btn {
  width: 90%;
  height: 50px;
  margin: 22px auto;
  border-radius: 25px;
  background-color: #fff;
  border: none;
  color: #f31c1c;
}
</style>
