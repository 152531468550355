<template>
  <div>
    <!-- 产品汇总详情组件 -->
    <div class="header-text pl-2x">
      <p class="mt-2x">员工：{{ user.userName }}</p>
      <div class="mv-1x">
        上班时间：{{ user.clockIn | date("yyyy-mm-dd hh:mm") }}
      </div>
      <div>交账时间：{{ user.clockOff | date("yyyy-mm-dd hh:mm") }}</div>
    </div>
    <!-- 产品汇总列表 start-->
    <div class="main-text font-s ph-2x mt-1x">
      <div class="flex-between pv-1x">
        <span>销售数量</span>
        <span>{{ productTotal.totalSalesNum }}个</span>
      </div>
      <div class="flex-between pv-1x">
        <span>退款数量</span>
        <span>{{ productTotal.totalRefundNum }}个</span>
      </div>
      <div class="flex-between pv-1x">
        <span>数量小计</span>
        <span>{{ productTotal.totalPaidInNum || 0 }}个</span>
      </div>
      <div class="flex-between pv-1x">
        <span>销售金额</span>
        <span>￥{{ productTotal.totalActualAmount / 100 || 0 }}</span>
      </div>
      <div class="flex-between pv-1x">
        <span>退款金额</span>
        <span>￥{{ productTotal.totalRefundAmount / 100 || 0 }}</span>
      </div>
      <div class="flex-between pv-1x">
        <span>金额小计</span>
        <span>￥{{ productTotal.totalPaidInAmount / 100 || 0 }}</span>
      </div>
      <!-- 待定 -->
      <div class="flex-between pv-1x">
        <span>合计</span>
        <span>￥{{ productTotal.totalPaidInAmount / 100 || 0 }}</span>
      </div>
    </div>
    <!-- 产品汇总列表 底部详情 -->
    <div class="footer-text font-s ph-2x mt-1x">
      <div
        class="flex-between pv-1x"
        v-for="(item, index) in productData"
        :key="index"
      >
        <span>{{ item.productName }}</span>
        <span :style="{ color: color }" @click="goDetails(item)">详情</span>
      </div>
    </div>
    <!-- 产品汇总列表  end-->
  </div>
</template>

<script>
import { orderAccountProductsummary } from "../../../api/search";
import { orderHandoverAccountInfo } from "@/api/order";
export default {
  name: "PaymentList",
  props: {
    id: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  created() {
    this.getSummary();
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  data() {
    return {
      summarylist: [], // 商户汇总列表
      money: {}, // 商户汇总 数据
      productTotal: {}, // 产品汇总列表
      productData: [], // 产品汇总 数据
    };
  },
  methods: {
    getSummary() {
      // 从交账记录页面跳转过来的交账汇总
      if (this.id) {
        // 产品总汇
        orderHandoverAccountInfo({ id: this.id }).then((res) => {
          let list = res[res.length - 1];
          this.productTotal.totalSalesNum = list.saleNum; //销售数量
          this.productTotal.totalRefundNum = list.refundNum; //退款数量
          this.productTotal.totalPaidInNum = list.actualSaleNum; //数量小计
          this.productTotal.totalActualAmount = list.saleAmount; //销售金额（单位：分）
          this.productTotal.totalPaidInAmount = list.actualSaleAmount; //金额小计（单位：分）
          this.productTotal.totalRefundAmount = list.refundAmount; //	退款金额（单位：分）
          res.pop();
          this.productData = res;
        });
        return;
      }
      // 交账产品汇总
      orderAccountProductsummary({
        accountStartTime: this.user.clockIn,
        accountEndTime: this.user.clockOff,
      }).then((res) => {
        let {
          totalSalesNum,
          totalRefundNum,
          totalPaidInNum,
          totalActualAmount,
          totalPaidInAmount,
          totalRefundAmount,
        } = res;
        this.productData = res.records;
        this.productTotal.totalSalesNum = totalSalesNum; //销售数量
        this.productTotal.totalRefundNum = totalRefundNum; //退款数量
        this.productTotal.totalPaidInNum = totalPaidInNum; //数量小计
        this.productTotal.totalActualAmount = totalActualAmount; //销售金额（单位：分）
        this.productTotal.totalPaidInAmount = totalPaidInAmount; //金额小计（单位：分）
        this.productTotal.totalRefundAmount = totalRefundAmount; //	退款金额（单位：分）
      });
    },
    goDetails(item) {
      let res;
      let type = "ZH"; // 默认是总汇页面
      if (this.id) {
        type = "JL"; // 从交账记录跳转的标识
        res = encodeURI(JSON.stringify(item));
      } else {
        type = "ZH"; // 默认是总汇页面
        res = encodeURI(JSON.stringify(item));
      }
      this.$router.push({
        path: "/me/auditAndPayAccountsDetails",
        query: {
          item: res,
          title: "产品详情",
          type,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header-text {
  height: 110px;
  width: 100%;
  border-top: 1px solid #f3f3f3;
  background-color: #fff;
}
.main-text {
  width: 100%;
  background-color: #fff;
}
.footer-text {
  width: 100%;
  background-color: #fff;
}
</style>
