<template>
  <van-action-sheet title="加购产品" v-model="visible">
    <div class="root">
      <div class="p-2x all">
        <van-checkbox
          v-model="all"
          @click="allFn"
          shape="square"
          :checked-color="color"
          >全选</van-checkbox
        >
      </div>
      <div
        class="p-2x list flex-between"
        v-for="(item, index) in listMore"
        :key="index"
      >
        <van-checkbox
          class="flex-grow"
          style="max-width: 70vw"
          shape="square"
          v-model="item.check"
          @change="changeList(item)"
          :checked-color="color"
          :disabled="
            item.relationalInfo &&
            !item.relationalInfo.whetheChangeNum &&
            item.checkDefault
              ? true
              : false
          "
        >
          {{ item.skuName }}
        </van-checkbox>
        <p class="font-l bold mt-1x flex-shrink">
          <span class="red font-s">￥</span>{{ item.price / 100 }}
        </p>
      </div>
      <!-- 底部确认按钮 -->
      <div class="footer">
        <van-button type="primary" round block :color="color" @click="submit">
          确定
        </van-button>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import { getTicketAddBuy } from "../../../../api/cart";
export default {
  name: "PayMore",
  data() {
    return {
      visible: false,
      all: false,
      listMore: [],
      productId: "",
      dailyCalendar: "",
    };
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  created() {
    // this.getMore();
  },
  methods: {
    allFn() {
      let all = this.all ? true : false;
      this.listMore.forEach((item) => {
        item.check = all;
        // 默认选中不可修改数量
        if (item.checkDefault && !item.relationalInfo.whetheChangeNum) {
          item.check = true;
        }
      });
    },
    changeList(item) {
      item.check = item.check ? 1 : 0;
      this.all = this.listMore.every((item) => item.check);
    },
    submit() {
      const arr = this.listMore
        .filter((item) => item.check)
        .map((temp) => {
          return {
            addBuyProductId: temp.id,
            addBuyNum: 1,
          };
        });
      if (arr.length) {
        this.$emit("change", arr);
      }
      this.visible = false;
    },
    getMore(productId, dailyCalendar) {
      this.productId = productId;
      this.dailyCalendar = dailyCalendar;
      getTicketAddBuy({
        productId: this.productId,
        dailyCalendar: this.dailyCalendar,
      }).then((res) => {
        this.visible = true;
        if (!res.length) return;
        res.forEach((item) => {
          item.check = item.checkDefault;
        });
        this.listMore = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.root {
  padding-bottom: 70px;
  background: #fff;
}
.all {
  border-top: 1px solid #f1f1f1;
}
.list {
  border-top: 1px solid #f1f1f1;
}
.footer {
  padding: 12px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
