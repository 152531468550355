<template>
  <div>
    <!-- 占位 -->
    <div style="height: 55px"></div>
    <!-- 底部 挂单取单 -->
    <div class="scroll-view">
      <div class="footer">
        <div class="flex-center mr-1x">
          <van-switch
            :active-color="color"
            :value="checked"
            size="20"
            class="mr-1x"
            @change="changeSwitch"
          />
          <span>下单完成清除当前购物车</span>
        </div>
        <button class="vip mr-1x" :style="[index == 0 ? native : '']">
          <span @click="isNative(0)" v-if="!mobileShow">会员</span>
          <span v-if="mobileShow">{{ mobileShow }}</span>
          <span class="close" v-if="mobileShow" @click="closeVip">
            <img src="../../../assets/images/quxiao-dl.png" alt="" />
          </span>
        </button>
        <button
          @click="isNative(1)"
          class="put mr-1x"
          :style="[index == 1 ? native : '']"
        >
          挂起购物车
        </button>
        <button
          @click="isNative(2)"
          class="get mr-1x"
          :style="[index == 2 ? native : '']"
        >
          取出购物车
        </button>
        <button
          class="get mr-1x"
          @click="isNative(3)"
          :style="[index == 3 ? native : '']"
        >
          取票
        </button>
        <button
          class="get"
          @click="isNative(4)"
          :style="[index == 4 ? native : '']"
        >
          清账
        </button>
      </div>
    </div>
    <!-- 会员登录弹窗 -->
    <van-overlay :show="show" z-index="9" @click="show = false">
      <div class="overlay-main ph-2x" @click.stop>
        <div class="title">
          <div class="overlay-title text-c font-l pv-2x">输入会员号</div>
          <div class="close" @click="close">
            <img src="../../../assets/images/quxiao.png" alt="" />
          </div>
        </div>
        <div class="pv-4x pv-2x text-main">
          <div class="int">
            <input
              v-model="mobile"
              class="pl-2x"
              type="number"
              placeholder="请输入号码"
            />
            <button @click="search" :style="{ backgroundColor: color }">
              确定
            </button>
          </div>
          <van-icon size="24px" :color="color" name="scan" @click="scan" />
        </div>
      </div>
    </van-overlay>
    <!-- 取票弹窗 -->
    <van-overlay
      :show="showTakeTicket"
      z-index="9"
      @click="showTakeTicket = false"
    >
      <div class="overlay-main" @click.stop>
        <div class="title">
          <div class="overlay-title text-c font-l pv-2x ph-2x">取票</div>
        </div>
        <van-search
          class="flex-1 search"
          show-action
          v-model="searchNumber"
          placeholder="请输入订单号或票号"
          @keyup.enter="clickSearch"
        >
          <template #action>
            <div
              @click="clickSearch"
              class="seach-btn text-c click bold"
              :style="{
                'background-color': $store.getters['common/color'].primary,
              }"
            >
              确定
            </div>
          </template>
        </van-search>
        <div class="list">
          <div class="item">
            <van-checkbox
              v-model="checkAll"
              shape="square"
              @change="changeCheckAll"
              >全选</van-checkbox
            >
          </div>
          <van-checkbox-group v-model="checkboxValue" ref="checkboxGroup">
            <div class="item" v-for="item in productList" :key="item.id">
              <div class="flex-between">
                <van-checkbox shape="square" :name="item.id">{{
                  item.productName
                }}</van-checkbox>
                <p>x{{ item.buyNum }}</p>
              </div>
              <p class="ml-056em red">{{ item.sellingPrice | money() }}</p>
              <div class="ml-056em flex-between">
                <p>小计：{{ item.totalAmount | money() }}</p>
                <p :style="{ color }" @click="setPrintWay(item)">
                  设置打印方式
                </p>
              </div>
            </div>
          </van-checkbox-group>
        </div>
        <div class="flex-between pop-centent-buttom">
          <button class="pop-centent-btn" @click="closeTakeTicket">取消</button>
          <button class="pop-centent-btn confirm" @click="printOk">确认</button>
        </div>
      </div>
    </van-overlay>
    <!-- 打印规则弹窗 -->
    <van-overlay :show="showCustom" z-index="9" @click="showCustom = false">
      <div class="overlay-main" @click.stop>
        <div class="title">
          <div class="overlay-title text-c font-l pv-2x ph-2x">
            设置打印规则
          </div>
        </div>
        <div class="ph-2x pt-2x print-main">
          <van-radio-group
            v-model="printForm.cartPrintType"
            @change="printTypeChange"
          >
            <van-radio
              :name="item.value"
              v-for="item in types"
              :key="item.value"
              class="mb-2x"
              >{{ item.name }}</van-radio
            >
          </van-radio-group>
          <div v-if="printForm.cartPrintType === 3">
            <div class="mb-2x">
              <span>本次打印票数：</span>
              <input
                type="number"
                class="print-type-inp ph-1x"
                placeholder="输入"
                :min="1"
                :max="tempRow.buyNum"
                v-model="printForm.printNum"
                @blur="changeNum"
                @change="changeBuyNum"
              />
              <span>张</span>
            </div>
            <div>
              <p class="mb-2x">每张票支持核销次数：</p>
              <div class="flex-between">
                <div
                  v-for="(item, index) in printForm.times"
                  :key="index"
                  class="mb-2x"
                >
                  <input
                    type="number"
                    :min="0"
                    :max="item.maxTotalNum"
                    class="print-time ph-1x"
                    v-model="item.num"
                    @blur="blurWriteOff"
                    @focus="focusNum(item, index)"
                    @change="changeMaxTotalNum(item)"
                  /><span :style="{ color }" @click="showDetail(index)"
                    >明细</span
                  >
                </div>
              </div>
            </div>
            <p class="mb-2x">
              提示：{{ tempRow.productName }}共购买<span
                class="red bold mh-1x"
                >{{ tempRow.buyNum }}</span
              >个，可出票<span class="red bold mh-1x">{{
                tempRow.buyNum || 0
              }}</span
              >个，本次出票<span class="red bold mh-1x">{{
                printForm.printNum || 0
              }}</span
              >个，包含产品数<span class="red bold mh-1x">{{ cludeNum }}</span
              >个
            </p>
          </div>
        </div>
        <div class="flex-between pop-centent-buttom">
          <button class="pop-centent-btn" @click="showCustom = false">
            取消
          </button>
          <button class="pop-centent-btn confirm" @click="handleOk">
            确认
          </button>
        </div>
      </div>
    </van-overlay>
    <!-- 查看明细 -->
    <van-overlay :show="isShowDetail" z-index="9" @click="cancelDetail">
      <div class="overlay-main" @click.stop>
        <div class="title">
          <div class="overlay-title text-c font-l pv-2x ph-2x">查看明细</div>
        </div>
        <div class="ph-2x pt-2x print-main">
          <div class="flex-start mb-2x">
            <span>票号列表：</span>
            <van-checkbox shape="square" :value="false" class="mr-1x"
              >可用</van-checkbox
            ><van-checkbox shape="square" :value="true" class="mr-1x"
              >已选</van-checkbox
            ><van-checkbox shape="square" disabled>已用</van-checkbox>
          </div>
          <van-checkbox-group direction="horizontal" v-model="checkboxGroup">
            <van-checkbox
              shape="square"
              v-for="item in ticketsList"
              :key="item.id"
              :name="item.id"
              :disabled="item.checked !== '' && item.checked !== checkIndex"
              @click="changeChecked(item)"
              class="mb-2x"
              >{{ item.productName }}</van-checkbox
            >
          </van-checkbox-group>
        </div>
        <div class="flex-between pop-centent-buttom">
          <button class="pop-centent-btn" @click="cancelDetail">取消</button>
          <button class="pop-centent-btn confirm" @click="checkedOk">
            确认
          </button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { commonPauseCart } from "../../../api/cart";
import {
  orderTaketicketslist,
  orderSubordertaketicket,
} from "../../../api/search";
import { findDefindPrintInfo, saveDefindPrintInfo } from "../../../api/order";
import { orderOneMemberInfo } from "../../../api/member";
import { scanQRCode, printTicket } from "../../../utils/hardware";
export default {
  name: "footerButton",
  props: {
    //   取单号
    cartId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      native: {
        color: this.$store.getters["common/color"].primary,
        border: `1px solid ${this.$store.getters["common/color"].primary}`,
      },
      index: 0,
      show: false, // 是否显示会员登录弹窗
      mobile: "", // 手机号
      vipMessage: {},
      mobileShow: "",
      showTakeTicket: false, // 取票弹窗
      searchNumber: "", // 搜索值
      productList: {}, // 产品列表
      checkboxValue: [], //选中
      checkAll: false, // 全选
      tempRow: {
        buyNum: 0,
      },
      showCustom: false, // 打印规则弹窗
      printForm: {
        cartPrintType: 1, //打印规则：1后台打印规则，2窗口打印规则，3自定义规则
        printNum: 2, //本次打印数量
        times: [{ num: 1, maxTotalNum: 1 }], //每张票支持核销次数，数字数组
      },
      types: [
        {
          name: "一个产品一票",
          value: 1,
        },
        {
          name: "一种产品一票",
          value: 2,
        },
        {
          name: "自定义规则",
          value: 3,
        },
      ],
      ticketsList: [], // 产品列表
      cludeNum: 0, // 包含票数
      remainingNum: 0, // 剩余票数
      usedNum: 0, // 产品已使用数量
      isShowDetail: false, //查看明细弹窗
      checkboxGroup: [], // 产品选择列表
      cacheData: [], //缓存选中产品
      checkIndex: 0, // 选中
    };
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
    currentFormats() {
      return this.$store.state.common.currentFormat;
    },
    // 是否选中
    checked() {
      return this.$store.getters["common/deleteCart"];
    },
  },
  methods: {
    // 保存打印规则
    handleOk() {
      let productDefindPrintDTOList = {};
      // 自定义规则数据
      if (this.printForm.cartPrintType === 3) {
        let defindPrintInfoList = [];
        this.printForm.times.forEach((val, key) => {
          let numList = [];
          this.ticketsList.forEach((item) => {
            if (item.checked === key) {
              numList.push(item.id);
            }
          });
          defindPrintInfoList.push({ numList });
        });
        productDefindPrintDTOList = {
          subOrderId: this.tempRow.id,
          num: this.printForm.printNum,
          productName: this.tempRow.productName,
          defindPrintInfoList,
        };
      }
      this.productList.forEach((item) => {
        if (item.id === this.tempRow.id) {
          item.printType = this.printForm.cartPrintType;
          item.productDefindPrintDTOList = productDefindPrintDTOList;
        }
      });
      this.showCustom = false;
    },
    // 取票确认
    printOk() {
      if (this.checkboxValue.length === 0) {
        this.$toast("请选择产品");
        return;
      }
      let arr = [];
      let productDefindPrintDTOList = [];
      this.productList.forEach((item) => {
        if (this.checkboxValue.indexOf(item.id) !== -1) {
          arr.push({
            id: item.id,
            printWay: item.printType,
          });
          if (item.printType === 3) {
            productDefindPrintDTOList.push(item.productDefindPrintDTOList);
          }
        }
      });
      // 有自定义规则先保存
      if (productDefindPrintDTOList.length > 0) {
        saveDefindPrintInfo({ productDefindPrintDTOList })
          .then(() => {
            this.takeTicket(arr);
          })
          .catch();
      } else {
        //取票
        this.takeTicket(arr);
      }
    },
    // 取票
    takeTicket(arr) {
      orderSubordertaketicket(arr)
        .then((res) => {
          if (res.length > 0) {
            printTicket(JSON.stringify(res));
            this.$toast.success("正在打印中");
            this.showTakeTicket = false;
          }
        })
        .catch(() => {});
    },
    // 自定义规则-选择
    changeChecked(item) {
      if (this.checkboxGroup.indexOf(item.id) !== -1) {
        item.checked = this.checkIndex;
      } else {
        item.checked = "";
      }
    },
    // 自定义规则-选中确认
    checkedOk() {
      if (
        this.printForm.times[this.checkIndex].num != this.checkboxGroup.length
      ) {
        this.$toast("选中票数不等于填写票数");
      } else {
        this.isShowDetail = false;
      }
    },
    // 自定义规则-查看明细
    showDetail(index) {
      this.checkIndex = index;
      this.isShowDetail = true;
      let checkboxGroup = [];
      // 初始化选中值
      for (let j = 0; j < this.ticketsList.length; j++) {
        if (this.ticketsList[j].checked === index) {
          checkboxGroup.push(this.ticketsList[j].id);
        }
      }
      this.cacheData = checkboxGroup; // 缓存一份选中的值，在改变时选中产品取消时还原数据
      this.checkboxGroup = checkboxGroup;
    },
    // 自定义规则-查看详情取消
    cancelDetail() {
      for (let j = 0; j < this.ticketsList.length; j++) {
        // 选中的没缓存在前面分配的就清空
        if (
          this.ticketsList[j].checked === this.checkIndex &&
          this.cacheData.indexOf(this.ticketsList[j].id) === -1
        ) {
          this.ticketsList[j].checked = "";
        } else if (
          this.ticketsList[j].checked === "" &&
          this.cacheData.indexOf(this.ticketsList[j].id) !== -1
        ) {
          // 取消掉选中的，在缓存数据中的就重新选中
          this.ticketsList[j].checked = this.checkIndex;
        }
      }
      this.isShowDetail = false;
    },
    // 可输入最大产品数
    changeMaxTotalNum(row) {
      if (row.num > row.maxTotalNum) {
        row.num = row.maxTotalNum;
      }
    },
    // 最大票数
    changeBuyNum() {
      if (this.printForm.printNum > this.tempRow.buyNum) {
        this.printForm.printNum = this.tempRow.buyNum;
      }
    },
    // 选择打印方式
    printTypeChange() {
      if (this.printForm.cartPrintType === 3) {
        this.getFindDefindPrintInfo();
      }
    },
    // 查询产品的自定义打印规则
    getFindDefindPrintInfo() {
      findDefindPrintInfo({
        subOrderId: this.tempRow.id,
      })
        .then((res) => {
          let usedNum = 0;
          for (let i = 0; i < res.length; i++) {
            // 计算已使用的产品
            if (res[i].status === 6) {
              usedNum += 1;
            }
          }
          this.usedNum = usedNum;
          this.ticketsList = res;
        })
        .catch(() => {});
    },
    // 匹配产品
    checkTicket() {
      // 初始化选中值
      for (let j = 0; j < this.ticketsList.length; j++) {
        this.ticketsList[j].checked = ""; // 选中
      }
      for (let i = 0; i < this.printForm.times.length; i++) {
        let num = this.printForm.times[i].num;
        for (let j = 0; j < this.ticketsList.length; j++) {
          if (this.ticketsList[j].checked === "" && num !== 0) {
            this.ticketsList[j].checked = i;
            num--;
          }
        }
      }
    },
    // 获取焦点 计算可输入最大产品数
    focusNum(item, index) {
      let num = 0;
      this.printForm.times.forEach((val, key) => {
        if (index !== key) {
          num += val.num;
        }
      });
      // 本张票最大输数 = 购买数量 - 其它框输入的数量 - 产品已使用数量
      item.maxTotalNum = this.tempRow.buyNum - num - this.usedNum;
    },
    //计算核销次数之和及剩余核销次数
    calculateNum() {
      let totalNum = 0;
      for (let i = 0; i < this.printForm.times.length; i++) {
        totalNum += Number(this.printForm.times[i].num);
      }
      this.cludeNum = totalNum;
      this.remainingNum = this.tempRow.buyNum - totalNum;
      this.checkTicket();
    },
    //本次打印票数改变
    changeNum() {
      if (this.printForm.printNum > this.tempRow.buyNum) {
        this.$toast("打印的票数不能大于产品数量");
      } else {
        let defindPrintInfoList = [];
        if (this.tempRow.productDefindPrintDTOList) {
          defindPrintInfoList =
            this.tempRow.productDefindPrintDTOList.defindPrintInfoList;
        }
        this.printForm.times = [];
        for (let i = 0; i < this.printForm.printNum; i++) {
          let num =
            defindPrintInfoList.length > 0
              ? defindPrintInfoList[i]
                ? defindPrintInfoList[i].numList.length
                : 1
              : 1;
          this.printForm.times.push({
            num,
            maxTotalNum: num,
          });
        }
      }
      this.calculateNum();
    },
    //产品数量失去焦点
    blurWriteOff() {
      this.calculateNum();
    },
    // 设置打印方式
    setPrintWay(row) {
      if (this.tempRow.id !== row.id) {
        this.printForm.cartPrintType = row.printType;
      }
      this.tempRow = row;
      this.printForm.printNum =
        row.printType === 3 ? row.productDefindPrintDTOList.num : "";
      this.printForm.writeOffNum = row.buyNum || [1];
      this.showCustom = true;
      this.changeNum();
    },
    // 全选
    changeCheckAll() {
      this.$refs.checkboxGroup.toggleAll(this.checkAll);
    },
    // 搜索
    clickSearch() {
      if (this.searchNumber) {
        orderTaketicketslist({
          keyword: this.searchNumber,
        })
          .then((res) => {
            // 根据票号查直接打印
            if (res.printInfo.length > 0) {
              printTicket(JSON.stringify(res.printInfo));
              this.$toast.success("正在打印中");
              this.showTakeTicket = false;
            } else {
              // 根据订单查
              res.productInfo.forEach((item) => {
                item.printType = 1;
              });
              this.productList = res.productInfo;
            }
          })
          .catch(() => {});
      } else {
        this.productList = [];
      }
    },
    // 关闭取票弹窗
    closeTakeTicket() {
      this.showTakeTicket = false;
    },
    // 下单清除购物车
    changeSwitch() {
      this.$store.commit("common/deleteCart", !this.checked);
    },
    closeVip() {
      this.vipMessage = {};
      this.$emit("getVip", this.vipMessage);
      this.mobileShow = "";
    },
    search() {
      if (this.mobile.length == 0) {
        this.$toast("请输入会员手机号");
        return;
      }
      orderOneMemberInfo({ evidence: this.mobile }).then((res) => {
        this.vipMessage = res;
        this.mobileShow = res.mobile;
        this.$emit("getVip", this.vipMessage);
        this.show = false;
      });
    },
    close() {
      this.show = false;
    },
    // 扫码
    scan() {
      scanQRCode(this.scanOk);
    },
    // 扫码成功返回
    scanOk(code) {
      this.mobile = code;
      this.search();
    },
    isNative(index) {
      this.index = index;
      //   取单
      if (index == 2) {
        this.$emit("take", true);
      }
      //   挂单
      if (index == 1) {
        if (!this.cartId) {
          this.$toast("您还未选择产品");
          return;
        }
        commonPauseCart({
          cartId: this.cartId || "",
          pauseCartFormatType: this.currentFormats,
        }).then(() => {
          this.$toast("挂单成功");
          this.$emit("clear", true);
        });
      }
      // 会员
      if (index === 0) {
        this.mobile = "";
        this.show = true;
      }
      // 取票
      if (index === 3) {
        this.showTakeTicket = true;
        this.searchNumber = ""; // 初始化取票搜索值
        this.productList = {}; // 初始化取票产品列表
        this.checkboxValue = []; // 初始化选中值
      } else if (index === 4) {
        this.$router.push({
          path: "/record/CloseoutAccountList",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-view {
  position: fixed;
  bottom: 48px;
  left: 0;
  width: 100%;
  overflow-x: scroll;
  .footer {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    box-shadow: 3px 0px 6px 1px rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid #f3f3f3;
    width: max-content;
    min-width: 100%;
    padding: 0px 12px;
    .vip {
      width: 128px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .close {
        img {
          width: 16px;
          height: 16px;
          vertical-align: text-bottom;
        }
      }
    }
    button {
      color: #b8b8b8;
      height: 35px;
      width: 100px;
      border-radius: 18px;
      border: 1px solid #b8b8b8;
      background-color: #fff;
    }
  }
}

.overlay-main {
  width: 326px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .overlay-title {
      border-bottom: 1px solid #f1f1f1;
      flex: 1;
    }
    .close {
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .text-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .int {
      position: relative;
      input {
        width: 254px;
        height: 45px;
        border-radius: 4px;
        border: none;
        background-color: #f1f1f1;
      }
      button {
        width: 50px;
        height: 30px;
        border-radius: 4px;
        border: none;
        color: #fff;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
  .search {
    padding: 10px 14px;
  }
  .seach-btn {
    padding: 0 12px;
    border-radius: 6px 6px 6px 6px;
    opacity: 0.81;
    color: #ffffff;
    line-height: 0.6rem !important;
    margin-right: 3px;
  }
  // 产品列表
  .list {
    height: 355px;
    overflow-y: scroll;
    .item {
      border-bottom: 1px solid #f1f1f1;
      padding: 15px 14px;
    }
  }
  .pop-centent-buttom {
    padding: 15px 20px 20px;
    border-top: 1px solid #f1f1f1;
    .pop-centent-btn {
      width: 120px;
      height: 40px;
      line-height: 40px;
      background: #f1f1f1;
      border-radius: 20px;
      font-size: 16px;
      font-weight: 400;
      border: none;
    }
    .pop-centent-btn::after {
      border: none;
    }
    .confirm {
      background: @primary;
      color: #fff;
    }
  }
  .print-type-inp {
    border-radius: 6px;
    border: 1px solid #b8b8b8;
    width: 136px;
    height: 36px;
    margin-right: 8px;
  }
  .print-time {
    width: 90px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #b8b8b8;
    margin-right: 8px;
  }
  .print-main {
    height: 410px;
    overflow-y: scroll;
  }
}
.ml-056em {
  margin-left: 0.56rem;
}
</style>
